
//做playInfo的存储
const playInfoData: any = {

}
export function setPlayInfoData(key: any, val: any) {
    playInfoData[key] = val
}
export function getPlayInfoData(key: any) {
    return playInfoData[key]
}