import { Button, Checkbox, Form, Input, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from "react";
import ChooseLang from "../../../../components/common/ChooseLang";
import { dealLangToPage } from "../../../../utils/lang";

interface IStoryFrom {
    onFinish: (value: any) => void;
    info: any;
}

const StoryForm = (props: IStoryFrom) => {
    const { onFinish, info } = props;
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState<string>();
    const [loading, setLoading] = useState(false);
    const introLang = useRef<any>([]) //映射简介语言数据
    const [isEnd, setIsEnd] = useState(info?.isEnd);

    useEffect(() => {
        if (!info) return
        form.setFieldsValue({
            nameInfo: info.nameInfo && dealLangToPage(info.nameInfo),
            isOmit: info.isOmit,
            isEnd: info.isEnd,
            imgUrl: info.imgUrl,
            resourceId: info.resourceId,
            endMessage: info.endMessage && dealLangToPage(info.endMessage),
        });
        setIsEnd(info.isEnd);
    }, [info, form])


    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );
    return info && <Form
        form={form}
        style={{
            width: 300,
            padding: 12,
            marginTop: 50,
            // 滚动
            overflow: 'auto',
            height: 'calc(100% - 100px)',
        }}
        name="form_item_path"
        onFinish={onFinish}>
        <Form.Item name="resourceId" label="剧情资源">
            <Input placeholder='请输入视频ID' />
        </Form.Item>
        <Form.Item name="imgUrl" label="剧情封面">
            <Upload
                name="imgUrl"
                listType="picture-card"
                showUploadList={false}
                action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                beforeUpload={() => {

                }}
                onChange={() => {

                }}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
        </Form.Item>

        <ChooseLang
            language={introLang.current}
            renderTab={(index, language) => {
                return <>
                    <Form.Item name={["nameInfo", index, language.key]} label="剧情名称" rules={[{ required: true }]}>
                        <Input placeholder='请输入剧情名称' />
                    </Form.Item>
                </>
            }} />

        <Form.Item
            name="isOmit"
            valuePropName="checked"
            getValueProps={(value) => ({ checked: value === 1 })}
            getValueFromEvent={(e) => (e.target.checked ? 1 : 0)}
        >
            <Checkbox>缩略剧情片段</Checkbox>
        </Form.Item>

        <Form.Item
            name="isEnd"
            valuePropName="checked"
            getValueProps={(value) => ({ checked: value === 1 })}
            dependencies={['isEnd']}
            getValueFromEvent={(e) => (e.target.checked ? 1 : 0)}
        >
            <Checkbox onChange={(e) => setIsEnd(e.target.checked ? 1 : 0)}>设置为结局</Checkbox>
        </Form.Item>

        {isEnd === 1 && (
            <ChooseLang
                language={introLang.current}
                renderTab={(index, language) => {
                    return (
                        <Form.Item name={["endMessage", index, language.key]} label="结局寄语">
                            <Input placeholder='请输入结局寄语' />
                        </Form.Item>
                    );
                }}
            />
        )}

        <div style={{ height: 50 }}>
            <Button type="primary" htmlType="submit">
                保存
            </Button>
        </div>
    </Form>
}

export default StoryForm;