import { http } from "../request";

class UploadService {

    //上传表单形式
    upload(param: any) {
        return http.postForm("/upload/upload", param);
    }
}

export const uploadService: UploadService = new UploadService();