import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { Popover, Tabs, Tag, Modal, Tooltip } from "antd";
import { PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { getAppLangs } from "../../../utils/lang";
const { TabPane } = Tabs;

export interface ILanguage {
    name: string | ReactNode
    key: string
    noNeedClose?: boolean

}

export interface IChooseLang {
    language: any[]
    // unSelectLanguage: ILanguage[]
    renderTab: (index: number, i: ILanguage) => JSX.Element
    onTabClick?: (index: string) => void
    TagClick?: (val: ILanguage, index: string) => void
    onEdit?: (index: string) => void
    delAddLanguage?: boolean
    disable?: boolean
}

export const ChooseLang: FC<IChooseLang> = props => {
    const languageMap: ILanguage[] = getAppLangs();
    const { language, renderTab, onTabClick, TagClick, onEdit, delAddLanguage } = props
    const [active, setActive] = useState<any>()
    const [isShowDel, setIsShowDel] = useState<boolean>(false)
    const [choosed, setChoosed] = useState<ILanguage[]>([])
    const [unChoosed, setUnChoosed] = useState<ILanguage[]>([])
    let delActive = useRef('')//当前选中tab

    useEffect(() => {
        let tempChoosed: any = []
        //根据map 整理传入的数据 生成选中和未选中数据
        if (language.length > 0) {
            //有默认值
            imgDeal()
            language.forEach(el => {
                const index = languageMap.findIndex((element: any) => element.key === el)
                if (index === -1) return
                tempChoosed.push(languageMap.splice(index, 1)[0])
                setActive(tempChoosed[0].key)
            });
        } else {
            //无默认值取英文
            const firstLanguage = languageMap.splice(languageMap.findIndex((element: any) => element.key === 'en'), 1)[0]
            tempChoosed.push(firstLanguage)
            setActive(firstLanguage.key)
        }

        setChoosed(tempChoosed)
        setUnChoosed(languageMap)
    }, [])

    //特殊处理
    function imgDeal() {
        if (language[0] === 'all') {
            languageMap.unshift({
                name: <div>通用
                    <Tooltip
                        arrowPointAtCenter
                        overlayClassName={'TooltipTip'}
                        color={"#fffaf0"}
                        placement="topLeft"
                        title={() => {
                            return <div style={{ color: '#543c30', padding: 8 }}>
                                <p>配图模式（二选一）</p>
                                <p>① 通用：多语言均通用一套图，配置通用图即可</p>
                                <p>② 配套：新增语言即可配置对应语言图片，若泰语没配图则泰语无图。</p>
                            </div>
                        }}>
                        <ExclamationCircleFilled style={{ marginLeft: 3, marginRight: 0 }} />
                    </Tooltip>
                </div>,
                key: 'all',
                noNeedClose: true,
            })
        }
    }

    const _onTabClick = (key: any) => {
        setActive(key)
        onTabClick && onTabClick(key)
    }

    const _TagClick = (val: any, key: any) => {
        TagClick && TagClick(val, key)
        const index = unChoosed.findIndex(el => el.key === key)
        unChoosed.splice(index, 1)
        setUnChoosed(unChoosed)
        setChoosed([...choosed, val])
        setActive(key)
    }

    //tab删除点击事件
    const _onEdit = (key:any) => {
        if (typeof (key) !== 'string') return
        setIsShowDel(true)
        delActive.current = key
    }

    return <><Tabs
        aria-disabled={props.disable}
        tabBarStyle={{ height: 35 }}
        onTabClick={_onTabClick}
        activeKey={active}
        type="editable-card"
        hideAdd={unChoosed.length === 0}
        onEdit={_onEdit}
        addIcon={<Popover className='addLanguage' placement="rightTop" content={<>
            {unChoosed.map((i, index) => {
                return <div key={index}><Tag color="#2db7f5" onClick={() => { _TagClick(i, i.key) }} className="fb languageTag" style={{ cursor: 'pointer' }}>{i.name}</Tag></div>
            })}
        </>}>
            {!delAddLanguage && !props.disable ? <div style={{ padding: 5, width: 85, height: 200 }}>
                新增语言 <PlusOutlined />
            </div> : <></>}
        </Popover>}
    >
        {choosed.length > 0 && choosed.map((i, index) => {
            return (<TabPane disabled={props.disable} forceRender={true} tab={i.name} key={i.key} closable={(choosed.length === 1 || i.noNeedClose) ? false : true}>
                {renderTab(index, i)}
            </TabPane>
            )
        })}
    </Tabs>
        {/* 语言删除确认弹框 */}
        <Modal
            title={null}
            open={isShowDel}
            centered
            onOk={() => {
                setUnChoosed([...choosed.splice(choosed.findIndex(el => el.key === delActive.current), 1), ...unChoosed])
                setChoosed(choosed)
                if (active === delActive.current) setActive(choosed[0].key)
                setIsShowDel(false)
                onEdit && onEdit(delActive.current)
            }}
            okText='确定'
            cancelText='取消'
            onCancel={() => { setIsShowDel(false) }}
        >
            <p>确定删除该语言配置？</p>
        </Modal></>
}
export default ChooseLang