import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { Button } from 'antd';

interface AddMenuProps {
    position: { x: number; y: number };
    onAddNode: (type: 1 | 2 | 3 | 4) => void;
}

export interface AddMenuRef {
    showMenu: (visible: 0 | 1 | 2 | 3 | 4 | 5) => void;
    hideMenu: () => void;
}

const addMap = [
    { type: 1, label: '对方对话' },
    { type: 2, label: '我方对话' },
    { type: 3, label: '设置判断' },
    { type: 4, label: '跳转已有' },
];

const AddMenu = forwardRef<AddMenuRef, AddMenuProps>(({ position, onAddNode }, ref) => {
    const [visible, setVisible] = useState<0 | 1 | 2 | 3 | 4 | 5>(0);

    useImperativeHandle(ref, () => ({
        showMenu: (newVisible: 0 | 1 | 2 | 3 | 4 | 5) => setVisible(newVisible),
        hideMenu: () => setVisible(0),
    }));

    let renderMap = addMap;
    switch (visible) {
        case 2:
            renderMap = [addMap[1]];
            break;
        case 3:
            renderMap = [addMap[2]];
            break;
        case 4:
            renderMap = addMap.slice(0, 3);
            break;
    }

    return (
        <div id="add" style={menuStyle(visible, position)}>
            {renderMap.map(({ type, label }) => (
                <Button
                    onClick={() => onAddNode(type)}
                    key={type}
                    style={buttonStyle}
                    type="primary"
                >
                    {label}
                </Button>
            ))}
        </div>
    );
});

const menuStyle = (visible: 0 | 1 | 2 | 3 | 4 | 5, position: { x: number; y: number }): React.CSSProperties => ({
    position: 'absolute',
    display: visible ? 'flex' : 'none',
    flexDirection: 'column',
    zIndex: 1,
    left: position.x,
    top: position.y,
    transform: 'translate(20%, -48%)',
});

const buttonStyle: React.CSSProperties = {
    cursor: 'pointer',
    width: 90,
    height: 30,
    boxShadow: '0 1px 3px rgba(0,0,0,.2)',
    marginBottom: 8,
};

export default AddMenu;
