import React, {useEffect} from 'react';
import './App.less';
import {AppStore} from "./appStore";
import {Auth} from "./pages/home";
import {ConfigProvider} from 'antd';
import zhCN from 'antd/locale/zh_CN';

import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');

function App(): React.ReactNode {



    // useEffect(() => {
    //     const ws = new WebSocket(`ws://${location.host}/ws`);
    //     ws.onmessage = (e) => {
    //         console.log(e.data);
    //     }
    //
    //     ws.onopen = () => {
    //         ws.send('Hello WebSockets!');
    //     }
    // }, [])
    return (
        <ConfigProvider locale={zhCN}>
            <AppStore>
                <Auth/>
            </AppStore>
        </ConfigProvider>
    );
}

export default App;
