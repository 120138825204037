import { http } from "../request";

class ValueNumberService {

    //列表
    list(param: any) {
        return http.post("/numberValue/page", param);
    }

    //添加
    add(param: any) {
        return http.post("/numberValue/add", param);
    }

    //更新
    update(param: any) {
        return http.post("/numberValue/update", param);
    }

    /**
     * 删除
     */
    delete(param: any) {
        return http.post("/numberValue/delete", param);
    }

    /**
     * 获取到短剧的所有数值
     */
    valueList(param:any){
        return http.post("/numberValue/valueList",param);
    }

}

export const valueNumberService: ValueNumberService = new ValueNumberService()