//这是一个组件，用于input框输入短剧id，查询出短剧信息
import { Form, Input, message, Tag, Select } from "antd";
import { useEffect, useState, useRef } from "react";
import { valueNumberService } from "../../service/play/value-number-service";


export interface IProps_RoleFeel {
    propName: string;//属性名称
    playId?: string;//短剧id
    feelId?: string;//数值id
    form: any;
}

export const RoleFeelItem = (props: IProps_RoleFeel) => {
    const { propName, form, playId, feelId } = props;
    const [valueMap, setValueMap] = useState({});

    //初始化数据
    const initData = () => {
        console.log("roleFeel ---playId", playId)
        valueNumberService.valueList({ playId }).then((res) => {
            let map: any = {}
            if (res.data) {
                res.data.map((item: any) => {
                    map[item["id"]] = item["name"];
                })
            }
            setValueMap(map);
        })
    }

    useEffect(() => {
        initData();
    }, []);



    return <div>
        <Form.Item name={propName} label="角色好感度" rules={[{ required: true }]}>
            <Select showSearch placeholder={"点击下拉关联数值"} defaultValue={feelId}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {Object.keys(valueMap).map((key: any) => {
                    return <Select.Option value={key} key={key}>{valueMap[key]}</Select.Option>
                })}
            </Select>
        </Form.Item>
    </div>
}