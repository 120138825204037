import { Button, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Checkbox, Radio, AutoComplete, message, Tag } from "antd";
import { useEffect, useState, useRef } from "react";
import { valueNumberService } from "../../../../service/play/value-number-service";
import ChooseLang from "../../../../components/common/ChooseLang";
import { dealLangToService, dealLangToPage, getOneLangDto, verifyImg, dealLangImgToService, delDataInArray, getAppLangs2 } from "../../../../utils/lang";
export interface IProps_ValueNumberEditForm {
    valueNumberEdit?: {
        id: string;
        playId: string;
        nameInfo: any;
        type: number;
        initValue: number;
    };
    mode: 'add' | 'edit' | 'read';
    onSuccess: () => void;
    onFail: (res: any) => void;
}

export const ValueNumberEditForm = (props: IProps_ValueNumberEditForm) => {
    const { valueNumberEdit, onSuccess, onFail, mode } = props;
    const [form] = Form.useForm();
    const [formShow, setFormShow] = useState<any>();//这是为了一次性渲染
    const [btnLoading, setBtnLoading] = useState(false);
    const contentLang = useRef<any>([]) //映射的语言数据

    useEffect(() => {
        fillForm(valueNumberEdit);
    }, []);


    const onFinish = (values: any) => {
        values.playId = valueNumberEdit?.playId;
        if (mode == "edit") {
            values.id = valueNumberEdit?.id;
        }

        //处理短剧多语言名称
        values.nameInfo = dealLangToService(values.name);
        // console.log(values);

        //处理多语言结构
        form.validateFields().then(() => {
            setBtnLoading(true);
            if (mode == "edit") {
                valueNumberService.update(values)
                    .then(() => {
                        onSuccess && onSuccess();
                        setBtnLoading(false);
                    })
                    .catch((res) => {
                        message.error(res.msg.msg);
                        setBtnLoading(false);
                    })
            } else {
                valueNumberService.add(values)
                    .then(() => {
                        onSuccess && onSuccess();
                        setBtnLoading(false);
                    })
                    .catch((res) => {
                        message.error(res.msg.msg);
                        setBtnLoading(false);
                    })
            }
        })
    }

    const fillForm = (valueNumberEdit: any) => {
        // console.log(valueNumberEdit)
        //初始化数据
        if (valueNumberEdit) {//格式化数据处理
            //判断名称多语言
            valueNumberEdit.nameInfo && valueNumberEdit.nameInfo.forEach((el: any) => {
                contentLang.current.push(el.language)
            })
            if (valueNumberEdit["nameInfo"]) {
                valueNumberEdit["name"] = dealLangToPage(valueNumberEdit["nameInfo"]);
            }
        }
        form.setFieldsValue(valueNumberEdit);
        setFormShow({ 1: 1 })
    }

    /**
     * 表单重置
     */
    const onReset = () => {
        form.resetFields();
    }

    return <div>
        {formShow ? <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            style={{ marginTop: 30 }}
        >
            <Form.Item
                label="数值名称"
            >
                <ChooseLang
                    language={contentLang.current}
                    renderTab={(index, language) => {
                        return <>
                            <Form.Item name={["name", index, language.key]} rules={[{ required: true }]}>
                                <Input placeholder="请输入数值名称" />
                            </Form.Item>
                        </>
                    }} />
            </Form.Item>
            <Form.Item name={["type"]} label="数值属性" rules={[{ required: true }]}>
                <Select >
                    <Select.Option key={1} value={1}>好感度</Select.Option>
                    <Select.Option key={0} value={2}>自定义</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item name={["initValue"]} label="初始值" rules={[{ required: true }]}>
                <Input placeholder="输入数值" />
            </Form.Item>
            {mode == "read" ? <></> :
                <Row justify={"end"}>
                    <Space size={'large'}>
                        <Popconfirm style={{

                        }} title={"确认提交？"} okText={"确认"} cancelText={"取消"}
                            onConfirm={() => form.submit()}>
                            <Button loading={btnLoading} type={'primary'}>提交</Button>
                        </Popconfirm>
                    </Space>
                </Row>
            }
        </Form> : <></>}
    </div>
}