import { Button, Checkbox, Form, Input, Select, Upload } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { dealLangToPage, dealLangToService } from '../../../../utils/lang';
import { TYPE_MAP } from '../..';
import StoryForm from './Story_Form';
import SelectForm from './Select_Form';
import ConditionForm from './ConditionForm';

const NodeInfo = (props: { info: any, onSubmit: (val: any, type: any) => void, close: ()=>void, playId: string }) => {
    const {close, onSubmit, info, playId} = props
    const onFinish = (value: any) => {
        value.nameInfo = value.nameInfo && dealLangToService(value.nameInfo)
        value.endMessage = value.endMessage && dealLangToService(value.endMessage)
        onSubmit(value, info.type)
    };

    return <div className="rigSidebar"
        style={{
            height: '100%',
            transition: 'all .3s',
            width: info ? (info.type == 1 ? 350 : 600) : 0,
            backgroundColor: 'white',
            borderLeft: '1px solid #ddd',
        }}>
        <div style={{
            height: 50,
            width: info ? (info.type == 1 ? 350 : 600) : 0,
            lineHeight: '40px',
            paddingLeft: 15,
            borderBottom: '1px solid #ddd',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            zIndex: 1
        }}>
            <div style={{ height: 18, width: 5, backgroundColor: '#007BFF', marginRight: 12 }} />
            <h3 style={{ flex: 1, justifyContent: 'center', color: '#333' }}>{TYPE_MAP[info?.type - 1]?.module}</h3>
            {/* 关闭 */}
            <Button
                style={{ height: 25, width: 25, lineHeight: 0, padding: 0, marginRight: 15 }}
                onClick={close}
                type="text"
                icon={<CloseOutlined style={{ fontSize: 18 }} />}
            />
        </div>
        {/* 剧情设置  */}
        {info?.type == 1 && <StoryForm onFinish={onFinish} info={info} />}
        {/* 选项设置 */}
        {info?.type == 2 && <SelectForm onFinish={onFinish} info={info} playId={playId}/>}
        {/* 条件设置 */}
        {info?.type == 3 && <ConditionForm onFinish={onFinish} info={info} />}
    </div>
}

export default NodeInfo;