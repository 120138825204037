//这是一个组件，用于input框输入短剧id，查询出短剧信息
import { Form, Input, message, Tag, Select, Modal, Tabs, Checkbox, Radio, Popconfirm, Button } from "antd";
import { useEffect, useState, useRef } from "react";
import { playInfoService } from "../../service/play/play-info-service";


export interface IProps_StorySelect {
    propName: string;//属性名称
    playId?: string;//短剧id
    storyId?: string;//剧情id
    form: any;
}

export const StorySelect = (props: IProps_StorySelect) => {
    const { propName, form, playId, storyId } = props;
    const [valueMap, setValueMap] = useState({});//章节目录
    const [visible, setVisible] = useState(false);//模态框是否可见
    const [selectedValue, setSelectedValue] = useState(null);//选择的value
    const [storyMap, setStoryMap] = useState({});//剧情map
    //初始化数据
    const initData = () => {
        console.log("stroy-select ---playId", playId)
        playInfoService.playChapterStory({ playId }).then((res) => {
            // console.log(res);
            if (res.data) {
                var map: any = {}
                Object.keys(res.data).map((key: any, index) => {
                    // console.log(res.data[key])
                    res.data[key]["storyList"].map((val: any) => {
                        map[val["storyId"]] = val["storyName"];
                    })
                })
                setStoryMap(map);
                setValueMap(res.data);
                //初始值
                if (storyId) {
                    form.setFieldValue(propName + "Name", map[storyId])
                    form.setFieldValue(propName, storyId)
                }
            }
        })
    }

    useEffect(() => {
        initData();
    }, []);


    const onFormClose = () => {
        setVisible(false);
    }

    const handleChange = (e: any) => {
        setSelectedValue(e.target.value);
    };


    return <div>
        <Form.Item name={propName + "Name"} label="选择剧情" rules={[{ required: true }]}>
            <Input readOnly onClick={() => {
                setVisible(true)
            }} placeholder="点击选择剧情"></Input>
        </Form.Item>
        <Form.Item name={propName} label="选择剧情id(隐藏)" hidden={true}>

        </Form.Item>
        <Modal
            open={visible}
            maskClosable={false}
            destroyOnClose={true}
            closable={true}
            footer={null}
            onCancel={() => onFormClose()}
            style={{ minWidth: "700px" }}
            styles={{
                body: {
                    padding: "20px 40px 0 0"
                }
            }}
        >
            <Tabs
                tabPosition={'left'}
                onChange={(e) => {
                    // console.log(e)
                }}
                items={Object.keys(valueMap).map((key, index) => {
                    const val = valueMap[key];
                    return {
                        label: val.chapterName,
                        key: val.chapterId,
                        children: <Form.Item
                            name={['chapter', val.chapterId]}
                        >
                            <Radio.Group style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: 5
                            }} onChange={handleChange} value={selectedValue}>
                                {val.storyList.map((item: any) => {
                                    return <>
                                        <Radio key={item.storyId} value={item.storyId}>{item.storyName}</Radio>
                                    </>
                                })
                                }
                            </Radio.Group>
                        </Form.Item>
                    };
                })}
            />
            <Popconfirm style={{

            }} title={"确认提交？"} okText={"保存"} cancelText={"取消"}
                onConfirm={() => {
                    form.setFieldValue(propName + "Name", storyMap[selectedValue])
                    form.setFieldValue(propName, selectedValue)
                    onFormClose()
                }}>
                <Button type={'primary'} >保存</Button>
            </Popconfirm>
        </Modal>
    </div>
}