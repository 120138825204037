import { http } from "../request";

interface IChapter_req {
    chapterId: string; //章节id
    playId: string; //短剧id
}

interface IChapter_req_del {
    ids: string[]; //节点id
}

export interface IChapter_req_update {
    id: string;
    nameInfo: any;
    imgUrl?: string;
    resourceId?: string;
    isEnd?: 1 | 0;
    isOmit?: 1 | 0;
    isChapterEnd?: 1 | 0;
}

export interface IChapter_req_create {
    chapterId: string; //章节id
    playId: string; //短剧id
    isStart?: 1 | 0;
    parentId?: string;
    parentType?: 1 | 2 | 3;
}

export interface IChatMap_req_create {
    storyId: string,
    roleId: string
    playId: string,
    isStart?: 1 | 0;
    parentId?: string;
    parentType?: 1 | 2 | 3;
}

export interface IChatMap_req_update {

}

//广告接口
class InteractiveStoryService {

    //获取章节节点
    getChapter(param: { chapterId: string }) {
        return http.post("/playInfo/getChapterRouter", param);
    }

    //创建剧情
    createChapterStory(param: IChapter_req_create) {
        return http.post("/playChapterStory/add", param);
    }

    //创建选项
    createChapterStorySelect(param: IChapter_req_create) {
        return http.post("/playChapterStorySelect/add", param);
    }

    //创建判断
    createChapterStoryCondition(param: IChapter_req) {
        return http.post("/playChapterStoryCondition/add", param);
    }

    //删除章节
    deleteChapter(param: IChapter_req) {
        return http.post("/playChapter/delete", param);
    }

    //删除剧情
    deleteChapterStory(param: IChapter_req_del) {
        return http.post("/playChapterStory/delete", param);
    }

    //删除选项
    deleteChapterStorySelect(param: IChapter_req_del) {
        return http.post("/playChapterStorySelect/delete", param);
    }

    //删除判断
    deleteChapterStoryCondition(param: IChapter_req_del) {
        return http.post("/playChapterStoryCondition/delete", param);
    }

    //更新章节
    updateChapter(param: {
        chapterId: string,
        playId: string
    }) {
        return http.post("/playChapter/update", param);
    }

    //更新剧情
    updateChapterStory(param: IChapter_req_update) {
        return http.post("/playChapterStory/update", param);
    }

    //更新选项
    updateChapterStorySelect(param: IChapter_req_update) {
        return http.post("/playChapterStorySelect/update", param);
    }

    //更新判断
    updateChapterStoryCondition(param: IChapter_req_update) {
        return http.post("/playChapterStoryCondition/update", param);
    }


    // 章节相关-----------------
    //获取章节列表
    getPlayChapterList(param: { playId: string }) {
        return http.post("/playChapter/list", param);
    }

    //添加章节
    addPlayChapterList(param: { playId: string }) {
        return http.post("/playChapter/add", { ...param, nameInfo: [{ name: '章节名称', lang: 'en' }] });
    }

    //删除章节
    deletePlayChapterList(param: { id: string }) {
        return http.post("/playChapter/delete", param);
    }

    // 更新章节
    updatePlayChapterList(param: { id: string }) {
        return http.post("/playChapter/update", param);
    }

    // 获取角色列表
    getCharacterList(param: { playId: string }) {
        return http.post("/playRole/list", param);
    }

    // 创建角色对话内容
    createCharacterDialogue(param: IChatMap_req_create) {
        return http.post("/playRoleDialogue/add", param);
    }

    // 创建角色对话选择
    createCharacterDialogueSelect(param: IChatMap_req_create) {
        return http.post("/playRoleDialogueSelect/add", param);
    }

    // 创建角色判断
    createCharacterCondition(param: IChatMap_req_create) {
        return http.post("/playRoleDialogueCondition/add", param);
    }

    // 更新角色对话内容
    updateCharacterDialogue(param: IChatMap_req_update) {
        return http.post("/playRoleDialogue/update", param);
    }

    // 更新角色对话选择
    updateCharacterDialogueSelect(param: IChatMap_req_update) {
        return http.post("/playRoleDialogueSelect/update", param);
    }

    // 更新角色判断
    updateCharacterCondition(param: IChatMap_req_update) {
        return http.post("/playRoleDialogueCondition/update", param);
    }

    // 删除角色对话内容
    deleteCharacterDialogue(param: { ids: string[] }) {
        return http.post("/playRoleDialogue/delete", param);
    }

    // 删除角色对话选择
    deleteCharacterDialogueSelect(param: { ids: string[] }) {
        return http.post("/playRoleDialogueSelect/delete", param);
    }

    // 删除角色判断
    deleteCharacterCondition(param: { ids: string[] }) {
        return http.post("/playRoleDialogueCondition/delete", param);
    }

    // 获取角色对话路径树
    getCharacterDialogueTree(param: {
        storyId: string,
        roleId: string
    }) {
        return http.post("/playRole/getRoleDialogueRouter", param);
    }

}



export const StoryService: InteractiveStoryService = new InteractiveStoryService()