import React, { FC, useEffect, useRef, useState, } from 'react'
import { Upload, message, Form, Image, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import "./video.less";
import { Player } from 'video-react';

import { uploadService } from "../../../service/upload/upload-service";
interface img {
    url: string,//图片，视频封面
    videoUlr?: string,//视频地址
}
interface UploadFile {
    success?: (src: string) => void,
    del?: () => void
    type: number //类型，对应上传需要到不同目录 1：广告
    tipMsg?: string
    formKey?: string
    img?: img[]
    index?: number
    groupId?: any
    url?: string
    video?: string
    accept?: string
    disable?: boolean
}
export const ImgUpload: FC<UploadFile> = (props) => {
    //域名,这里还要加上具体模块。。。
    const domain: string = process.env.REACT_APP_CND_URL || "";
    const { success, del, img, formKey, groupId, index, tipMsg, video, accept, disable, type } = props
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState<any>([])
    const [showUrl, setShowUrl] = useState<any>();//数据
    const [visible, setVisible] = useState(false);
    const [videoImg, setVideoImg] = useState<any>();//视频封面
    const [videoTime, setVideotime] = useState<any>();//视频时长

    const timeUp = useRef<any>(true)
    const canUp = useRef<any>(false)

    useEffect(() => {
        img && setFileList(img)
        setVideoImg("")
        setVideotime("")
        return () => {
        }
    }, [img])


    //上传之前
    function beforeUpload(file: any) {
        setLoading(true)
        // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        // if (!isJpgOrPng) {
        //     message.error('You can only upload JPG/PNG file!');
        // }
        // const isLt2M = file.size / 1024 / 1024 < 20;
        // if (!isLt2M && !video) {
        //     message.error('文件大小需小于 20MB!');
        //     return false;
        // }
        canUp.current = true
        return false;
        // return isJpgOrPng && isLt2M;
    }
    //上传
    function uploadFileFuction(file: any, fileList: any, formData: any, videoImg: any, videoTime: any, type: number) {
        if (video) {
            formData.append('videoFile', file);
            var videoImgFile = base64ToBlob(videoImg);
            formData.append('coverImageFile', videoImgFile)
        }
        formData.append("type", type)
        uploadService.upload(formData).then(res => {
            //成功回调
            if (video) {
                // console.log(videoImg);
                // console.log(videoTime)
                fileList = fileList.map((file: any) => {
                    res.data.videoTime = videoTime;
                    success && success(res.data)
                    // setShowUrl(res.data.videoUlr)
                    var imgurl = "";
                    // console.log(imgurl)
                    file.url = imgurl
                    file.videoUlr = res.data.videoUlr
                    // findvideocover(res.data.videoUlr)
                    return file;
                });
            } else {
                fileList = fileList.map((file: any) => {
                    success && success(res.data.filePath)
                    file.url = domain + res.data.filePath;
                    return file;
                });
            }
            setLoading(false)
            setFileList([...fileList]);
        }).catch(() => {
            setLoading(false)
        })
        setTimeout(() => {
            timeUp.current = true
        }, 2000);
    }

    const handleChange = ({ file, fileList }: any) => {
        fileList.length > 1 && fileList.splice(0, 1)
        // 删除
        if (file.status === 'removed') {
            setFileList([]);
            setLoading(false)
            del && del()
            // })
            return
        } else if (canUp.current) {
            if (!timeUp.current) return
            timeUp.current = false
            canUp.current = false
            // 新增
            console.log(file)
            const formData = new FormData();
            formData.append('file', file);
            console.log(formData)
            //如果是视频的添加时长跟视频截图两个参数
            if (video) {
                Promise.all([isSize(file), getPhoto(file)]).then(data => {
                    uploadFileFuction(file, fileList, formData, data[1], data[0], type);
                })
            } else {
                uploadFileFuction(file, fileList, formData, "", "", type);
            }
        } else {
            setLoading(false)
        }
    }
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="col-3 f12">{tipMsg}</div>
        </div>
    );
    //获取视频时长
    const isSize = (file: any) => {
        return new Promise((resolve, reject) => {
            const videoUrl = URL.createObjectURL(file)
            const videoObj = document.createElement('video')
            videoObj.setAttribute('crossorigin', 'anonymous'); //处理跨域
            videoObj.setAttribute("preload", "auto");
            videoObj.setAttribute("src", videoUrl);
            videoObj.onloadedmetadata = () => {
                URL.revokeObjectURL(videoUrl)
                let times = Math.round(videoObj.duration)
                resolve(times);
            }
        });
    }
    //获取视频截图
    const getPhoto = (file: any) => {
        return new Promise((resolve, reject) => {
            const videoInfo = document.getElementById("videoPhotoInfo") as HTMLVideoElement;
            const videoUrl = URL.createObjectURL(file)
            videoInfo.setAttribute("src", videoUrl)  // url地址 url跟 视频流是一样的
            videoInfo.setAttribute('crossOrigin', 'anonymous')
            videoInfo.currentTime = 3 // 第一帧
            videoInfo.onloadeddata = () => {
                var canvas = document.createElement('canvas');// 获取 canvas 对象
                const ctx: any = canvas.getContext('2d'); // 绘制2d
                canvas.width = videoInfo.clientWidth; // 获取视频宽度
                canvas.height = videoInfo.clientHeight; //获取视频高度
                // 利用canvas对象方法绘图
                ctx.drawImage(videoInfo, 0, 0, canvas.width, canvas.height)
                // 转换成base64形式
                var imgsrc = canvas.toDataURL("image/png") // 截取后的视频封面
                videoInfo.src = "";
                resolve(imgsrc);
            }
        });
    }

    //base64转图片
    const base64ToBlob = function (base64Data: any) {
        // console.log(base64Data)
        let arr = base64Data.split(','),
            fileType = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            l = bstr.length,
            u8Arr = new Uint8Array(l);
        while (l--) {
            u8Arr[l] = bstr.charCodeAt(l);
        }
        var blob = new Blob([u8Arr], {
            type: fileType
        });
        return new window.File([blob], "视频截图.png", {
            type: fileType
        })
    };
    const handlePreview = async (file: any) => {
        video ? setShowUrl(file.videoUlr.indexOf("http") > -1 ? file.videoUlr : domain + file.videoUlr) : setShowUrl(file.url.indexOf("http") > -1 ? file.url : domain + file.url)
        setVisible(true)
    }
    return (
        <div>
            {/* <link rel="stylesheet" href="https://video-react.github.io/assets/video-react.css" /> */}
            <Upload
                disabled={props.disable}
                accept={accept}
                fileList={fileList}
                listType="picture-card"
                showUploadList={{ showPreviewIcon: true }}
                // action={"/image/upload"}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                onPreview={handlePreview}
            >
                {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            <Modal
                open={visible}
                title="图片展示"
                footer={null}
                destroyOnClose={true}
                onCancel={() => { setVisible(false) }}
            >
                {!video ? <img alt="图片展示" style={{ width: '100%' }} src={showUrl} /> : <></>}
                {video ? <Player playsInline src={showUrl} /> : <></>}
            </Modal>
            {video ? <video id="videoPhotoInfo"></video> : <></>}

        </div>
    )
}

export default ImgUpload
