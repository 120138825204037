import * as d3 from 'd3';
import { useEffect, useRef, useState } from "react";
import { CHAT_TYPE_MAP } from './UseChatMapData';

interface IUseDrawChatMap {
    selectedNode: any;
    svgRef: any;
    data: any;
    updateNode: any
    onClickNode: (e: any, node: any) => void;
    onClickAddNode: (event: any, node: any) => void;
    ChatMap: any;
}

const NODE_WIDTH = 100;
const NODE_HEIGHT = 60;
const PADDING = 28;
const PADDING_H = 80
const NODE_SIZE: [number, number] = [NODE_HEIGHT + PADDING, 180];
const BUTTON_SIZE = 10;
const BLUE = '#0ca5f1'
const SVG_WIDTH = 4000;
const SVG_HEIGHT = 4000;
const INIT_X = 400;
const INIT_Y = 50;
const SCALE_RANGE: [number, number] = [0.2, 2];

export const UseDrawChatMap = (props: IUseDrawChatMap) => {
    const { selectedNode, svgRef, data, updateNode, onClickNode, onClickAddNode, ChatMap } = props;
    const [transform, setTransform] = useState(d3.zoomIdentity);
    const [startNode, setStartNode] = useState<any>(null);
    const [endNode, setEndNode] = useState<any>(null);
    const hoverTimer = useRef<any>(null);
    const svg = useRef<any>(null);

    const resetScale = () => {
        svg.current.transition()
            .duration(750)
            .call(d3.zoom().transform, d3.zoomIdentity);
        setTransform(d3.zoomIdentity);
    }

    useEffect(() => {
        if (!startNode) {
            setEndNode(null);
        }
    }, [startNode])

    useEffect(() => {
        if (!data || data.length == 0) {
            svg.current && svg.current.selectAll('*').remove();
            return
        }
        svg.current = d3.select(svgRef.current)
            .attr('width', SVG_WIDTH) //TODO 根据节点计算
            .attr('height', SVG_HEIGHT)
            .call(d3.zoom()
                .scaleExtent(SCALE_RANGE)
                .on('zoom', (event) => {
                    setTransform(event.transform);
                }));
        const treeLayout = d3.tree<any>().nodeSize(NODE_SIZE)

        const root = d3.hierarchy(data);
        treeLayout(root);

        root.each((d: any) => {
            d.x += INIT_X;
            d.y += INIT_Y;

            // 把合并的节点居中
            let previousLevelNodes = root.descendants().filter((node: any) => node.depth === d.depth - 1 && node.data.nextDialogueId === d.data.id);
            if (previousLevelNodes.length >= 2) {
                // 上一层级节点的 x 坐标平均值
                let avgX = previousLevelNodes.reduce((sum: number, node: any) => sum + node.x, 0) / previousLevelNodes.length;
                // 移动的距离
                let deltaX = avgX - d.x;
                // 移动当前节点及其子节点
                d.x += deltaX;
                d.descendants().forEach((child: any) => {
                    // 移动子节点 
                    if (child.depth > d.depth) {
                        child.x += deltaX;
                    }
                });
            }
        });

        // 清空上一次的渲染
        svg.current.selectAll('*').remove();

        // 添加区分用的色块
        const colorBlocksGroup = svg.current.append('g').attr('transform', transform.toString());

        colorBlocksGroup
            .selectAll('rect')
            .data(root.descendants().filter(d => d.data.type === 2 || d.data.type === 3))
            .enter()
            .append('rect')
            .attr('x', d => (d.data.type === 2 || d.data.type === 3) ? d.y - PADDING / 2 : 0)
            .attr('y', d => (d.data.type === 2 || d.data.type === 3) ? d.x - PADDING / 2 : 0)
            .attr('width', d => (d.data.type === 2 || d.data.type === 3) ? NODE_WIDTH + PADDING : 0)
            .attr('height', d => {
                const parent = d.parent;
                if (!parent) return 0;
                const isFirstChild = parent.children && parent.children[0].data.id === d.data.id;
                const theLastChildX = parent.children && parent.children[parent.children.length - 1].x || 0;
                return isFirstChild && theLastChildX ? NODE_HEIGHT + PADDING + (theLastChildX - (d.x || 0)) : NODE_HEIGHT + PADDING;
            })
            .attr('rx', 30)
            .attr('fill', d => d.data.type === 2 ? '#c8f0f8' : '#fdc7d7')  // 底色

        // 复原位置
        const g = svg.current
            .append('g')
            .attr('transform', transform.toString());
        // 连线
        g.selectAll('path')
            .data(root.links().filter(link => !link.source.data.isPlaceholder && !link.target.data.isPlaceholder)) // TODO 删除掉这个逻辑
            .enter()
            .append('path')
            .attr('d', d => {
                const start_x = d.source.y + NODE_WIDTH / 2;
                const start_y = d.source.x + NODE_HEIGHT / 2;
                const end_x = d.target.y + NODE_WIDTH / 2;
                const end_y = d.target.x + NODE_HEIGHT / 2;
                const mid_x = (start_x + end_x) / 2;
                return `
                    M${start_x},${start_y}
                    H${mid_x}
                    V${end_y}
                    H${end_x}
                `;
            })
            .attr('fill', 'none')
            .attr('stroke', '#828e9b')
            .attr('stroke-width', 2)
            .attr('stroke-dasharray', '4,5');

        const deleteIconSize = 16; // 删除图标的大小
        // 特殊的链接nextId连线
        root.descendants().forEach((d: any) => {
            // 当前节点有标记，且父节点没有
            if (d.data.nextId) {
                // 找到没被标记的目标节点
                const nextNode: any = root.descendants().find(node => d.data.nextId === node.data.id);
                if (nextNode) {
                    const path = g.append('path')
                        .attr('d', () => {
                            const start_x = d?.y + NODE_WIDTH / 2;
                            const start_y = d?.x + NODE_HEIGHT / 2;
                            const end_x = nextNode.y + NODE_WIDTH / 2;
                            const end_y = nextNode.x + NODE_HEIGHT / 2;
                            return `
                                M${start_x},${start_y}
                                H${end_x - NODE_WIDTH / 2 - PADDING_H / 2}
                                V${end_y}
                                H${end_x}
                            `;
                        })
                        .attr('fill', 'none')
                        .classed('line', true)
                        .on('mouseover', function () {
                            d3.select(this).classed('hover-line', true);
                            clearTimeout(hoverTimer.current);
                            hoverTimer.current = null;
                            // 添加删除图标
                            const start_x = d?.y + NODE_WIDTH / 2;
                            const start_y = d?.x + NODE_HEIGHT / 2;
                            const end_x = nextNode.y + NODE_WIDTH / 2;
                            const end_y = nextNode.x + NODE_HEIGHT / 2;
                            const mid_x = (start_x + end_x) / 2;
                            const mid_y = (start_y + end_y) / 2
                            const deleteGroup = g.append('g')
                                .attr('class', 'delete-icon-group')
                                .attr('transform', `translate(${mid_x},${mid_y})`)
                                .style('cursor', 'pointer')
                                .on('click', () => {
                                    // 删除操作链接
                                    updateNode({ value: { nextDialogueId: '', selectIds: [] }, nodeId: d.data.id, type: d.data.type }, (err: any) => { })
                                });

                            // 添加圆形背景
                            deleteGroup.append('circle')
                                .classed('delete-icon', true)
                                .attr('r', deleteIconSize)
                                .attr('fill', '#fff');

                            // 添加文本 "X"
                            deleteGroup.append('text')
                                .classed('delete-icon', true)
                                .attr('font-size', deleteIconSize)
                                .attr('text-anchor', 'middle')
                                .attr('dominant-baseline', 'middle')
                                .attr('fill', 'red')
                                .text('X');
                        })
                        .on('mouseout', function () {
                            !hoverTimer.current && (hoverTimer.current = setTimeout(() => {
                                d3.select(this).classed('hover-line', false)
                                g.selectAll('.delete-icon').remove();
                                hoverTimer.current = null;
                            }, 1.5 * 1000));
                        });
                }
            }
        });

        // 添加链接时的连线
        if (startNode && endNode) {
            g.append('path')
                .attr('d', () => {
                    const start_x = startNode.y + NODE_WIDTH / 2;
                    const start_y = startNode.x + NODE_HEIGHT / 2;
                    const end_x = endNode.y + NODE_WIDTH / 2;
                    const end_y = endNode.x + NODE_HEIGHT / 2;
                    return `
                            M${start_x},${start_y}
                            H${end_x}
                            V${end_y}
                        `;
                })
                .attr('fill', 'none')
                .attr('stroke', BLUE)
                .attr('stroke-width', 2)
        }

        const nodes = g
            .selectAll('g')
            .data(root.descendants()) // 删除掉过滤逻辑
            .enter()
            .append('g')
            .attr('class', 'node-group')
            .style('cursor', 'pointer')
            .attr('transform', d => `translate(${d.y},${d.x})`)
            .on('click', (event, d) => {
                event.stopPropagation();
                if (startNode) {
                    const ids = endNode?.ids || []
                    // 新增跳跃连线
                    updateNode({ value: ids.length > 0 ? { selectIds: ids } : { nextDialogueId: d.data.id }, nodeId: startNode.data.id, type: startNode.data.type }, (err: any) => {
                        if (err) return
                        setEndNode(null);
                        setStartNode(null);
                    })
                    return
                }
                onClickNode(event, d)
            })
            .on('mouseover', (event, d) => {
                // 只能选中剧情
                if (startNode && startNode !== d.data.id && d.data.type != 3) {
                    // 如果 d.data.type == 2 说明要链接到整个选择项
                    const ids: any = []
                    if (d.data.type == 2) {
                        // 把所有的子节点都选中
                        const parent = d.parent;
                        if (parent) {
                            parent.children.forEach((child: any) => {
                                ids.push(child.data.id)
                            })
                        }
                    }
                    !endNode && setEndNode({
                        ids,
                        data: {
                            id: d.data.id,
                        },
                        x: d.x,
                        y: d.y,
                    });
                }
            }).on('mouseout', (event, d) => {
                endNode && setEndNode(null);
            })

        // 投影
        svg.current.append('defs')
            .append('filter')
            .attr('id', 'shadow')
            .attr('x', '-50%')
            .attr('y', '-50%')
            .attr('width', '200%')
            .attr('height', '200%')
            .append('feDropShadow')
            .attr('dx', 2)
            .attr('dy', 2)
            .attr('stdDeviation', 3)
            .attr('flood-color', '#718fa5')
            .attr('flood-opacity', 0.3);

        // 块
        nodes
            .append('path')
            .attr('d', d => {
                if (d.data.id === 'root') {
                    // 根节点画圆形
                    const radius = NODE_HEIGHT / 2;
                    return `M${NODE_WIDTH / 2},${NODE_HEIGHT / 2} m-${radius},0 a${radius},${radius} 0 1,0 ${radius * 2},0 a${radius},${radius} 0 1,0 -${radius * 2},0`;
                } else if (d.data.type === 1) {
                    // 长方形
                    return `M0,0 H${NODE_WIDTH} V${NODE_HEIGHT} H0 Z`;
                } else if (d.data.type === 2) {
                    // 长条圆角矩形
                    const rx = 30; // 圆角半径
                    return `M${rx},0 H${NODE_WIDTH - rx} Q${NODE_WIDTH},0 ${NODE_WIDTH},${rx} V${NODE_HEIGHT - rx} Q${NODE_WIDTH},${NODE_HEIGHT} ${NODE_WIDTH - rx},${NODE_HEIGHT} H${rx} Q0,${NODE_HEIGHT} 0,${NODE_HEIGHT - rx} V${rx} Q0,0 ${rx},0 Z`;
                } else if (d.data.type === 3) {
                    // 菱形
                    const halfWidth = NODE_WIDTH / 2;
                    const halfHeight = NODE_HEIGHT / 2;
                    return `M${halfWidth},0 L${NODE_WIDTH},${halfHeight} L${halfWidth},${NODE_HEIGHT} L0,${halfHeight} Z`;
                }
                return '';
            })
            .attr('fill', d => (d.data.id === selectedNode?.id ? '#e6f4ff' : '#fff'))
            .attr('stroke', d => (d.data.id === selectedNode?.id ? BLUE : null))
            .attr('stroke-width', 2)
            .attr('filter', 'url(#shadow)')

        // 高亮选中节点
        nodes.filter(d => (d.data.id === startNode?.data?.id) || (d.data.id === endNode?.data?.id))
            .select('path')
            .attr('fill', '#e6f4ff')
            .attr('stroke', BLUE)
            .attr('stroke-width', 2)

        // 根节点的加号
        nodes.filter(d => d.data.id === 'root')
            .append('text')
            .attr('dy', NODE_HEIGHT / 2 + 11)
            .attr('x', NODE_WIDTH / 2)
            .attr('text-anchor', 'middle')
            .attr('fill', BLUE)
            .attr('font-size', '40px')
            .style('user-select', 'none')
            .text('+');

        // 文字
        nodes.append('text')
            .filter(d => d.data.id !== 'root')
            .attr('dy', NODE_HEIGHT / 2 + 5)
            .attr('x', NODE_WIDTH / 2)
            .attr('text-anchor', 'middle')
            .style('user-select', 'none')
            .each(function (d) {
                let name = CHAT_TYPE_MAP[d.data.type - 1].name;
                let map = ChatMap?.current;
                if (map && map[d.data.id]?.nameInfo) {
                    const nameInfo = map[d.data.id].nameInfo;
                    const firstItem = nameInfo?.[0];
                    firstItem && (name = firstItem?.name);
                    const enItem = nameInfo?.find((item: any) => item.language === 'en');
                    enItem && (name = enItem?.name);
                }
                const maxNumber = 7; // 设置文本最大数
                const maxLines = 2; // 最多显示两行
                let words = name.split('')
                // 是否需要换行
                const isNeedWrap = words.length > maxNumber;
                let line = '';
                let lineNumber = 0;
                let textElement = d3.select(this);
                for (let i = 0; i < words.length; i++) {
                    let testLine = line + words[i];
                    const length = testLine.length
                    if (length > maxNumber && lineNumber < maxLines - 1) {
                        textElement.append('tspan')
                            .attr('x', NODE_HEIGHT / 2 + 20)
                            .attr('dy', '2.2em')
                            .attr('font-size', '12px')
                            .text(line);

                        line = words[i];
                        lineNumber++;
                    } else if (length > maxNumber && lineNumber === maxLines - 1) {
                        textElement.append('tspan')
                            .attr('x', NODE_HEIGHT / 2 + 20)
                            .attr('dy', '1.4em')
                            .attr('font-size', '12px')
                            .text(line + '...');
                        lineNumber++;
                        break;
                    } else {
                        line = testLine;
                    }
                }

                if (lineNumber < maxLines) {
                    textElement.append('tspan')
                        .attr('x', NODE_WIDTH / 2)
                        .attr('font-size', isNeedWrap ? '12px' : '14px')
                        .attr('dy', isNeedWrap ? '1.2em' : '2.4em')
                        .text(line);
                }
            });

        const plusGroups = nodes
            .filter(d => d.data.id !== 'root')
            .filter(d => !(d.children && d.children.some((child: any) => child.data.type === 1)))
            .append('g')
            .attr('class', startNode ? '' : 'plus-group')
            .style('cursor', 'pointer')
            .style('visibility', 'hidden')
            .on('click', (event, d) => {
                onClickAddNode(event, d);
                event.stopPropagation();
            });

        plusGroups
            .append('circle')
            .attr('r', BUTTON_SIZE)
            .attr('cx', NODE_WIDTH)
            .attr('cy', BUTTON_SIZE + (NODE_HEIGHT - BUTTON_SIZE) / 4 + 7)
            .attr('fill', BLUE);

        plusGroups
            .append('text')
            .attr('x', NODE_WIDTH)
            .attr('y', BUTTON_SIZE + (NODE_HEIGHT - BUTTON_SIZE) / 4 + 10.5)
            .attr('text-anchor', 'middle')
            .attr('fill', '#fff')
            .attr('font-size', '16px')
            .text('+')

    }, [data, transform, selectedNode, startNode, endNode]);

    return [
        startNode,
        setStartNode,
        transform,
        resetScale
    ]
}