import { Button, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Radio, message, Tag, Checkbox, DatePicker, Col, Switch, Modal } from "antd";
import { useEffect, useState, useRef } from "react";
// import { playInfoService } from "../../../service/play/play-info-service";
import { getPlayInfoData } from "../../../src/utils/playInfoData";
import { DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { pid } from "process";
dayjs.extend(weekday);
dayjs.extend(localeData);
const { RangePicker } = DatePicker;
export interface IProps_EpisodeForm {
    specialPrice?: {
        id: string;//短剧id
        regionMoney: any;//国家特殊价格
    };
    onSave: (res: any, saveCb: any) => void;
}

export const SpecialPriceForm = (props: IProps_EpisodeForm) => {
    const { specialPrice, onSave } = props;
    const [formModal] = Form.useForm();
    const [formModalShow, setFormModalShow] = useState<any>();//这是为了一次性渲染
    const [btnLoading, setBtnLoading] = useState(false);
    const regionMap = getPlayInfoData("regionMap");//国家/地区map
    const [regionInfo, setRegionInfo] = useState<any>([]);//特殊价格国家信息
    const [registTimeInfo, setRegisterTimeInfo] = useState<any>([]);//注册时间信息
    useEffect(() => {
        fillForm(specialPrice);
    }, []);

    function isOverlapping(intervals: any) {
        for (let i = 0; i < intervals.length; i++) {
            for (let j = i + 1; j < intervals.length; j++) {
                if (intervals[i].startTime < intervals[j].endTime && intervals[i].endTime > intervals[j].startTime) {
                    return true; // 重叠
                }
            }
        }
        return false; // 无重叠
    }


    const onFinishModal = (values: any) => {
        values.id = specialPrice?.id;
        var regionMoney: any = {};
        var timeTow = false;
        //判断国家重复
        var regionTow = false;
        var regionMap: any = {};
        regionInfo.forEach((el: any, index: any) => {
            var regionId = "regionId" + el.key;
            var regionName = values[regionId];
            var moeny = "money" + el.key;
            var vipMoney = "vipMoney" + el.key;
            if (regionMap[regionName]) {
                regionTow = true;
            } else {
                regionMap[regionName] = 1;
                regionMoney[regionName] = {
                    "regionId": values[regionId],
                    "money": values[moeny],
                    "vipMoney": values[vipMoney]
                }
            }
        });
        if (regionTow) {
            message.error("国家重复")
            return;
        }
        registTimeInfo.forEach((el: any, index: any) => {
            var regionId = "regionId" + el.pid;
            var regionName = values[regionId];
            var startTimeInfo = "sendTime" + el.pid + "_" + el.key;
            var timeMoney = "registerPrice" + el.pid + "_" + el.key;
            var vipTimeMoney = "vipRegisterPrice" + el.pid + "_" + el.key;
            var registerMoney = {
                startTime: values[startTimeInfo][0].valueOf(),
                endTime: values[startTimeInfo][1].valueOf(),
                registerPrice: values[timeMoney],
                vipRegisterPrice: values[vipTimeMoney]
            }
            if (regionMoney[regionName]["prices"]) {
                regionMoney[regionName]["prices"].push(registerMoney);
                if (isOverlapping(regionMoney[regionName]["prices"])) {
                    timeTow = true;
                }
            } else {
                regionMoney[regionName]["prices"] = [registerMoney]
            }
        })
        if (timeTow) {
            message.error("时间段重复")
            return;
        }
        values.regionMoney = regionMoney;
        console.log("values", values)
        formModal.validateFields().then(() => {
            // console.log("XXXXXXX")
            setBtnLoading(true);
            onSave && onSave(values, setBtnLoading(false));
            // playInfoService.edit(values)
            //     .then((res: any) => {
            //         onSuccess && onSuccess(res);
            //         setBtnLoading(false);
            //     })
            //     .catch(() => {
            //         onFail && onFail();
            //         setBtnLoading(false);
            //     })
        })
    }

    const fillForm = (specialPrice: any) => {
        // console.log("specialPrice", specialPrice)
        var regionMoneyMap = specialPrice["regionMoney"] || {};
        var regionEdit: any = [];//国家配置信息
        var registTimeInfoEdit: any = [];//
        let index = 0;
        let timeIndex = 0;
        Object.keys(regionMoneyMap).map((key) => {
            var regionMap = regionMoneyMap[key];
            var regionId = "regionId" + index;
            var money = "money" + index;
            var vipMoney = "vipMoney" + index;
            specialPrice[regionId] = regionMap["regionId"];
            specialPrice[money] = regionMap["money"];
            specialPrice[vipMoney] = regionMap["vipMoney"]
            var registerMoney: any = regionMap["prices"];
            if (registerMoney) {
                registerMoney.forEach((el: any, cIndex: any) => {
                    var sendTime = "sendTime" + index + "_" + timeIndex;
                    var registerPrice = "registerPrice" + index + "_" + timeIndex;
                    var vipRegisterPrice = "vipRegisterPrice" + index + "_" + timeIndex;
                    specialPrice[sendTime] = [];
                    specialPrice[sendTime][0] = dayjs(el["startTime"])
                    specialPrice[sendTime][1] = dayjs(el["endTime"])
                    specialPrice[registerPrice] = el["registerPrice"];
                    specialPrice[vipRegisterPrice] = el["vipRegisterPrice"];
                    registTimeInfoEdit.push({
                        key: timeIndex,
                        pid: index
                    })
                    timeIndex++;
                    var sendTime = "sendTime" + index;
                })
            }
            regionEdit.push({ key: index })
            index++;
        })
        // console.log("registTimeInfoEdit", registTimeInfoEdit)
        setRegionInfo([...regionEdit])
        setRegisterTimeInfo([...registTimeInfoEdit])
        //初始化数据
        formModal.setFieldsValue(specialPrice);
        setFormModalShow({ 1: 1 })
    }

    /**
     * 表单重置
     */
    const onReset = () => {
        formModal.resetFields();
    }

    const removeRegionInfo = (index: number) => {
        regionInfo.splice(regionInfo.findIndex((el: any) => el.key === index), 1);
        setRegionInfo([...regionInfo])
    }
    const addRegionInfo = () => {
        var data = regionInfo[regionInfo.length - 1];
        var index = 0;
        if (data) {
            index = data.key + 1;
        }
        regionInfo.push({ key: index })
        setRegionInfo([...regionInfo]);
    }

    const removeRegisterTimeInfo = (index: string) => {
        const foundIndex = registTimeInfo.findIndex((el: any) => { return el.key === index })
        registTimeInfo.splice(foundIndex, 1);
        setRegisterTimeInfo([...registTimeInfo])
    }
    const addRegisterTimeInfo = (pid: number) => {
        var data = registTimeInfo[registTimeInfo.length - 1];
        var index = 0;
        if (data) {
            index = data.key + 1;
        }
        registTimeInfo.push({ key: index, pid: pid })
        // console.log("registTimeInfo", registTimeInfo)
        setRegisterTimeInfo([...registTimeInfo]);
    }

    return <>
        {formModalShow ? <Form
            form={formModal}
            onFinish={onFinishModal}
            onReset={onReset}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
        >
            {regionInfo ? <>{
                regionInfo.map((el: any, elIndex: any) => {
                    return <div key={el.key}>
                        <Form.Item label={"地区定价" + (elIndex + 1)} style={{ marginBottom: 0 }} required={true}>
                            <Form.Item name={["regionId" + el.key]} label="国家/地区" style={{ width: "200px", display: 'inline-block' }}>
                                <Select allowClear showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={(e) => {
                                        // 选到相同国家则删除并且提示,获取formModal的值去判断
                                        var regionMap = formModal.getFieldsValue();
                                        var regionId = "regionId" + el.key;
                                        var regionName = regionMap[regionId];
                                        var count = 0;
                                        Object.keys(regionMap).map((key) => {
                                            // 计算regionName的个数 大于1就是重复
                                            if (regionMap[key] == regionName) {
                                                count++;
                                            }
                                        })
                                        if (count > 1) {
                                            message.error("国家重复")
                                            formModal.setFieldsValue({ [regionId]: "" })
                                        }
                                    }}
                                >
                                    {Object.keys(regionMap).map((key) => {
                                        return <Select.Option key={key} value={key}>{regionMap[key]}</Select.Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item name={["money" + el.key]} label="定价" style={{ marginLeft: '10px', display: 'inline-block' }}>
                                <InputNumber precision={0} min={0} placeholder="输入定价"></InputNumber>
                            </Form.Item>
                            <Form.Item name={["vipMoney" + el.key]} label="vip定价" style={{ marginLeft: '10px', display: 'inline-block' }}>
                                <InputNumber precision={0} min={0} placeholder="输入定价"></InputNumber>
                            </Form.Item>
                            <Form.Item style={{ display: 'inline-block' }}>
                                <DeleteOutlined style={{
                                    marginLeft: '10px',
                                    fontSize: '25px'
                                }} onClick={() => {
                                    removeRegionInfo(el.key)
                                }} />
                            </Form.Item>
                        </Form.Item>
                        {registTimeInfo.map((elc: any, cIndex: any) => {
                            return <div key={elc.key}>
                                {elc.pid == elIndex ? <>
                                    <Form.Item label={"注册时间" + (cIndex + 1)} style={{ marginBottom: 0 }}>
                                        <Form.Item name={"sendTime" + el.key + "_" + elc.key} style={{ display: 'inline-block' }}>
                                            <RangePicker
                                                onOk={res => {
                                                    // 判断两个时间都选择了
                                                    if (res && res.length == 2 && res[0] && res[1]) {
                                                        // 重复就删除
                                                        var timeTow = false;
                                                        var registerMoney: any = [];
                                                        var registerTime = formModal.getFieldsValue();
                                                        var regionId = "regionId" + el.key;
                                                        var regionName = registerTime[regionId];
                                                        var startTimeInfo = "sendTime" + el.key + "_" + elc.key;
                                                        var timeMoney = "registerPrice" + el.key + "_" + elc.key;
                                                        var vipTimeMoney = "vipRegisterPrice" + el.key + "_" + elc.key;
                                                        registerTime[startTimeInfo] = res;
                                                        registerTime[timeMoney] = registerTime[timeMoney];
                                                        registerTime[vipTimeMoney] = registerTime[vipTimeMoney];
                                                        registTimeInfo.forEach((el: any, index: any) => {
                                                            if (el.pid == elc.pid) {
                                                                var sendTime = "sendTime" + el.pid + "_" + el.key;
                                                                var registerPrice = "registerPrice" + el.pid + "_" + el.key;
                                                                var vipRegisterPrice = "vipRegisterPrice" + el.pid + "_" + el.key;
                                                                registerMoney.push({
                                                                    startTime: registerTime[sendTime][0].valueOf(),
                                                                    endTime: registerTime[sendTime][1].valueOf(),
                                                                    registerPrice: registerTime[registerPrice],
                                                                    vipRegisterPrice: registerTime[vipRegisterPrice]
                                                                })
                                                            }
                                                        })
                                                        if (registerMoney) {
                                                            registerMoney.forEach((el: any, cIndex: any) => {
                                                                if (isOverlapping(registerMoney)) {
                                                                    timeTow = true;
                                                                }
                                                            })
                                                        }
                                                        if (timeTow) {
                                                            message.error("时间段重复")
                                                            formModal.setFieldsValue({ [startTimeInfo]: [] })
                                                        }
                                                    }
                                                }}
                                                style={{ width: "330px" }}
                                                showTime={{ format: 'HH:mm:ss' }}
                                                format="YYYY-MM-DD HH:mm:ss" />
                                        </Form.Item>
                                        <Form.Item name={"registerPrice" + el.key + "_" + elc.key} style={{ display: 'inline-block' }}>
                                            <InputNumber precision={0} min={0} placeholder="输入定价"></InputNumber>
                                        </Form.Item>
                                        <Form.Item name={"vipRegisterPrice" + el.key + "_" + elc.key} style={{ display: 'inline-block' }}>
                                            <InputNumber precision={0} min={0} placeholder="输入vip定价"></InputNumber>
                                        </Form.Item>
                                        <Form.Item style={{ display: 'inline-block' }}>
                                            <DeleteOutlined style={{
                                                marginLeft: '10px',
                                                fontSize: '25px'
                                            }} onClick={() => {
                                                removeRegisterTimeInfo(elc.key)
                                            }} />
                                        </Form.Item>
                                    </Form.Item>
                                </> : <></>}
                            </div>
                        })}
                        <Form.Item>
                            <Button style={{ marginLeft: "60px" }} onClick={() => addRegisterTimeInfo(elIndex)} type={'link'} size={"small"} >+添加注册时间</Button>
                        </Form.Item>
                    </div>
                })
            }
            </> : <></>}
            <Form.Item>
                <Button style={{ marginLeft: "60px" }} onClick={addRegionInfo} type={'link'} size={"small"} >+添加地区定价</Button>
            </Form.Item>
            {/* <Form.Item label={"xxxx"} name={"good"}>
                <Input></Input>
            </Form.Item> */}
            <Row justify={"end"}>
                <Space size={'large'}>
                    <Popconfirm title={"确认提交？"} okText={"确认"} cancelText={"取消"}
                        onConfirm={() => { formModal.submit() }}>
                        <Button loading={btnLoading} type={'primary'} onClick={() => {

                        }}>提交</Button>
                    </Popconfirm>
                </Space>
            </Row>
        </Form> : <></>}
    </>
}