import { Button, Checkbox, Form, Select, Upload } from "antd";
import { Content, Header } from "antd/es/layout/layout"
import { PlusOutlined, RollbackOutlined, DeleteOutlined } from '@ant-design/icons';
import { useEffect } from "react";

interface IChatMap_Cont {
    curRole: any
    close: () => void
    onClick_del: () => void
    onFormChange: (val: any) => void
    selectedNode: any
    storyNode: any
    storyMap: any
}

const ChatMap_Header = (props: IChatMap_Cont) => {
    const { curRole, close, onClick_del, selectedNode, onFormChange, storyNode, storyMap } = props
    const [form] = Form.useForm();
    useEffect(() => {
        if (!storyNode) return
        const formData = storyMap.current[storyNode.id] || {}
        form.setFieldsValue({
            dialogStyle: formData?.dialogStyle,
            isVibrate: formData?.isVibrate,
            isSound: formData?.isSound,
            soundUrl: formData?.soundUrl
        });
    }, [storyNode, form])
    return <Header style={headerStyle}>
        <div style={{ height: 40, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {/* 返回 */}
            <Button
                style={{ marginLeft: 15 }}
                onClick={close}
                type="primary"
                icon={<RollbackOutlined />} >
                退出编辑
            </Button>
            <Form
                onValuesChange={(val) => {
                    onFormChange(val)
                }}
                form={form}
                layout="inline"
                style={{ alignItems: 'center' }}>
                {/* 样式选择 */}
                <Form.Item
                    name="dialogStyle"
                    noStyle>
                    <Select
                        defaultValue="1"
                        style={{ width: 130, marginLeft: 15 }}
                        options={[
                            { label: <span>单人消息样式</span>, value: 1 },
                            { label: <span>消息汇总样式</span>, value: 2 },
                            { label: <span>主动聊天样式</span>, value: 3 },
                        ]}
                    />
                </Form.Item>
                {/* 设置 */}
                <Form.Item
                    name="isVibrate"
                    valuePropName="checked"
                    getValueProps={(value) => ({ checked: value === 1 })}
                    getValueFromEvent={(e) => (e.target.checked ? 1 : 0)}
                    noStyle>
                    <Checkbox
                        style={{ marginLeft: 20 }}
                        onChange={() => { }}>弹框震动</Checkbox>
                </Form.Item>
                <Form.Item
                    name="isSound"
                    valuePropName="checked"
                    getValueProps={(value) => ({ checked: value === 1 })}
                    getValueFromEvent={(e) => (e.target.checked ? 1 : 0)}
                    noStyle>
                    <Checkbox
                        style={{ marginLeft: 15 }}
                        onChange={() => { }}>弹框音效</Checkbox>
                </Form.Item>


                {/* 增加根节点 */}
                <Form.Item
                    name="soundUrl"
                    noStyle>
                    <Upload {...props}>
                        <Button
                            style={{ marginLeft: 15 }}
                            icon={<PlusOutlined />}>
                            添加音效
                        </Button>
                    </Upload>
                </Form.Item>
            </Form>

            <div style={{ flex: 1 }}>
                {/* 删除 */}
                <Button
                    danger
                    onClick={onClick_del}
                    disabled={!selectedNode}
                    style={{ float: 'right', marginRight: 15 }}
                    type="primary"
                    icon={<DeleteOutlined />} />
            </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '0 15px' }}>
                <div style={{
                    height: 20, width: 30, backgroundColor: '#fff', marginRight: 6,
                    borderRadius: 8, boxShadow: 'rgb(209 220 227) 2px 2px 5px 0px'
                }} />
                <div>我方对话</div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 20 }}>
                <div style={{
                    height: 20, width: 30, backgroundColor: '#fff',
                    marginRight: 6, boxShadow: 'rgb(209 220 227) 2px 2px 5px 0px'
                }} />
                <div>对方对话</div>
            </div>
        </div>
    </Header>
}

const headerStyle: React.CSSProperties = {
    backgroundColor: '#f3f5f7',
    height: 90,
    padding: 0,
    lineHeight: 0,
    paddingTop: 10,
};


export default ChatMap_Header