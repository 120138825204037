import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { message } from "antd";
interface ILANG_LANGDto {
    /** 语言 */
    language: string,
    /** 内容 */
    name?: string,
    /** 视频地址 */
    videoUrl?: string,
    /** list内容 */
    contents?: string[],
    /** gifUrl */
    girUrl?: string
}

interface ILanguage {
    name: string | ReactNode
    key: string
    label: string
    noNeedClose?: boolean

}
//根据语言拿去数据，先中文，再英文，在没有就第一个
export function getOneLangDto(arr: ILANG_LANGDto[]): ILANG_LANGDto {
    let zhDto = null;
    let enDto = null;
    arr.forEach(item => {
        if (item.language == "zh") {
            zhDto = item;
        } else if (item.language == "en") {
            enDto = item;
        }
    })
    let resultDto: any = enDto ? enDto : (zhDto ? zhDto : arr[0]);
    return resultDto;
}

//服务端的转为页面用
export function dealLangToPage(arr: ILANG_LANGDto[]): any[] {
    let contents: any[] = [];
    arr.forEach(el => {
        let obj = {};
        Object.defineProperty(obj, el.language, { value: el.name })
        contents.push(obj)
    });
    return contents
}

//服务端转为页面用list
export function dealLangToPageList(arr: ILANG_LANGDto[]): any[] {
    let contents: any[] = [];
    arr.forEach(el => {
        let obj = {};
        Object.defineProperty(obj, el.language, { value: el.contents })
        contents.push(obj)
    });
    return contents
}

//页面数据转为服务端用
export function dealLangToService(data: any[]): ILANG_LANGDto[] {
    var langInfo: ILANG_LANGDto[] = [];
    data.forEach(e => {
        for (var k in e) {
            if (e[k]) {
                langInfo.push({
                    "name": e[k],
                    "language": k
                })
            }
        }
    })
    return langInfo;
}

//页面数据转为服务端用
export function dealLangToServiceContent(data: any): ILANG_LANGDto[] {
    var langInfo: ILANG_LANGDto[] = [];
    Object.keys(data).forEach((e: any) => {
        langInfo.push({
            "contents": data[e]["contents"],
            "language": data[e]["lang"]
        })
    })
    return langInfo;
}

//页面图片多语言转为服务端用
export function dealLangImgToService(languageImgData: any) {
    let imgs: any = []
    languageImgData.current.forEach((el: any) => {
        const language = el["language"]
        const name = el["imgs"].toString()
        const gifUrl = el["gifUrl"]?.toString()
        const imgUrl = el["imgUrl"]?.toString()
        imgs.push({ "language": language, "name": name, "gifUrl": gifUrl, "imgUrl": imgUrl })
    })
    return imgs
}

//获取到所有语言
export function getAppLangs(): ILanguage[] {
    return [{ "name": "英文", "key": "en", "label": "英文" }, { "name": "中文", "key": "zh", "label": "中文" },
    { "name": "繁体", "key": "cht", "label": "繁体" }, { "name": "德语", "key": "de", "label": "德语" },
    { "name": "西班牙语", "key": "spa", "label": "西班牙语" }, { "name": "葡萄牙语", "key": "pt", "label": "葡萄牙语" },
    { "name": "日语", "key": "jp", "label": "日语" }, { "name": "韩语", "key": "kor", "label": "韩语" }, 
    { "name": "印尼语", "key": "idn", "label": "印尼语" },{ "name": "俄语", "key": "ru", "label": "俄语" },
    { "name": "泰语", "key": "th", "label": "泰语" }, { "name": "法语", "key": "fra", "label": "法语" }
    
    ]
}

//获取到所有语言
export function getAppLangs2(): any {
    let value: any = [];
    getAppLangs().map((item: any) => {
        value.push({
            "name": item.name,
            "value": item.key,
            "label": item.label
        })
    })
    return value;
}

//获取到所有语言
export function getAppLangKeys(): any {
    return ["zh", "en", "ru", "de"];
}

//验证图片是否正常
export function verifyImg(languageImgData: any): Boolean {
    if (languageImgData.current.length == 0) {
        message.error("有语言图片未上传完整，请检查")
        return false
    } else {
        var pass = true;
        languageImgData.current.forEach((el: any) => {
            if (!el["imgs"][0]) {
                message.error("有语言图片未上传完整，请检查")
                pass = false;
                return pass;
            }
        })
        return pass
    }
}

export function delDataInArray(data: any, key: any, datakey?: any): any {
    const index = data.findIndex((el: any) => datakey ? el[datakey] === key : el === key)
    index !== -1 && data.splice(index, 1)
}