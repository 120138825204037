import { Button, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Checkbox, Radio, AutoComplete, message, Tag, Tabs, Modal, DatePicker } from "antd";
import { useEffect, useState, useRef } from "react";
import { playInfoService } from "../../../../service/play/play-info-service";
import ChooseLang from "../../../../components/common/ChooseLang";
import { dealLangToService, dealLangToPage, getOneLangDto, verifyImg, dealLangImgToService, delDataInArray, getAppLangs2 } from "../../../../utils/lang";
import { SpecialPriceForm } from "../../../../components/play-info/special-price";
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import { DeleteOutlined } from '@ant-design/icons';
dayjs.extend(weekday);
dayjs.extend(localeData);
export interface IProps_GlobalEditForm {
    playId: string;
    onSuccess: () => void;
}
const { RangePicker } = DatePicker;
export const GlobalEditForm = (props: IProps_GlobalEditForm) => {
    const { playId, onSuccess } = props;
    const [form] = Form.useForm();//套餐设置
    const [ctrlForm] = Form.useForm();//控件设置
    const [formShow, setFormShow] = useState<any>();//这是为了一次性渲染
    const [btnLoading, setBtnLoading] = useState(false);
    const contentLang = useRef<any>([]) //映射的语言数据
    const [visible, setVisible] = useState(false);
    const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');    // 特殊价格模态框模式
    const [specialPrice, setSpecialPrice] = useState<any>();//特殊价格

    const [playInfo, setPlayInfo] = useState<any>();//短剧信息

    const [registTimeInfo, setRegisterTimeInfo] = useState<any>([]);//半包注册时间信息

    const [allRegistTimeInfo, setAllRegisterTimeInfo] = useState<any>([]);//全包注册时间信息

    /**
     * 模态框关闭
     */
    const onFormClose = () => {
        setVisible(false);
        setSpecialPrice(null);
    }

    /**
     * 模态框编辑
     */
    const onEditClick = () => {
        setSpecialPrice(playInfo);
        setModalMode('edit');
        setVisible(true);
    }

    function isOverlapping(intervals: any) {
        for (let i = 0; i < intervals.length; i++) {
            for (let j = i + 1; j < intervals.length; j++) {
                if (intervals[i].startTime < intervals[j].endTime && intervals[i].endTime > intervals[j].startTime) {
                    return true; // 重叠
                }
            }
        }
        return false; // 无重叠
    }

    //半包
    const removeRegisterTimeInfo = (index: number) => {
        registTimeInfo.splice(registTimeInfo.findIndex((el: any) => el.key === index), 1);
        setRegisterTimeInfo([...registTimeInfo])
    }
    const addRegisterTimeInfo = () => {
        var data = registTimeInfo[registTimeInfo.length - 1];
        var index = 0;
        if (data) {
            index = data.key + 1;
        }
        registTimeInfo.push({ key: index })
        setRegisterTimeInfo([...registTimeInfo]);
    }

    //全包
    const removeAllRegisterTimeInfo = (index: number) => {
        allRegistTimeInfo.splice(allRegistTimeInfo.findIndex((el: any) => el.key === index), 1);
        setAllRegisterTimeInfo([...allRegistTimeInfo])
    }
    const addAllRegisterTimeInfo = () => {
        var data = allRegistTimeInfo[allRegistTimeInfo.length - 1];
        var index = 0;
        if (data) {
            index = data.key + 1;
        }
        allRegistTimeInfo.push({ key: index })
        setAllRegisterTimeInfo([...allRegistTimeInfo]);
    }

    const versionInfo = () => {
        return <>
            {formShow ? <Form
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 20 }}
                style={{ marginTop: 30 }}
            >
                <Form.Item
                    label="半包(解锁全部付费章节)"
                    name={"isHalf"}
                    required
                >
                    <Checkbox defaultChecked={form.getFieldValue("isHalf") == 1 ? true : false}
                        onChange={(e) => {
                            form.setFieldValue("isHalf", e.target.checked ? 1 : 0)
                        }}>启用</Checkbox>
                    <Button style={{ marginLeft: "30px" }} onClick={addRegisterTimeInfo} type={'link'} size={"small"} >+添加注册时间</Button>
                    <Button style={{ marginLeft: "30px" }} onClick={() => { onEditClick() }} type={'link'} size={"small"} >{"设置特殊定价>"}</Button>
                </Form.Item>
                <Form.Item
                    label="付费金额(Point/Bonus)"
                    name={"halfMoney"}
                    required
                >
                    <InputNumber min={1}></InputNumber>
                </Form.Item>
                <Form.Item
                    label="会员优惠价"
                    name={"vipHalfMoney"}
                    required
                >
                    <InputNumber min={1}></InputNumber>
                </Form.Item>
                <Form.Item
                    label="付费模式"
                    name={"halfPayType"}
                    required
                >
                    <Radio.Group >
                        <Radio key={2} value={2}>仅Points</Radio>
                        <Radio key={1} value={1}>Points+Bonus</Radio>
                    </Radio.Group>
                </Form.Item>
                {registTimeInfo ? <>
                    {registTimeInfo.map((el: any, elIndex: any) => {
                        return <div key={elIndex}><Form.Item label={"注册时间" + (elIndex + 1)} style={{ marginBottom: 0 }}>
                            <Form.Item name={"sendTime" + el.key} style={{ display: 'inline-block' }}>
                                <RangePicker
                                    onOk={(res) => {
                                        if (res && res.length == 2 && res[0] && res[1]) {
                                            // 如果时间段有重复 则提示并且删除
                                            var registerMoney: any = [];
                                            registTimeInfo.forEach((el: any, index: any) => {
                                                var startTimeInfo = "sendTime" + el.key;
                                                var registerPrice = "registerPrice" + el.key;
                                                var vipRegisterPrice ="vipRegisterPrice"+ el.key;
                                                registerMoney.push({
                                                    startTime: form.getFieldValue(startTimeInfo)[0].valueOf(),
                                                    endTime: form.getFieldValue(startTimeInfo)[1].valueOf(),
                                                    registerPrice: form.getFieldValue(registerPrice),
                                                    vipRegisterPrice:form.getFieldValue(vipRegisterPrice)
                                                })
                                            })
                                            if (isOverlapping(registerMoney)) {
                                                message.error("时间段有重复")
                                                form.setFieldsValue({
                                                    ["sendTime" + el.key]: null,
                                                    ["registerPrice" + el.key]: null,
                                                    ["vipRegisterPrice"+ el.key]: null
                                                })
                                                removeRegisterTimeInfo(el.key)
                                            }
                                        }
                                    }}
                                    style={{ width: "330px" }}
                                    showTime={{ format: 'HH:mm:ss' }}
                                    format="YYYY-MM-DD HH:mm:ss" />
                            </Form.Item>
                            <Form.Item name={"registerPrice" + el.key} style={{ display: 'inline-block' }}>
                                <InputNumber precision={0} min={0} placeholder="输入定价"></InputNumber>
                            </Form.Item>
                            <Form.Item name={"vipRegisterPrice" + el.key} style={{ display: 'inline-block' }}>
                                <InputNumber precision={0} min={0} placeholder="输入vip定价"></InputNumber>
                            </Form.Item>
                            <Form.Item style={{ display: 'inline-block' }}>
                                <DeleteOutlined style={{
                                    marginLeft: '10px',
                                    fontSize: '25px'
                                }} onClick={() => {
                                    removeRegisterTimeInfo(el.key)
                                }} />
                            </Form.Item>
                        </Form.Item>
                        </div>
                    })}
                </> : <></>}

                <Form.Item
                    label="全包(解锁全部付费章节+付费选项)"
                    name={"isAll"}
                    required
                >
                    <Checkbox defaultChecked={form.getFieldValue("isAll") == 1 ? true : false}
                        onChange={(e) => {
                            form.setFieldValue("isAll", e.target.checked ? 1 : 0)
                        }}>启用</Checkbox>
                    <Button style={{ marginLeft: "30px" }} onClick={addAllRegisterTimeInfo} type={'link'} size={"small"} >+添加注册时间</Button>
                </Form.Item>
                <Form.Item
                    label="付费金额(Point/Bonus)"
                    name={"allMoney"}
                    required
                >
                    <InputNumber min={1}></InputNumber>
                </Form.Item>
                <Form.Item
                    label="会员优惠价"
                    name={"vipAllMoney"}
                    required
                >
                    <InputNumber min={1}></InputNumber>
                </Form.Item>
                <Form.Item
                    label="付费模式"
                    name={"allPayType"}
                    required
                >
                    <Radio.Group >
                        <Radio key={2} value={2}>仅Points</Radio>
                        <Radio key={1} value={1}>Points+Bonus</Radio>
                    </Radio.Group>
                </Form.Item>
                {allRegistTimeInfo ? <>
                    {allRegistTimeInfo.map((el: any, elIndex: any) => {
                        return <div key={elIndex}><Form.Item label={"注册时间" + (elIndex + 1)} style={{ marginBottom: 0 }}>
                            <Form.Item name={"allSendTime" + el.key} style={{ display: 'inline-block' }}>
                                <RangePicker
                                    onOk={(res) => {
                                        if (res && res.length == 2 && res[0] && res[1]) {
                                            // 如果时间段有重复 则提示并且删除
                                            var registerMoney: any = [];
                                            allRegistTimeInfo.forEach((el: any, index: any) => {
                                                var startTimeInfo = "allSendTime" + el.key;
                                                var registerPrice = "allRegisterPrice" + el.key;
                                                var vipRegisterPrice ="allVipRegisterPrice" + el.key;
                                                registerMoney.push({
                                                    startTime: form.getFieldValue(startTimeInfo)[0].valueOf(),
                                                    endTime: form.getFieldValue(startTimeInfo)[1].valueOf(),
                                                    registerPrice: form.getFieldValue(registerPrice),
                                                    vipRegisterPrice:form.getFieldValue(vipRegisterPrice),
                                                })
                                            })
                                            if (isOverlapping(registerMoney)) {
                                                message.error("时间段有重复")
                                                form.setFieldsValue({
                                                    ["allSendTime" + el.key]: null,
                                                    ["allRegisterPrice" + el.key]: null,
                                                    ["allVipRegisterPrice" + el.key]: null
                                                })
                                                removeAllRegisterTimeInfo(el.key)
                                            }
                                        }
                                    }}
                                    style={{ width: "330px" }}
                                    showTime={{ format: 'HH:mm:ss' }}
                                    format="YYYY-MM-DD HH:mm:ss" />
                            </Form.Item>
                            <Form.Item name={"allRegisterPrice" + el.key} style={{ display: 'inline-block' }}>
                                <InputNumber precision={0} min={0} placeholder="输入定价"></InputNumber>
                            </Form.Item>
                            <Form.Item name={"allVipRegisterPrice" + el.key} style={{ display: 'inline-block' }}>
                                <InputNumber precision={0} min={0} placeholder="输入vip定价"></InputNumber>
                            </Form.Item>
                            <Form.Item style={{ display: 'inline-block' }}>
                                <DeleteOutlined style={{
                                    marginLeft: '10px',
                                    fontSize: '25px'
                                }} onClick={() => {
                                    removeAllRegisterTimeInfo(el.key)
                                }} />
                            </Form.Item>
                        </Form.Item>
                        </div>
                    })}
                </> : <></>}
                <Row justify={"end"}>
                    <Space size={'large'}>
                        <Popconfirm style={{

                        }} title={"确认保存？"} okText={"确认"} cancelText={"取消"}
                            onConfirm={() => onFinish(form.getFieldsValue())}>
                            <Button loading={btnLoading} type={'primary'} >保存</Button>
                        </Popconfirm>
                    </Space>
                </Row>
            </Form> : <></>}
        </>
    }
    const ctrlInfo = () => {
        return <>
            {formShow ? <Form
                form={ctrlForm}
                onFinish={onFinish}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                style={{ marginTop: 30 }}
            >
                <Form.Item
                    label="点击震动"
                    name={"isVibrate"}
                    required
                >
                    <Checkbox defaultChecked={ctrlForm.getFieldValue("isVibrate") == 1 ? true : false}
                        onChange={(e) => {
                            ctrlForm.setFieldValue("isVibrate", e.target.checked ? 1 : 0)
                        }}>启用</Checkbox>
                </Form.Item>
                <Row justify={"end"}>
                    <Space size={'large'}>
                        <Popconfirm style={{

                        }} title={"确认保存？"} okText={"确认"} cancelText={"取消"}
                            onConfirm={() => onFinish(ctrlForm.getFieldsValue())}>
                            <Button loading={btnLoading} type={'primary'} >保存</Button>
                        </Popconfirm>
                    </Space>
                </Row>
            </Form> : <></>}</>
    }

    const initialItems = [
        { label: '套餐设置', children: versionInfo(), key: '1', closable: false },
        { label: '控件设置', children: ctrlInfo(), key: '2', closable: false },
    ];

    const [activeKey, setActiveKey] = useState(initialItems[0].key);

    useEffect(() => {
        fillForm();
    }, []);

    const onFinish = (values: any) => {
        values.id = playId;
        var halfRegisterMoney: any = [];
        registTimeInfo.forEach((el: any, index: any) => {
            var startTimeInfo = "sendTime" + el.key;
            var registerPrice = "registerPrice" + el.key;
            var vipRegisterPrice = "vipRegisterPrice" + el.key;
            halfRegisterMoney.push({
                startTime: values[startTimeInfo][0].valueOf(),
                endTime: values[startTimeInfo][1].valueOf(),
                registerPrice: values[registerPrice],
                vipRegisterPrice: values[vipRegisterPrice]
            })
        })
        //判断时间是否重复
        if (isOverlapping(halfRegisterMoney)) {
            message.error("时间段有重复")
            return;
        }
        values.halfRegisterMoney = halfRegisterMoney;

        var allRegisterMoney: any = [];
        allRegistTimeInfo.forEach((el: any, index: any) => {
            var startTimeInfo = "allSendTime" + el.key;
            var registerPrice = "allRegisterPrice" + el.key;
            var vipRegisterPrice = "allVipRegisterPrice" + el.key;
            allRegisterMoney.push({
                startTime: values[startTimeInfo][0].valueOf(),
                endTime: values[startTimeInfo][1].valueOf(),
                registerPrice: values[registerPrice],
                vipRegisterPrice: values[vipRegisterPrice]
            })
        })
        //判断时间是否重复
        if (isOverlapping(allRegisterMoney)) {
            message.error("时间段有重复")
            return;
        }
        values.allRegisterMoney = allRegisterMoney;
        //处理多语言结构
        form.validateFields().then(() => {
            setBtnLoading(true);
            playInfoService.edit(values)
                .then(() => {
                    onSuccess && onSuccess();
                    setBtnLoading(false);
                })
                .catch((res) => {
                    message.error(res.msg.msg);
                    setBtnLoading(false);
                })
        })

    }

    const fillForm = () => {
        // console.log(GlobalEditEdit)
        //初始化数据
        playInfoService.getById(playId).then((res) => {
            if (res.data) {
                var data = res.data;
                data.isVibrate = data.isVibrate ? data.isVibrate : 0;
                data.isHalf = data.isHalf ? data.isHalf : 0;
                data.isAll = data.isAll ? data.isAll : 0;

                //半包注册时间信息
                var registerMoney = data["halfRegisterMoney"]
                var registTimeInfoEdit: any = [];//配置信息
                registerMoney?.length > 0 && registerMoney.forEach((el: any, index: any) => {
                    var sendTime = "sendTime" + index;
                    var registerPrice = "registerPrice" + index;
                    var vipRegisterPrice = "vipRegisterPrice" + index;
                    data[sendTime] = [];
                    data[sendTime][0] = dayjs(el["startTime"])
                    data[sendTime][1] = dayjs(el["endTime"])
                    data[registerPrice] = el.registerPrice;
                    data[vipRegisterPrice] =el.vipRegisterPrice;
                    registTimeInfoEdit.push({
                        key: index
                    })
                })
                setRegisterTimeInfo([...registTimeInfoEdit])
                //全包
                var allRegisterMoney = data["allRegisterMoney"]
                var allRegistTimeInfoEdit: any = [];//配置信息
                allRegisterMoney?.length > 0 && allRegisterMoney.forEach((el: any, index: any) => {
                    var sendTime = "allSendTime" + index;
                    var registerPrice = "allRegisterPrice" + index;
                    var vipRegisterPrice = "allVipRegisterPrice" + index;
                    data[sendTime] = [];
                    data[sendTime][0] = dayjs(el["startTime"])
                    data[sendTime][1] = dayjs(el["endTime"])
                    data[registerPrice] = el.registerPrice;
                    data[vipRegisterPrice] =el.vipRegisterPrice;
                    allRegistTimeInfoEdit.push({
                        key: index
                    })
                })
                setAllRegisterTimeInfo([...allRegistTimeInfoEdit])
                setPlayInfo(data);
                form.setFieldsValue(data);
                ctrlForm.setFieldsValue(data);
                setFormShow({ 1: 1 })
            }
        })

    }

    /**
     * 表单重置
     */
    const onReset = () => {
        form.resetFields();
    }



    return <div>
        <Tabs
            tabPosition={'left'}
            type="card"
            onChange={(e) => {
                setActiveKey(e);
            }}
            activeKey={activeKey}
            // onEdit={null}
            items={initialItems}
        />
        <Modal
            open={visible}
            maskClosable={false}
            destroyOnClose={true}
            closable={true}
            footer={null}
            onCancel={() => onFormClose()}
            style={{ minWidth: "800px" }}
            styles={{
                body: {
                    padding: "20px 40px 0 0"
                }
            }}
        >
            <SpecialPriceForm
                specialPrice={specialPrice}
                onSave={(values: any, saveCb: any) => {
                    playInfoService.edit(values)
                        .then((res: any) => {
                            message.success("操作成功")
                            saveCb && saveCb()
                            setVisible(false)
                            setPlayInfo(res.data)
                            // setBtnLoading(false);
                        })
                        .catch(() => {
                            message.error("操作失败")
                            // setBtnLoading(false);
                        })
                }}
            />
        </Modal>
    </div>
}