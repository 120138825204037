import { Button, Checkbox, Form, Input, Upload } from "antd"
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CHAT_TYPE_MAP } from "../hook/UseChatMapData";
import ChooseLang from "../../../../../components/common/ChooseLang";
import { useEffect, useRef, useState } from "react";
import SelectForm from "../../NodeInfo/Select_Form";
import ConditionForm from "../../NodeInfo/ConditionForm";
import { dealLangToPage, dealLangToService } from "../../../../../utils/lang";
import ImgUpload from "../../../../../components/common/Upload";

const ChatMap_RigForm = (props: any) => {
    const { info, close, onSubmit } = props

    const onFinish = (value: any) => {
        console.log('🍖🍖🍖 ~ value:', value)
        value.nameInfo = value.nameInfo && dealLangToService(value.nameInfo)
        value.nameInfo = value.nameInfo.map((el: any) => {
            return {
                ...el,
                imgUrl: value.imgUrl,
                name: value.imgUrl ? '' : el.name
            }
        })
        onSubmit(value, info.type)
    }

    return <div className="rigSidebar"
        style={{
            height: '100%',
            transition: 'all .3s',
            width: info ? (info.type == 1 ? 350 : 600) : 0,
            backgroundColor: 'white',
            borderLeft: '1px solid #ddd',
        }}>
        <div style={{
            height: 50,
            width: info ? (info.type == 1 ? 350 : 600) : 0,
            lineHeight: '40px',
            paddingLeft: 15,
            borderBottom: '1px solid #ddd',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            zIndex: 1
        }}>
            <div style={{ height: 18, width: 5, backgroundColor: '#007BFF', marginRight: 12 }} />
            <h3 style={{ flex: 1, justifyContent: 'center', color: '#333' }}>{CHAT_TYPE_MAP[info?.type - 1]?.name}</h3>
            {/* 关闭 */}
            <Button
                style={{ height: 25, width: 25, lineHeight: 0, padding: 0, marginRight: 15 }}
                onClick={close}
                type="text"
                icon={<CloseOutlined style={{ fontSize: 18 }} />}
            />
        </div>
        {/* 对方设置  */}
        {info?.type == 1 && <NpcChatForm onFinish={onFinish} info={info} />}
        {/* 我方设置 */}
        {info?.type == 2 && <SelectForm onFinish={onFinish} info={info} isChatForm />}
        {/* 条件设置 */}
        {info?.type == 3 && <ConditionForm onFinish={onFinish} info={info} />}
    </div>
}

const NpcChatForm = (props: any) => {
    const [imageUrl, setImageUrl] = useState<string>();
    const { onFinish, info } = props
    const introLang = useRef<any>([]) //映射简介语言数据
    const [form] = Form.useForm();

    useEffect(() => {
        if (!info) return
        if(info.nameInfo?.length > 0){
            setImageUrl(info.nameInfo[0].imgUrl)
        }
        form.setFieldsValue({
            nameInfo: info.nameInfo && dealLangToPage(info.nameInfo),
            history: info.history || 0,
            conditionType: info.conditionDetail?.conditionType || 1,
            children: info.conditionDetail?.children || [
                {
                    conditionType: 1,
                    children: [
                        { valueType: 1, opType: 1, optionId: 1 }
                    ]
                }
            ]
        });
    }, [info, form])

    return <Form
        form={form}
        style={{
            width: 350,
            padding: 12,
            marginTop: 50,
            // 滚动
            overflow: 'auto',
            height: 'calc(100% - 100px)',
        }}
        name="form_item_path"
        onFinish={onFinish}>

        <ChooseLang
            language={introLang.current}
            renderTab={(index, language) => {
                return <>
                    <Form.Item name={["nameInfo", index, language.key]} label="内容" >
                        <Input placeholder='请输入' />
                    </Form.Item>
                </>
            }} />

        <Form.Item name="imgUrl">
            <ImgUpload
                type={4}
                img={imageUrl ? [{ url: `${process.env.REACT_APP_CND_URL}${imageUrl}` }] : []}
                del={() => {
                    setImageUrl('')
                }}
                success={(res: any) => {
                    const newImageUrl = res;
                    setImageUrl(newImageUrl);
                }} />
        </Form.Item>

        <Form.Item
            name="history"
            valuePropName="checked"
            getValueProps={(value) => ({ checked: value === 1 })}
            getValueFromEvent={(e) => (e.target.checked ? 1 : 0)}
        >
            <Checkbox>设为历史记录</Checkbox>
        </Form.Item>

        <div style={{ height: 50 }}>
            <Button type="primary" htmlType="submit">
                保存
            </Button>
        </div>
    </Form>
}

export default ChatMap_RigForm