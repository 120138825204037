import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Modal, message, Switch } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import { useEffect, useState } from "react";
import { valueNumberService } from "../../../service/play/value-number-service";
import { getOneLangDto } from "../../../utils/lang";
import { ValueNumberEditForm } from "./EditModal/valueNumber-edit-form";
import { Link } from 'react-router-dom';
import dayjs from "dayjs";
import { AnyObject } from "antd/lib/_util/type";
const { confirm } = Modal;
export interface IProps_ValueNumberList {
    playId: string
}
const ValueNumberList = (props: IProps_ValueNumberList) => {
    const { playId } = props;
    const [msgApi, contextHolder] = message.useMessage();

    const [form] = Form.useForm()
    const [valueNumberList, setvalueNumberList] = useState([])
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    // 模态框是否可见
    const [visible, setVisible] = useState(false);
    // 模态框模式
    const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');
    // 当前选中的短剧信息,临时用 any存
    const [valueNumber, setvalueNumber] = useState<any>();

    const onFinish = () => {
        setPageNum(1);
        searchvalueNumber(1);
    }

    const onReset = () => {
        form.resetFields();
        searchvalueNumber();
    }

    useEffect(() => {
        searchvalueNumber();
    }, []);

    //搜索
    const searchvalueNumber = (page?: number, size?: number) => {
        const fieldsValue = form.getFieldsValue();
        const param = {
            playId,
            pageNum: page ? (page - 1) : (pageNum - 1), // 服务端是从 0 开始
            pageSize: size ? size : pageSize,
            ...fieldsValue // 其他筛选条件，来自表单属性，属性明明和服务端一致
        }

        console.log("表单值：", param);
        valueNumberService.list(param)
            .then(res => {
                console.log("valueNumber list res", res);
                const list = res.data.content;
                const totalElements = res.data.totalElements;
                setvalueNumberList(list);
                setTotal(totalElements);
            })
            .catch(err => {
                console.error("valueNumber list err", err);
            });
    }

    const columns = [
        // {
        //     key: "valueNumber",
        //     dataIndex: "valueNumber",
        //     title: "终止版本",
        //     render: (text: any) => {
        //         return text
        //     }
        // },
        {
            key: "nameInfo",
            dataIndex: "nameInfo",
            title: "数值名称",
            render: (text: any, record: any) => {
                return getOneLangDto(record.nameInfo).name;
            }
        },
        {
            key: "type",
            dataIndex: "type",
            title: "数值属性",
            render: (text: any) => {
                return text == 1 ? "好感度" : "自定义";
            }
        },
        {
            key: "initValue",
            dataIndex: "initValue",
            title: "初始值",
            render: (text: any) => {
                return text;
            }
        },
        {
            key: "_operation",
            dataIndex: "_operation",
            title: "操作",
            fixed: "right",
            render: (text: any, record: any) => {
                return <ButtonGroup>
                    <Button type={'link'} onClick={() => onEditClick(record)}>编辑</Button>
                    <Button type={'link'} onClick={() => {deleteById(record.id) }}>删除</Button>
                </ButtonGroup>
            }
        }
    ]


    //更改状态
    const deleteById = (id: string) => {
        confirm({
            title: '确定删除',
            onOk() {
                valueNumberService.delete({ id }).then(res => {
                    message.success("删除成功")
                    searchvalueNumber(1);
                }).catch(err => {
                    message.error(err.msg.msg)
                })
            },
            onCancel() {
                return;
            },
        });
    }

    //编辑
    const onEditClick = (record: any, type?: any) => {
        setvalueNumber(record);
        setModalMode(type ? type : 'edit');
        setVisible(true);
    }

    //添加
    const onAddClick = () => {
        setvalueNumber({
            playId
        });
        setModalMode('add');
        setVisible(true);
    }
    /**
     * 模态框关闭
     */
    const onFormClose = () => {
        setVisible(false);
        setvalueNumber(null);
    }


    return <div>
        {contextHolder}
        <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
        >
        </Form>
        <Row style={{ paddingBottom: '20px' }}>
            <Col span={24}>
                <ButtonGroup>
                    <Button type={'primary'} onClick={onAddClick}>添加数值</Button>
                </ButtonGroup>
            </Col>
        </Row>
        <Table
            columns={columns}
            dataSource={valueNumberList}
            rowKey={(record: any) => {
                return `table_row_${record.id}}`;
            }}
            pagination={{
                position: ["bottomRight"],
                pageSize: pageSize,
                current: pageNum,
                total: total,
                onChange: (page, pageSize) => {
                    setPageNum(page);
                    setPageSize(pageSize);
                    searchvalueNumber(page, pageSize);
                }
            }}
        />

        <Modal
            open={visible}
            maskClosable={false}
            destroyOnClose={true}
            closable={true}
            footer={null}
            onCancel={() => onFormClose()}
            style={{ minWidth: "800px" }}

        >
            <ValueNumberEditForm mode={modalMode}
                valueNumberEdit={valueNumber}
                onSuccess={() => {
                    msgApi.success({ content: '操作成功', duration: 2 }).then();
                    searchvalueNumber(1);
                    onFormClose();
                }}
                onFail={(res) => {
                    message.error(res.msg.msg);
                    onFormClose();
                }}
            />
        </Modal>

    </div>

}

export default ValueNumberList;