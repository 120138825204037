import { http } from "../request";

class RoleService {

    //列表
    list(param: any) {
        return http.post("/playRole/page", param);
    }

    //添加
    add(param: any) {
        return http.post("/playRole/add", param);
    }

    //更新
    update(param: any) {
        return http.post("/playRole/update", param);
    }

    /**
     * 删除
     */
    delete(param: any) {
        return http.post("/playRole/delete", param);
    }

    //心动回忆列表
    listRecall(param: any) {
        return http.post("/playRole/listRecall", param);
    }

    //批量更新添加心动回忆
    upsertRecallList(param: any) {
        return http.post("/playRole/upsertRecallList", param);
    }

    //私房写真列表
    listPhotos(param: any) {
        return http.post("/playRole/listPrivatePhoto", param);
    }

    //批量更新添加心动回忆
    upsertPhotoList(param: any) {
        return http.post("/playRole/upsertPhotoList", param);
    }

}

export const roleService: RoleService = new RoleService()