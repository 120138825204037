import { Button } from 'antd';
import React, { useState } from 'react';

interface AddMenuProps {
    visible: 0 | 1 | 2 | 3 | 4;
    position: { x: number; y: number };
    onAddNode: (type: 1 | 2 | 3 | 4) => void;
}

const addMap = [
    {
        type: 1,
        label: '添加剧情',
    },
    {
        type: 2,
        label: '设置选项',
    },
    {
        type: 3,
        label: '设置判断',
    },
    {
        type: 4,
        label: '跳转已有',
    },
]

const AddMenu: React.FC<AddMenuProps> = ({ visible, position, onAddNode }) => {
    let renderMap = addMap
    switch (visible) {
        case 2:
            renderMap = [addMap[1]]
            break;
        case 3:
            renderMap = [addMap[2]]
            break;
    }
    return (
        <div id="add" style={menuStyle(visible, position)}>
            {renderMap.map((item) => {
                const { type, label } = item;
                return <Button
                    onClick={() => onAddNode(type)}
                    key={type}
                    style={buttonStyle}
                    type="primary">
                    {label}
                </Button>
            })}
        </div>
    );
};

const menuStyle = (visible: 0 | 1 | 2 | 3 | 4, position: { x: number; y: number }): React.CSSProperties => ({
    position: 'absolute',
    display: visible ? 'flex' : 'none',
    flexDirection: 'column',
    zIndex: 1,
    left: position.x,
    top: position.y,
    transform: 'translate(20%, -48%)',
});

const buttonStyle: React.CSSProperties = {
    cursor: 'pointer',
    width: 90,
    height: 30,
    boxShadow: '0 1px 3px rgba(0,0,0,.2)',
    marginBottom: 8,
};

export default AddMenu;