import { useEffect, useRef, useState } from "react"
import { message, Modal } from "antd"
import { StoryService } from "../../../../../service/interactiveStory/inedx"
import { markDuplicateNodes } from "../../../hook/UseStoryData"
import { ExclamationCircleFilled } from '@ant-design/icons';

interface IUseChatMapData {
    playId: string
    storyId: string
    curRole: any
    isVisible: boolean
}

export const CHAT_TYPE_MAP = [
    {
        name: '对方对话',
        api_create: StoryService.createCharacterDialogue,
        api_update: StoryService.updateCharacterDialogue,
        api_delete: StoryService.deleteCharacterDialogue,
    },
    {
        name: '我方对话',
        api_create: StoryService.createCharacterDialogueSelect,
        api_update: StoryService.updateCharacterDialogueSelect,
        api_delete: StoryService.deleteCharacterDialogueSelect,
    },
    {
        name: '条件判断',
        api_create: StoryService.createCharacterCondition,
        api_update: StoryService.updateCharacterCondition,
        api_delete: StoryService.deleteCharacterCondition,
    }
]

const UseChatMapData = (props: IUseChatMapData) => {
    const { playId, storyId, curRole, isVisible } = props
    const [roles, setRoles] = useState<any>([])
    const [chatMapData, setChatMapData] = useState<any>([])
    const ChatMap = useRef<any>({})

    useEffect(() => {
        if (!storyId || !curRole) return
        // 获取选中角色对话数据
        StoryService.getCharacterDialogueTree({ roleId: curRole.id, storyId })
            .then(res => {
                if (res.data?.routers) {
                    // 有数据
                    ChatMap.current = {
                        ...res.data.storyMap,
                        ...res.data.selectMap,
                        ...res.data.conditionMap
                    }
                    const data = markDuplicateNodes({ id: 'root', children: res.data.routers });
                    const findParent = (node: any) => {
                        if (node.children) {
                            node.children.forEach((child: any) => {
                                if (child.isPlaceholder) {
                                    node.nextId = child.id;
                                }
                            });
                            node.children = node.children.filter((child: any) => !child.isPlaceholder);
                            node.children.forEach(findParent)
                        }
                    }
                    findParent(data);
                    setChatMapData(data || [])
                }else{
                    // 没数据
                    setChatMapData({ id: 'root', children: []})
                    ChatMap.current = {}
                }
            })
            .catch(err => {
                message.open({
                    type: 'error',
                    content: '获取角色对话数据失败',
                });
            })
    }, [curRole, storyId])

    useEffect(() => {
        // 角色数据，不会变
        isVisible && StoryService.getCharacterList({ playId })
            .then(res => {
                setRoles(res.data)
            }).catch(err => {
                message.open({
                    type: 'error',
                    content: '获取角色数据失败',
                });
            })
    }, [isVisible])

    // 创建节点
    const createNode = (parentNode: any, type: number, isStart: 0 | 1) => {
        const api = CHAT_TYPE_MAP[type - 1].api_create
        return api({
            storyId,
            roleId: curRole.id,
            playId,
            isStart,
            parentId: isStart ? null : parentNode.id,
            parentType: isStart ? null : parentNode.type,
        })
            .then(res => {
                const findParent = (node: any) => {
                    if (node.id === parentNode.id) {
                        node.children = node.children || [];
                        node.children.push({
                            type,
                            id: res.data
                        })
                        return;
                    }
                    if (node.children) node.children.forEach(findParent)
                }
                findParent(chatMapData);
                setChatMapData({ ...chatMapData });
                ChatMap.current[res.data] = {
                    type,
                    id: res.data
                }
            })
            .catch(err => {
                message.open({
                    type: 'error',
                    content: '创建节点失败',
                });
            })
    }

    // 更新节点
    const updateNode = (params: { value: any, nodeId: string, type: any }, cb: (err?: any) => void) => {
        const { value, nodeId, type } = params;
        CHAT_TYPE_MAP[type - 1].api_update({ ...value, id: nodeId }).then(res => {
            message.open({
                type: 'success',
                content: '更新成功',
            });
            ChatMap.current[nodeId] = { ...ChatMap.current[nodeId], ...value }
            if (value.nextDialogueId || (value.selectIds && value.selectIds.length)) {
                // 给data里id为nodeId的节点添加子集，增加字段nextId 和 isPlaceholder 
                const findNode = (node: any) => {
                    if (node.id === nodeId) {
                        node.nextId = value.nextDialogueId || value.selectIds[0];
                        return;
                    }
                    if (node.children) node.children.forEach(findNode)
                }
                findNode(chatMapData);
            } else if (value.nextDialogueId === '') {
                // 删除data里id为nodeId的节点的子集
                const findNode = (node: any) => {
                    if (node.id === nodeId) {
                        node.children = [];
                        node.nextId = null;
                        return;
                    }
                    if (node.children) node.children.forEach(findNode)
                }
                findNode(chatMapData);
            }
            setChatMapData({ ...chatMapData });
            cb && cb()
        }).catch(err => {
            cb && cb(err)
            message.open({
                type: 'error',
                content: '更新节点失败',
            });
        })
    }

    // 删除节点
    const deleteNode = (nodeId: string, type: any) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: '确定删除该节点？',
            okText: '删除',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                CHAT_TYPE_MAP[type - 1].api_delete({ ids: [nodeId] }).then(res => {
                    delete ChatMap.current[nodeId];
                    // 删除data里id为nodeId的节点及其子集
                    const findParent = (node: any) => {
                        if (node.children) {
                            node.children = node.children.filter((child: any) => child.id !== nodeId);
                            node.children.forEach(findParent)
                        }
                    }
                    findParent(chatMapData);
                    setChatMapData({ ...chatMapData });
                    message.open({
                        type: 'success',
                        content: '已删除',
                    });
                }).catch(err => {
                    message.open({
                        type: 'error',
                        content: '删除失败',
                    });
                })
            },
            onCancel() {
            },
        });
    }

    return [roles, chatMapData, ChatMap, createNode, updateNode, deleteNode]
}
export default UseChatMapData