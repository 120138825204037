import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Modal, message, Switch, Tooltip, Image } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import { useEffect, useState } from "react";
import { roleService } from "../../../service/play/role-service";
import { valueNumberService } from "../../../service/play/value-number-service";
import { getOneLangDto } from "../../../utils/lang";
import { RoleEditForm } from "./EditModal/role-edit-form";
import { Link } from 'react-router-dom';
import dayjs from "dayjs";
const { confirm } = Modal;
export interface IProps_RoleList {
    playId: string
}
const RoleList = (props: IProps_RoleList) => {
    const { playId } = props;
    const [msgApi, contextHolder] = message.useMessage();

    const [form] = Form.useForm()
    const [RoleList, setRoleList] = useState([])
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    // 模态框是否可见
    const [visible, setVisible] = useState(false);
    // 模态框模式
    const [modalMode, setModalMode] = useState<'add' | 'edit'>('add');
    // 当前选中的短剧信息,临时用 any存
    const [role, setRole] = useState<any>();
    //数值属性
    const [valueNumberMap, setValueNumberMap] = useState({})
    //域名
    const domain: string = process.env.REACT_APP_CND_URL || "";

    const onFinish = () => {
        setPageNum(1);
        searchRole(1);
    }

    const onReset = () => {
        form.resetFields();
        searchRole();
    }

    useEffect(() => {
        // getValueNumber();
        searchRole();
    }, []);

    //搜索
    const searchRole = (page?: number, size?: number) => {
        const fieldsValue = form.getFieldsValue();
        const param = {
            playId,
            pageNum: page ? (page - 1) : (pageNum - 1), // 服务端是从 0 开始
            pageSize: size ? size : pageSize,
            ...fieldsValue // 其他筛选条件，来自表单属性，属性明明和服务端一致
        }

        console.log("表单值：", param);
        valueNumberService.valueList({ playId }).then(res => {
            if (res.data) {
                let map: any = {}
                res.data.map((item: any) => {
                    map[item.id] = item["name"]
                })
                setValueNumberMap(map);
            }
            roleService.list(param)
                .then(res => {
                    console.log("Role list res", res);
                    const list = res.data.content;
                    const totalElements = res.data.totalElements;
                    setRoleList(list);
                    setTotal(totalElements);
                })
                .catch(err => {
                    console.error("Role list err", err);
                });
        })
    }

    const columns = [
        // {
        //     key: "Role",
        //     dataIndex: "Role",
        //     title: "终止版本",
        //     render: (text: any) => {
        //         return text
        //     }
        // },
        {
            key: "nameInfo",
            dataIndex: "nameInfo",
            title: "角色名称",
            render: (text: any, record: any) => {
                return getOneLangDto(record.nameInfo).name;
            }
        },
        {
            key: "avatar",
            dataIndex: "avatar",
            title: "头像",
            render: (text: any) => {
                return <Tooltip title={
                    <Image style={{ width: "210px", height: "280px" }}
                        src={domain + text}
                    />}>
                    {<Image style={{ width: "30px", height: "40px" }}
                        src={domain + text} />}
                </Tooltip>
            }
        },
        {
            key: "cover",
            dataIndex: "cover",
            title: "封面",
            render: (text: any) => {
                return <Tooltip title={
                    <Image style={{ width: "210px", height: "280px" }}
                        src={domain + text}
                    />}>
                    {<Image style={{ width: "30px", height: "40px" }}
                        src={domain + text} />}
                </Tooltip>;
            }
        },
        {
            key: "numberValueId",
            dataIndex: "numberValueId",
            title: "角色好感度",
            render: (text: any) => {
                return valueNumberMap[text];
            }
        },
        {
            key: "sort",
            dataIndex: "sort",
            title: "排序",
            render: (text: any) => {
                return text;
            }
        },
        {
            key: "_operation",
            dataIndex: "_operation",
            title: "操作",
            fixed: "right",
            render: (text: any, record: any) => {
                return <ButtonGroup>
                    <Button type={'link'} onClick={() => onEditClick(record)}>编辑</Button>
                    <Button type={'link'} onClick={() => { deleteById(record.id) }}>删除</Button>
                </ButtonGroup>
            }
        }
    ]


    //更改状态
    const deleteById = (id: string) => {
        confirm({
            title: '确定删除',
            onOk() {
                roleService.delete({ id }).then(res => {
                    message.success("删除成功")
                    searchRole(1);
                }).catch(err => {
                    message.error(err.msg.msg)
                })
            },
            onCancel() {
                return;
            },
        });
    }

    //编辑
    const onEditClick = (record: any, type?: any) => {
        setRole(record);
        setModalMode(type ? type : 'edit');
        setVisible(true);
    }

    //添加
    const onAddClick = () => {
        setRole({
            playId
        });
        setModalMode('add');
        setVisible(true);
    }
    /**
     * 模态框关闭
     */
    const onFormClose = () => {
        setVisible(false);
        setRole(null);
    }


    return <div>
        {contextHolder}
        <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
        >
        </Form>
        <Row style={{ paddingBottom: '20px' }}>
            <Col span={24}>
                <ButtonGroup>
                    <Button type={'primary'} onClick={onAddClick}>添加角色</Button>
                </ButtonGroup>
            </Col>
        </Row>
        <Table
            columns={columns}
            dataSource={RoleList}
            rowKey={(record: any) => {
                return `table_row_${record.id}}`;
            }}
            pagination={{
                position: ["bottomRight"],
                pageSize: pageSize,
                current: pageNum,
                total: total,
                onChange: (page, pageSize) => {
                    setPageNum(page);
                    setPageSize(pageSize);
                    searchRole(page, pageSize);
                }
            }}
        />

        <Modal
            open={visible}
            maskClosable={false}
            destroyOnClose={true}
            closable={true}
            footer={null}
            onCancel={() => onFormClose()}
            style={{ minWidth: "800px" }}

        >
            <RoleEditForm mode={modalMode}
                roleEdit={role}
                onSuccess={() => {
                    msgApi.success({ content: '操作成功', duration: 2 }).then();
                    searchRole(1);
                    onFormClose();
                }}
                onFail={(res) => {
                    message.error(res.msg.msg);
                    onFormClose();
                }}
            />
        </Modal>

    </div>

}

export default RoleList;