import React, { useState, useRef, useEffect } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Modal, message, Switch } from "antd";
import * as d3 from 'd3';
import { useGlobalEventListeners } from './hook/UseGlobalEventHandlers';
import AddMenu from './comps/AddMenu';
import ChapterSelection from './comps/ChapterSelection';
import NodeInfo from './comps/NodeInfo/index';
import TopOperation from './comps/TopOperation';
import UseStoryData from './hook/UseStoryData';
import ValueNumberList from './comps/ValueNumberForm';
import RoleList from './comps/RoleForm';
import { GlobalEditForm } from './comps/EditModal/global-edit-form';
import { StoryService } from '../../service/interactiveStory/inedx';
import './css/index.less';
import { Alert } from 'antd';
import { useLocation } from 'react-router';
import { UseDraw } from './hook/UseDraw';
import ChatMap, { ChatMapRef } from './comps/chatMap';
export interface NodeData {
    id: string;
    type: 1 | 2 | 3;
    conditionIds?: string[];
    selectIds?: string[];
    children?: NodeData[];
    isPlaceholder?: boolean;
    nextId?: string;
}

export const TYPE_MAP = [
    {
        module: '剧情设置',
        name: '剧情名称',
        api_create: StoryService.createChapterStory,
        api_update: StoryService.updateChapterStory,
        api_delete: StoryService.deleteChapterStory,
    },
    {
        module: '选项设置',
        name: '选项内容',
        api_create: StoryService.createChapterStorySelect,
        api_update: StoryService.updateChapterStorySelect,
        api_delete: StoryService.deleteChapterStorySelect,
    },
    {
        module: '条件设置',
        name: '条件判断',
        api_create: StoryService.createChapterStoryCondition,
        api_update: StoryService.updateChapterStoryCondition,
        api_delete: StoryService.deleteChapterStoryCondition,
    }
]

const TreeMap: React.FC = () => {
    const location = useLocation();
    const playId = location.state?.playId

    const [isShowLeftSidebar, setIsShowLeftSidebar] = useState(false);
    const [selectedNode, setSelectedNode] = useState<any>(null);
    const svgRef = useRef<SVGSVGElement>(null);

    const chatMapRef = useRef<ChatMapRef>(null);

    // 增加按钮的显示状态
    const [menuVisible, setMenuVisible] = useState<0 | 1 | 2 | 3 | 4>(0);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const addTarget = useRef<any>(null);

    const [data,
        addNode,
        updateNode,
        deleteNode,
        changeNode,
        storyMap,
        curChapterId,
        addChapter,
        updateChapter,
        list] = UseStoryData({ playId });

    const [startNode, setStartNode, transform, resetScale] = UseDraw({
        selectedNode,
        svgRef,
        data,
        updateNode,
        onClickNode: d => {
            // 拉出表单抽屉
            setSelectedNode({ ...storyMap.current[d.data?.id], type: d.data.type });
            setMenuVisible(0);
        },
        onClickAddNode: (e, d) => {
            const isType_2 = d.children && d.children.some((child: any) => child.data.type === 2);
            const isType_3 = d.children && d.children.some((child: any) => child.data.type === 3);
            if (isType_2) {
                setMenuVisible(2);
            } else if (isType_3) {
                setMenuVisible(3);
            } else {
                setMenuVisible(4);
            }
            addTarget.current = d;
            setMenuPosition({ x: e.clientX, y: e.clientY });
        },
        storyMap,
    })

    // 增加节点
    const handleAddNode = (type: 1 | 2 | 3 | 4) => {
        setMenuVisible(0);
        const _addTarget = addTarget.current;
        if (type == 4) {
            // 进入新增跳跃连线状态
            setStartNode(_addTarget);
            return
        }
        // 新增节点
        addNode(_addTarget.data, type);
    };

    //各种页面模态框
    const [valueModal, setValueModal] = useState(false);//数值管理
    const [roleModal, setRoleModal] = useState(false);//角色管理
    const [globalModal, setGlobalModal] = useState(false);//全局设置

    return (
        <div style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: '#f3f5f7',
        }}>

            {/* 提示内容 */}
            {startNode && <div style={{
                position: 'absolute',
                top: 50,
                zIndex: 1,
                width: 250,
                left: '50%',
                marginLeft: -125,
            }}>
                <Alert message="请在图中选择要链接的节点" type="info" showIcon closable afterClose={() => {
                    setStartNode(null);
                }} />
            </div>}

            {/* 添加按钮 */}
            <AddMenu
                visible={menuVisible}
                position={menuPosition}
                onAddNode={handleAddNode}
            />

            {/* 章节选择 */}
            <ChapterSelection
                onUpdate={updateChapter}
                onAdd={addChapter}
                onClick={id => {
                    if (id === curChapterId) return;
                    changeNode(id)
                }}
                currentId={curChapterId}
                list={list}
                isShow={isShowLeftSidebar} />

            <div style={{ flex: 1, position: 'relative' }}>
                {/* 顶部操作栏 */}
                <TopOperation
                    transform={transform}
                    resetScale={resetScale}
                    selectedNode={selectedNode}
                    onClick_del={() => 
                        deleteNode(selectedNode.id, selectedNode.type).then(() => {
                            setSelectedNode(null);
                        })
                    }
                    onClick_refresh={() => changeNode()}
                    onClick_chatMap={() => chatMapRef.current?.show()}
                    onClick_Chapter={() => setIsShowLeftSidebar(!isShowLeftSidebar)} />
                <Button
                    type="primary"
                    style={{
                        position: "absolute",
                        marginLeft: "175px",
                        marginTop: "10px"
                    }} onClick={() => {
                        setGlobalModal(true)
                    }}>全局设置</Button>
                <Button
                    type="primary"
                    style={{
                        position: "absolute",
                        marginLeft: "275px",
                        marginTop: "10px"
                    }} onClick={() => {
                        setRoleModal(true)
                    }}>角色管理</Button>
                <Button
                    type="primary"
                    style={{
                        position: "absolute",
                        marginLeft: "375px",
                        marginTop: "10px"
                    }} onClick={() => {
                        setValueModal(true)
                    }}>数值管理</Button>
                {/* svg */}
                <svg
                    style={{ width: '100%' }}
                    onClick={() => {
                        setMenuVisible(0);
                    }} ref={svgRef} />
            </div>

            {/* 节点信息表单 */}
            <NodeInfo
                playId={playId}
                info={selectedNode}
                close={() => { setSelectedNode(null) }}
                onSubmit={(val, type) => {
                    updateNode({ value: val, nodeId: selectedNode.id, type })
                }} />

            {/* 数值管理 */}
            <Modal
                open={valueModal}
                maskClosable={false}
                destroyOnClose={true}
                closable={true}
                footer={null}
                onCancel={() => {
                    setValueModal(false);
                }}
                style={{ minWidth: "800px" }}

            >
                <ValueNumberList playId={playId}></ValueNumberList>
            </Modal>

            {/*角色管理 */}
            <Modal
                open={roleModal}
                maskClosable={false}
                destroyOnClose={true}
                closable={true}
                footer={null}
                onCancel={() => {
                    setRoleModal(false);
                }}
                style={{ minWidth: "800px" }}
            >
                <RoleList playId={playId}></RoleList>
            </Modal>
            {/* 全局配置 */}
            <Modal
                open={globalModal}
                maskClosable={false}
                destroyOnClose={true}
                closable={true}
                footer={null}
                onCancel={() => {
                    setGlobalModal(false);
                }}
                style={{ minWidth: "1000px" }}

            >
                <GlobalEditForm playId={playId}
                    onSuccess={() => {
                        message.success({ content: '操作成功', duration: 2 }).then();
                        setGlobalModal(false);
                    }}></GlobalEditForm>
            </Modal>

            {/* 聊天弹窗 */}
            {<ChatMap
                storyMap={storyMap}
                updateNode={updateNode}
                ref={chatMapRef}
                onClosed={changeNode}
                storyNode={selectedNode}
                playId={playId}
            />}
        </div>

    );
};

export default TreeMap;