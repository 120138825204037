import { PlusOutlined, SettingOutlined, MoreOutlined, EditOutlined, DeleteOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, message, Modal, Popover, Radio, Row, Select, Space, Tabs, Upload } from 'antd';
import { memo, useEffect, useRef, useState } from 'react';
import { dealLangToPage, dealLangToService, getOneLangDto } from '../../../utils/lang';
import ChooseLang from '../../../components/common/ChooseLang';
import { getPlayInfoData } from '../../../utils/playInfoData';
import dayjs from 'dayjs';
const ChapterSelection = memo((props: {
    isShow: boolean,
    list: [],
    onAdd: () => void,
    onUpdate: (value: any) => void,
    onClick: (id: string) => void
    currentId: string
}) => {
    const { isShow, list, onAdd, onClick, currentId, onUpdate } = props
    const domain: string = process.env.REACT_APP_CND_URL || "";
    const [curId, setCurId] = useState<string>('');
    const regionMap = getPlayInfoData("regionMap");//国家/地区map
    const [imageUrl, setImageUrl] = useState<string>();
    const [loading, setLoading] = useState(false);
    const introLang = useRef<any>([]) //映射简介语言数据
    const [form] = Form.useForm();
    const [useMoney, setUseMoney] = useState(true);
    const [useVipMoney, setUseVipMoney] = useState(true);

    const _edit = (item: any) => {
        if (item.isFree || !item.money) setUseMoney(false)
        if (!item.vipMoney) setUseVipMoney(false)
        // 转换国家数据
        item.regionMoney = item?.regionMoney && Object.keys(item.regionMoney).map((key) => {
            return {
                regionId: key,
                money: item.regionMoney[key].money,
                vipMoney: item.regionMoney[key].vipMoney,
                children: item.regionMoney[key].children
            }
        })
        form.setFieldsValue({
            nameInfo: item.nameInfo && dealLangToPage(item.nameInfo),
            money: item.money,
            vipMoney: item.vipMoney,
            allPayType: item.allPayType,
            registerMoney: item.registerMoney,
            regionMoney: item.regionMoney,
            imgUrl: item.imgUrl,
        });
        setCurId(item.id)
    }

    const onFinish = (values: any) => {
        //判断国家重复
        var regionTow = false;
        var regionMap: any = {};
        values.regionMoney.forEach((el: any, index: any) => {
            var regionId = el.regionId;
            if (regionMap[regionId]) {
                regionTow = true;
            } else {
                regionMap[regionId] = el;
            }
        });
        if (regionTow) {
            message.error("国家重复")
            return;
        }
        values.regionMoney = regionMap
        if (!useMoney) {
            // 免费
            values.money = 0
            values.isFree = 1
        }
        if (!useVipMoney) {
            values.vipMoney = 0
        }
        values.nameInfo = values.nameInfo && dealLangToService(values.nameInfo)
        onUpdate({ ...values, id: curId })
    };

    if (!list || list.length == 0) return

    return (
        <div className="leftSidebar"
            style={{
                height: '100%',
                backgroundColor: 'white',
                transition: 'all .3s',
                borderRight: '1px solid #ddd',
                width: isShow ? 250 : 0,
                overflow: 'hidden',
            }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0 15px',
                width: 220,
            }}>
                <h3 style={{ flex: 1 }}>章节列表</h3>
                {/* 设置 */}
                <Button
                    style={{ marginRight: 10 }}
                    size='small'
                    shape="circle"
                    icon={<SettingOutlined />} />
                {/* 添加 */}
                <Button
                    onClick={onAdd}
                    size='small' shape="circle" icon={<PlusOutlined />} />
            </div>
            <div style={{
                // 滚动
                overflow: 'auto',
                maxHeight: 'calc(100% - 50px)',
                width: 250,
            }}>
                {
                    list.map((item, index) => {
                        const { imgUrl, id, nameInfo } = item;
                        const isActive = id === currentId;
                        return <div
                            onClick={() => onClick(id)}
                            key={index} style={{
                                height: 150,
                                transition: 'all .3s',
                                borderRadius: 10,
                                border: isActive ? '1px solid #007BFF' : '1px solid #ddd',
                                margin: '0 15px 15px',
                                position: 'relative',
                                overflow: 'hidden',
                                cursor: 'pointer',
                            }}>
                            <Popover placement="bottom" content={<div style={{ width: 80 }}>
                                <Button
                                    onClick={() => {
                                        _edit(item)
                                    }}
                                    type="text"
                                    icon={<EditOutlined />}
                                    block>
                                    编辑
                                </Button>
                                <Button
                                    type="text"
                                    icon={<DeleteOutlined />}
                                    block>
                                    删除
                                </Button>
                            </div>}>
                                <MoreOutlined style={{ position: 'absolute', top: 10, right: 10 }} />
                            </Popover>
                            {imgUrl && <div>
                                <img src={domain + imgUrl} alt="" style={{ width: '100%', height: '100%' }} />
                            </div>}
                            <div style={{
                                position: 'absolute',
                                top: 10,
                                left: 10,
                            }}>{index + 1}</div>
                            <div style={{
                                position: 'absolute',
                                bottom: 0,
                                height: 30,
                                backgroundColor: isActive ? 'rgba(28,73,129,.5)' : 'rgba(0,0,0,.5)',
                                transition: 'all .3s',
                                width: '100%',
                                textAlign: 'center',
                                lineHeight: '30px',
                                color: 'white',
                            }}>{getOneLangDto(nameInfo).name}</div>

                        </div>
                    })
                }
            </div>

            <Modal
                title="章节修改"
                width={700}
                open={!!curId}
                maskClosable={false}
                onOk={() => {
                    // 提交表单
                    form.submit()
                }}
                onCancel={() => {
                    setCurId('')
                }}
            >
                <Form
                    form={form}
                    style={{
                        width: '100%',
                        padding: 12,
                        // 滚动
                        overflow: 'auto',
                        height: 'calc(100% - 100px)',
                    }}
                    name="form_item_path"
                    onFinish={onFinish}>
                    <ChooseLang
                        language={introLang.current}
                        renderTab={(index, language) => {
                            return <>
                                <Form.Item name={["nameInfo", index, language.key]} label="章节名称" rules={[{ required: true }]}>
                                    <Input placeholder='请输入章节名称' />
                                </Form.Item>
                            </>
                        }} />

                    <Form.Item name="imgUrl" label="章节封面">
                        <Upload
                            name="imgUrl"
                            listType="picture-card"
                            showUploadList={false}
                            action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                            beforeUpload={() => {

                            }}
                            onChange={() => {

                            }}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : <button style={{ border: 0, background: 'none' }} type="button">
                                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                                <div style={{ marginTop: 8 }}>Upload</div>
                            </button>}
                        </Upload>
                    </Form.Item>

                    <Tabs defaultActiveKey="1" items={[
                        {
                            key: '1',
                            label: '选项付费',
                            children: <>
                                <Row gutter={16}>
                                    <Col span={11}>
                                        <Form.Item
                                            name="money"
                                            label={
                                                <Checkbox
                                                    checked={useMoney}
                                                    onChange={(e) => setUseMoney(e.target.checked)}
                                                >付费金额</Checkbox>
                                            }
                                        >
                                            <Input
                                                disabled={!useMoney}
                                                placeholder='请输入付费金额' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} />
                                    <Col span={11}>
                                        <Form.Item
                                            name="vipMoney"
                                            label={
                                                <Checkbox
                                                    checked={useVipMoney}
                                                    onChange={(e) => setUseVipMoney(e.target.checked)}
                                                >会员优惠价</Checkbox>
                                            }
                                        >
                                            <Input
                                                disabled={!useVipMoney}
                                                placeholder='请输入会员优惠价' />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={1} />
                                    <Col span={13}>
                                        <Form.Item
                                            name={'allPayType'}
                                            label="付费模式">
                                            <Radio.Group>
                                                <Radio value={2}> 仅Points </Radio>
                                                <Radio value={1}> Points+Bonus </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.List name="registerMoney">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                    <Form.Item
                                                        {...restField}
                                                        label="注册时间"
                                                        name={[name, 'startTime']}
                                                        // @ts-expect-error
                                                        getValueProps={(date) => {
                                                            return dayjs(date) 
                                                        }}
                                                        getValueFromEvent={(date) => date ? date.valueOf() : null}
                                                    >
                                                        <DatePicker placeholder="开始时间" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'endTime']}
                                                        // @ts-expect-error
                                                        // getValueProps={(date) => date ? moment(date) : null}
                                                        // getValueFromEvent={(date) => date ? moment(date).valueOf() : null}
                                                    >
                                                        <DatePicker placeholder="结束时间" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'registerPrice']}
                                                    >
                                                        <Input style={{ width: 90 }} placeholder="付费金额" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'vipRegisterPrice']}
                                                    >
                                                        <Input style={{ width: 90 }} placeholder="优惠价" />
                                                    </Form.Item>
                                                    <CloseOutlined onClick={() => remove(name)} />
                                                </Space>
                                            ))}
                                            <Form.Item>
                                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                    添加注册时间
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </>,
                        },
                        {
                            key: '2',
                            label: '特殊定价',
                            children: <Form.List name="regionMoney">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Card
                                                style={{ marginBottom: 16 }}
                                                size="small"
                                                title={`条件 ${name + 1}`}
                                                key={key}
                                                extra={
                                                    <CloseOutlined
                                                        onClick={() => remove(name)}
                                                    />
                                                }
                                            >
                                                <Form.Item name={[name, "regionId"]} label="国家/地区" rules={[{ required: true }]}>
                                                    <Select allowClear showSearch
                                                        filterOption={(input, option: any) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {Object.keys(regionMap).map((key) => {
                                                            return <Select.Option key={key} value={key}>{regionMap[key]}</Select.Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                                <Row>
                                                    <Col>
                                                        <Form.Item
                                                            name={[name, "money"]}
                                                            label='付费金额'
                                                        >
                                                            <Input
                                                                placeholder='请输入定价' />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}></Col>
                                                    <Col>
                                                        <Form.Item
                                                            {...restField}
                                                            label='优惠价'
                                                            name={[name, 'vipMoney']}
                                                        >
                                                            <Input placeholder="优惠价" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Form.List name={[name, 'children']}>
                                                    {(subFields, subOpt) => (
                                                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                                            {subFields.map((subField) => (
                                                                <Space key={subField.key} align="baseline">
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label="注册时间"
                                                                        name={[name, 'startTime']}
                                                                        // @ts-expect-error
                                                                        // getValueProps={(date) => date ? moment(date) : null}
                                                                        // getValueFromEvent={(date) => date ? moment(date).valueOf() : null}
                                                                    >
                                                                        <DatePicker placeholder="开始时间" />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[name, 'endTime']}
                                                                        // @ts-expect-error
                                                                        // getValueProps={(date) => date ? moment(date) : null}
                                                                        // getValueFromEvent={(date) => date ? moment(date).valueOf() : null}
                                                                    >
                                                                        <DatePicker placeholder="结束时间" />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[subField.name, 'money']}
                                                                        getValueFromEvent={(e) => {
                                                                            const value = e.target.value;
                                                                            return value ? parseFloat(value) : null;
                                                                        }}
                                                                    >
                                                                        <Input placeholder="请输入付费金额" />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        {...restField}
                                                                        name={[subField.name, 'vipMoney']}
                                                                        getValueFromEvent={(e) => {
                                                                            const value = e.target.value;
                                                                            return value ? parseFloat(value) : null;
                                                                        }}
                                                                    >
                                                                        <Input placeholder="请输入优惠价" />
                                                                    </Form.Item>
                                                                    <CloseOutlined
                                                                        onClick={() => subOpt.remove(subField.name)}
                                                                    />
                                                                </Space>
                                                            ))}
                                                            <Button
                                                                icon={<PlusOutlined />}
                                                                type="dashed"
                                                                style={{ fontSize: 13 }}
                                                                onClick={() => subOpt.add({ valueType: 1, opType: 1, optionId: 1 })}
                                                                block
                                                            >
                                                                添加注册时间
                                                            </Button>
                                                        </div>
                                                    )}
                                                </Form.List>
                                            </Card>

                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                                添加地区定价
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>,
                        }
                    ]} onChange={() => {

                    }} />
                </Form>
            </Modal>
        </div>
    );
})

export default ChapterSelection;