import { homeRoute } from "./homeRoute";
import { playRoute } from './playRoute';
import { orderRoute } from "./orderRoute";
import { userRoute } from "./userRoute";
import { pushTaskRoute } from "./pushTask";
import { channelRoute } from "./channelRoute";
import { versionRoute } from "./versionRoute";
import { activeRoute } from "./activeRouter";

export const routeCfg = [
    ...activeRoute,
    ...homeRoute,
    ...playRoute,
    ...orderRoute,
    ...userRoute,
    ...pushTaskRoute,
    ...channelRoute,
    ...versionRoute
]
