import { Button, Layout, Popover } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from "react";
import { getOneLangDto } from "../../../../../utils/lang";

const { Sider } = Layout;

interface IChatMap_Sider {
    roles: any[]
    curRole: any
    onClickItem: (role: any) => void
    selectedNode: any
}

const ChatMap_Sider = (props: IChatMap_Sider) => {
    const { roles, curRole, onClickItem, selectedNode } = props;
    const [curRoleList, setCurRoleList] = useState<any[]>(selectedNode && selectedNode.dialogueIdMap && roles.filter(el=>Object.keys(selectedNode.dialogueIdMap).includes(el.id)) || [])

    useEffect(() => {
        setCurRoleList(selectedNode && selectedNode.dialogueIdMap && roles.filter(el=>Object.keys(selectedNode.dialogueIdMap).includes(el.id)) || [])
    },[selectedNode, roles])

    const canChooseRoles = useMemo(() => {
        return roles.filter((el: any) => {
            return !curRoleList.find((item: any) => item.id === el.id)
        })
    }, [roles, curRoleList])

    const RoleListComp = () => {
        return canChooseRoles.map((el: any) => {
            if (curRoleList.find((item: any) => item.id === el.id)) return null;
            return <Button
                key={el.id}
                style={{ width: '100%' }}
                onClick={() => setCurRoleList([...curRoleList, el])}
                type="text" block>
                {getOneLangDto(el.nameInfo).name}
            </Button>
        })
    }

    return <Sider width={200} style={siderStyle}>
        <div style={{
            height: 50,
            justifyContent: 'center',
            alignContent: 'center',
        }}>
            {/* 已添加的角色列表 */}
            {curRoleList.map(el => {
                return <Button
                    key={el.id}
                    style={{
                        width: '85%', marginTop: 10,
                        backgroundColor: el.id === curRole?.id ? '#2458d9' : '',
                        color: el.id === curRole?.id ? '#fff' : ''
                    }}
                    onClick={() => onClickItem(el)}
                    type="text" block>
                    {getOneLangDto(el.nameInfo).name}
                </Button>
            })}

            {/* 添加角色按钮 */}
            {canChooseRoles && canChooseRoles.length > 0 && <Popover
                placement="topLeft"
                style={{ padding: 0 }}
                content={RoleListComp}
                title="请选择角色">
                <Button
                    icon={<PlusOutlined />}
                    type="dashed"
                    style={{ fontSize: 13, width: '85%', marginTop: 10 }}
                    onClick={() => { }}
                    block
                >
                    添加角色
                </Button>
            </Popover>}
        </div>
    </Sider>
}

const siderStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    backgroundColor: 'white',
    borderRight: '1px solid #ddd',
};


export default ChatMap_Sider