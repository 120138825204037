import { Button, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Checkbox, Radio, AutoComplete, message, Tag, Tabs, Col, Modal } from "antd";
import { useEffect, useState, useRef } from "react";
import { roleService } from "../../../../service/play/role-service";
import ChooseLang from "../../../../components/common/ChooseLang";
import ImgUpload from "../../../../components/common/Upload";
import { RoleFeelItem } from "../../../../components/play-info/role-feel-item";
import { StorySelect } from "../../../../components/play-info/story-select";
import { dealLangToService, dealLangToPage, getOneLangDto, verifyImg, dealLangImgToService, delDataInArray, getAppLangs2 } from "../../../../utils/lang";
export interface IProps_RoleEditForm {
    roleEdit?: {
        id: string;
        playId: string;
        nameInfo: any;
        chatStatus: number
        avatar: string;
        cover: string;
        sort: number;
        numberValueId: number;
        hideStatus: number;
        storyId: string;
        aiChatStatus: number;
        aiChatStoryId: string;
        aiChatInfo: any;
    };
    mode: 'add' | 'edit' | 'read';
    onSuccess: () => void;
    onFail: (res: any) => void;
}

export const RoleEditForm = (props: IProps_RoleEditForm) => {
    const { roleEdit, onSuccess, onFail, mode } = props;
    const [form] = Form.useForm();//角色form
    const [recallForm] = Form.useForm();//回忆form
    const [photoForm] = Form.useForm(); //写真form
    const [formShow, setFormShow] = useState<any>();//这是为了一次性渲染
    const [btnLoading, setBtnLoading] = useState(false);
    const contentLang = useRef<any>([]) //映射的语言数据
    const domain: string = process.env.REACT_APP_CND_URL || "";
    const [recalls, setRecalls] = useState([]); //心动回忆
    const [photos, setPhotos] = useState([]); //私房写真
    //角色信息
    const roleInfo = () => {
        return <>
            {formShow ? <Form
                form={form}
                onFinish={onFinish}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                style={{ marginTop: 30 }}
            >
                <Form.Item
                    label="仅为聊天角色"
                    name={"chatStatus"}
                >
                    <Checkbox defaultChecked={form.getFieldValue("chatStatus") == 1 ? true : false}
                        onChange={(e) => {
                            form.setFieldValue("chatStatus", e.target.checked ? 1 : 0)
                        }}></Checkbox>
                </Form.Item>
                <Form.Item
                    label="角色头像"
                    name={"avatar"}
                    required={true}
                >
                    <ImgUpload
                        type={5}
                        img={form.getFieldValue("avatar") ? [{ url: form.getFieldValue("avatar").indexOf("http") > -1 ? form.getFieldValue("avatar") : domain + form.getFieldValue("avatar") }] : []}
                        del={() => {
                            form.setFieldValue("avatar", "")
                        }}
                        success={(res: any) => {
                            form.setFieldValue("avatar", res);
                        }} />
                </Form.Item>
                <Form.Item
                    label="角色封面"
                    name={"cover"}
                    required={true}
                >
                    <ImgUpload
                        type={5}
                        img={form.getFieldValue("cover") ? [{ url: form.getFieldValue("cover").indexOf("http") > -1 ? form.getFieldValue("cover") : domain + form.getFieldValue("cover") }] : []}
                        del={() => {
                            form.setFieldValue("cover", "")
                        }}
                        success={(res: any) => {
                            form.setFieldValue("cover", res);
                        }} />
                </Form.Item>
                <Form.Item
                    label="角色名称"
                >
                    <ChooseLang
                        language={contentLang.current}
                        renderTab={(index, language) => {
                            return <>
                                <Form.Item name={["name", index, language.key]} rules={[{ required: true }]}>
                                    <Input placeholder="请输入数值名称" />
                                </Form.Item>
                            </>
                        }} />
                </Form.Item>
                <RoleFeelItem form={form} playId={roleEdit?.playId} propName="numberValueId" ></RoleFeelItem>
                <Form.Item
                    label="排序"
                    name={"sort"}
                    required={true}
                >
                    <InputNumber min={1}></InputNumber>
                </Form.Item>
                <Form.Item
                    label="剧情未出现角色隐藏"
                    name={"hideStatus"}
                >
                    <Checkbox defaultChecked={form.getFieldValue("hideStatus") == 1 ? true : false} onChange={(e) => {
                        form.setFieldValue("hideStatus", e.target.checked ? 1 : 0)
                    }}></Checkbox>
                </Form.Item>
                {form.getFieldValue("hideStatus") == 1 ? <Form.Item
                    label=""
                    name={"storyId"}
                    required={true}
                >
                    <Input></Input>
                </Form.Item> : <></>}
                {mode == "read" ? <></> :
                    <Row justify={"end"}>
                        <Space size={'large'}>
                            <Popconfirm style={{

                            }} title={"确认提交？"} okText={"确认"} cancelText={"取消"}
                                onConfirm={() => onFinish(form.getFieldsValue())}>
                                <Button loading={btnLoading} type={'primary'} >提交</Button>
                            </Popconfirm>
                        </Space>
                    </Row>
                }
            </Form> : <></>}
        </>
    }

    //添加回忆
    const addRecall = () => {
        var data: any = recalls[recalls.length - 1];
        var index = 0;
        if (data) {
            index = data.key + 1;
        }
        recalls.push({ key: index, languageInfo: ["en"] })
        setRecalls([...recalls]);
    }

    //删除回忆
    const delRecall = (index: number) => {
        recalls.splice(recalls.findIndex((el: any) => el.key === index), 1);
        setRecalls([...recalls])
    }

    //心动回忆
    const recallInfo = () => {
        return <>
            {formShow ? <Form
                form={recallForm}
                onFinish={onFinish}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                style={{ marginTop: 30 }}
            >
                {recalls?.map((recall: any, inedx: number) => {
                    return <div key={inedx}>
                        {/* <Form.Item
                            label="删除回忆"
                        >
                        </Form.Item> */}
                        <Form.Item
                            label="封面"
                            name={"imgUrl_" + recall.key}
                            required={true}
                        >
                            <ImgUpload
                                type={5}
                                img={recall["imgUrl"] ? [{ url: recall["imgUrl"].indexOf("http") > -1 ? recall["imgUrl"] : domain + recall["imgUrl"] }] : []}
                                del={() => {
                                    recall["imgUrl"] = "";
                                }}
                                success={(res: any) => {
                                    recall["imgUrl"] = res;
                                }} />
                        </Form.Item>
                        <Form.Item
                            label="会议标题"
                        >
                            <ChooseLang
                                TagClick={(val, key) => {//添加
                                    recall.languageInfo.push(key);
                                }}
                                onEdit={(key) => {//删除
                                    const index = recall.languageInfo.findIndex((el: any) => el == key)
                                    index !== -1 && recall.languageInfo.splice(index, 1)
                                }}
                                language={recall.languageInfo}
                                renderTab={(index, language) => {
                                    return <>
                                        <Form.Item name={"name_" + language.key + "_" + recall.key} rules={[{ required: true }]}>
                                            <Input placeholder="请输入回忆标题" />
                                        </Form.Item>
                                    </>
                                }} />
                        </Form.Item>
                        <Form.Item
                            label="排序"
                            name={"sort_" + recall.key}
                            required={true}
                        >
                            <InputNumber min={1} onBlur={(e) => {
                                recall.sort = e.target.value;
                            }} defaultValue={recall.sort}></InputNumber>
                        </Form.Item>
                        <Form.Item
                            label="解锁方式"
                            name={"unlockType_" + recall.key}
                            required={true}
                        >
                            <Select defaultValue={1} allowClear onChange={(e) => {

                            }}>
                                <Select.Option value={1}>免费</Select.Option>
                                {/* <Select.Option value={2}>心动碎片</Select.Option> */}
                                <Select.Option value={3}>付费</Select.Option>
                                <Select.Option value={4}>剧情解锁</Select.Option>
                                {/* <Select.Option value={5}>好感度解锁</Select.Option> */}
                            </Select>
                        </Form.Item>
                        {recallForm.getFieldValue("unlockType_" + recall.key) == 3 ? <>
                            <Form.Item
                                label="Points/Bonus"
                                name={"money_" + recall.key}
                                required={true}
                            >
                                <InputNumber min={1} defaultValue={recall.money}></InputNumber>
                            </Form.Item>
                            <Form.Item
                                label="付费模式"
                                name={"payType_" + recall.key}
                                required={true}
                            >
                                <Radio.Group value={recall.payType}>
                                    <Radio key={2} value={2}>仅Points</Radio>
                                    <Radio key={1} value={1}>Points+Bonus</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </> : <></>}
                        {recallForm.getFieldValue("unlockType_" + recall.key) == 4 ? <StorySelect form={recallForm} playId={roleEdit?.playId} storyId={recall.storyId} propName={"storyId_" + recall.key}></StorySelect> : <></>}
                        <Form.Item
                            label="视频资源"
                            name={"videoUrl_" + recall.key}
                            required={true}
                        >
                            <ImgUpload
                                type={5}
                                img={recall["videoUrl"] ? [{ url: recall["videoUrl"].indexOf("http") > -1 ? recall["videoUrl"] : domain + recall["videoUrl"] }] : []}
                                del={() => {
                                    recall["videoUrl"] = "";
                                }}
                                success={(res: any) => {
                                    recall["videoUrl"] = res;
                                }} />
                        </Form.Item>
                        <Form.Item
                            label="隐藏该回忆"
                            name={"hideStatus_" + recall.key}
                        >
                            <Checkbox defaultChecked={recallForm.getFieldValue("hideStatus_" + recall.key) == 1 ? true : false}
                                onChange={(e) => {
                                    recallForm.setFieldValue("hideStatus_" + recall.key, e.target.checked ? 1 : 0)
                                }}></Checkbox>
                        </Form.Item>
                        {recallForm.getFieldValue("hideStatus_" + recall.key) == 1 ? <StorySelect form={recallForm} playId={roleEdit?.playId} storyId={recall.hideStoryId} propName={"hideStoryId_" + recall.key}></StorySelect> : <></>}
                        <Form.Item
                            label="分割线"
                        >
                            <label>--------------------------------------------------------------------------------------------------</label>
                        </Form.Item>
                    </div>
                })}
                <Button onClick={() => addRecall()}>添加回忆</Button>
                <Row justify={"end"}>
                    <Space size={'large'}>
                        <Popconfirm style={{

                        }} title={"确认提交？"} okText={"保存"} cancelText={"取消"}
                            onConfirm={() => { recallOnFinish(recallForm.getFieldsValue()) }}>
                            <Button loading={btnLoading} type={'primary'} >保存</Button>
                        </Popconfirm>
                    </Space>
                </Row>
            </Form> : <></>}
        </>
    }

    //添加写真
    const addPhoto = () => {
        var data: any = photos[photos.length - 1];
        var index = 0;
        if (data) {
            index = data.key + 1;
        }
        photos.push({ key: index, languageInfo: ["en"] })
        setRecalls([...photos]);
    }

    //删除写真
    const delPhoto = (index: number) => {
        photos.splice(photos.findIndex((el: any) => el.key === index), 1);
        setRecalls([...photos])
    }

    //私房写真
    const photoInfo = () => {
        return <>
            {formShow ? <Form
                form={photoForm}
                onFinish={onFinish}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                style={{ marginTop: 30 }}
            >
                {photos?.map((photo: any, inedx: number) => {
                    return <div key={inedx}>
                        {/* <Form.Item
                        label="删除回忆"
                    >
                    </Form.Item> */}
                        <Form.Item
                            label="封面"
                            name={"imgUrl_" + photo.key}
                            required={true}
                        >
                            <ImgUpload
                                type={5}
                                img={photo["imgUrl"] ? [{ url: photo["imgUrl"].indexOf("http") > -1 ? photo["imgUrl"] : domain + photo["imgUrl"] }] : []}
                                del={() => {
                                    photo["imgUrl"] = "";
                                }}
                                success={(res: any) => {
                                    photo["imgUrl"] = res;
                                }} />
                        </Form.Item>
                        <Form.Item
                            label="照片标题"
                        >
                            <ChooseLang
                                TagClick={(val, key) => {//添加
                                    photo.languageInfo.push(key);
                                }}
                                onEdit={(key) => {//删除
                                    const index = photo.languageInfo.findIndex((el: any) => el == key)
                                    index !== -1 && photo.languageInfo.splice(index, 1)
                                }}
                                language={photo.languageInfo}
                                renderTab={(index, language) => {
                                    return <>
                                        <Form.Item name={"name_" + language.key + "_" + photo.key} rules={[{ required: true }]}>
                                            <Input placeholder="请输入回忆标题" />
                                        </Form.Item>
                                    </>
                                }} />
                        </Form.Item>
                        <Form.Item
                            label="照片排序"
                            name={"sort_" + photo.key}
                            required={true}
                        >
                            <InputNumber min={1} onBlur={(e) => {
                                photo.sort = e.target.value;
                            }} defaultValue={photo.sort}></InputNumber>
                        </Form.Item>
                        <Form.Item
                            label="解锁方式"
                            name={"unlockType_" + photo.key}
                            required={true}
                        >
                            <Select defaultValue={1} allowClear onChange={(e) => {

                            }}>
                                <Select.Option value={1}>免费</Select.Option>
                                {/* <Select.Option value={2}>心动碎片</Select.Option> */}
                                <Select.Option value={3}>付费</Select.Option>
                                <Select.Option value={4}>剧情解锁</Select.Option>
                                {/* <Select.Option value={5}>好感度解锁</Select.Option> */}
                            </Select>
                        </Form.Item>
                        {photoForm.getFieldValue("unlockType_" + photo.key) == 3 ? <>
                            <Form.Item
                                label="Points/Bonus"
                                name={"money_" + photo.key}
                                required={true}
                            >
                                <InputNumber min={1} defaultValue={photo.money}></InputNumber>
                            </Form.Item>
                            <Form.Item
                                label="付费模式"
                                name={"payType_" + photo.key}
                                required={true}
                            >
                                <Radio.Group value={photo.payType}>
                                    <Radio key={2} value={2}>仅Points</Radio>
                                    <Radio key={1} value={1}>Points+Bonus</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </> : <></>}
                        {photoForm.getFieldValue("unlockType_" + photo.key) == 4 ? <StorySelect form={photoForm} playId={roleEdit?.playId} storyId={photo.storyId} propName={"storyId_" + photo.key}></StorySelect> : <></>}
                        <Form.Item
                            label="分割线"
                        >
                            <label>--------------------------------------------------------------------------------------------------</label>
                        </Form.Item>
                    </div>
                })}
                <Button onClick={() => addPhoto()}>添加回忆</Button>
                <Row justify={"end"}>
                    <Space size={'large'}>
                        <Popconfirm style={{

                        }} title={"确认提交？"} okText={"保存"} cancelText={"取消"}
                            onConfirm={() => { photoOnFinish(photoForm.getFieldsValue()) }}>
                            <Button loading={btnLoading} type={'primary'} >保存</Button>
                        </Popconfirm>
                    </Space>
                </Row>
            </Form> : <></>}
        </>
    }

    const initialItems = [
        { label: '角色信息', children: roleInfo(), key: '1', closable: false },
        { label: '心动回忆', children: recallInfo(), key: '2', closable: false, disabled: mode == "add" ? true : false },
        { label: '私房写真', children: photoInfo(), key: '3', closable: false, disabled: mode == "add" ? true : false },
    ];

    const [activeKey, setActiveKey] = useState(initialItems[0].key);

    useEffect(() => {
        fillForm(roleEdit);
    }, []);

    //查询私房写真
    const findPhotos = () => {
        roleService.listPhotos({ "roleId": roleEdit?.id }).then((res) => {
            console.log(res);
            if (res.data) {
                var dataList: any = []
                var values: any = {};
                res.data.map((item: any, index: number) => {
                    item.key = index;
                    if (item["nameInfo"]) {
                        item.languageInfo = [];
                        item["nameInfo"].map((ll: any) => {
                            var _name = "name_" + ll.language + "_" + index;
                            values[_name] = ll.name;
                            item.languageInfo.push(ll.language)
                        })
                    } else {
                        item.languageInfo = ["en"];
                    }
                    dataList.push(item)
                    var _sort = "sort_" + index;
                    values[_sort] = item.sort;

                    var _unlockType = "unlockType_" + index;
                    values[_unlockType] = item.unlockType;

                    var _payType = "payType_" + index;
                    values[_payType] = item.payType;

                    var _money = "money_" + index;
                    values[_money] = item.money;

                    var _hideStatus = "hideStatus_" + index;
                    values[_hideStatus] = item.hideStatus;

                    var _stroyId = "storyId_" + index;
                    values[_stroyId] = item.storyId;

                    var _hideStoryId = "hideStoryId_" + index;
                    values[_hideStoryId] = item.hideStoryId;

                })
                setPhotos(dataList);
                //处理photoForm
                photoForm.setFieldsValue(values);
            }
        })
    }

    //私房写真保存
    const photoOnFinish = (values: any) => {
        console.log("values", values)
        // console.log("recalls", recalls)
        if (photos) {
            photos.map((item: any) => {
                var nameInfos: any = [];
                //处理标题名称
                item["languageInfo"].map((el: any) => {
                    //name_en_0
                    var nameStr = "name_" + el + "_" + item.key;
                    var nameInfo = {
                        "language": el,
                        "name": values[nameStr]
                    }
                    nameInfos.push(nameInfo);
                })
                item["playId"] = roleEdit?.playId
                item["roleId"] = roleEdit?.id
                item["nameInfo"] = nameInfos;
                item["unlockType"] = values["unlockType_" + item.key];
                item["storyId"] = values["storyId_" + item.key];
                item["money"] = values["money_" + item.key];
                item["payType"] = values["payType_" + item.key];
                item["hideStatus"] = values["hideStatus_" + item.key];
                item["hideStoryId"] = values["hideStoryId_" + item.key];
            })
        }
        console.log("photos", photos)
        //构建回忆的数据
        photoForm.validateFields().then(() => {
            setBtnLoading(true);
            roleService.upsertPhotoList(photos).then(() => {
                onSuccess && onSuccess();
                setBtnLoading(false);
            }).catch((res) => {
                message.error(res.msg.msg);
                setBtnLoading(false);
            })
        })
    }

    //查询心动回忆
    const findRecalls = () => {
        roleService.listRecall({ "roleId": roleEdit?.id }).then((res) => {
            console.log(res);
            if (res.data) {
                var dataList: any = []
                var values: any = {};
                res.data.map((item: any, index: number) => {
                    item.key = index;
                    if (item["nameInfo"]) {
                        item.languageInfo = [];
                        item["nameInfo"].map((ll: any) => {
                            var _name = "name_" + ll.language + "_" + index;
                            values[_name] = ll.name;
                            item.languageInfo.push(ll.language)
                        })
                    } else {
                        item.languageInfo = ["en"];
                    }
                    dataList.push(item)
                    var _sort = "sort_" + index;
                    values[_sort] = item.sort;

                    var _unlockType = "unlockType_" + index;
                    values[_unlockType] = item.unlockType;

                    var _payType = "payType_" + index;
                    values[_payType] = item.payType;

                    var _money = "money_" + index;
                    values[_money] = item.money;

                    var _hideStatus = "hideStatus_" + index;
                    values[_hideStatus] = item.hideStatus;

                    var _stroyId = "storyId_" + index;
                    values[_stroyId] = item.storyId;

                    var _hideStoryId = "hideStoryId_" + index;
                    values[_hideStoryId] = item.hideStoryId;

                })
                setRecalls(dataList);
                //处理recallForm
                recallForm.setFieldsValue(values);
            }
        })
    }

    //心动回忆保存
    const recallOnFinish = (values: any) => {
        // console.log("values", values)
        // console.log("recalls", recalls)
        if (recalls) {
            recalls.map((item: any) => {
                var nameInfos: any = [];
                //处理标题名称
                item["languageInfo"].map((el: any) => {
                    //name_en_0
                    var nameStr = "name_" + el + "_" + item.key;
                    var nameInfo = {
                        "language": el,
                        "name": values[nameStr]
                    }
                    nameInfos.push(nameInfo);
                })
                item["playId"] = roleEdit?.playId
                item["roleId"] = roleEdit?.id
                item["nameInfo"] = nameInfos;
                item["unlockType"] = values["unlockType_" + item.key];
                item["storyId"] = values["storyId_" + item.key];
                item["money"] = values["money_" + item.key];
                item["payType"] = values["payType_" + item.key];
                item["hideStatus"] = values["hideStatus_" + item.key];
                item["hideStoryId"] = values["hideStoryId_" + item.key];
            })
        }
        // console.log("recalls", recalls)
        //构建回忆的数据
        recallForm.validateFields().then(() => {
            setBtnLoading(true);
            roleService.upsertRecallList(recalls).then(() => {
                onSuccess && onSuccess();
                setBtnLoading(false);
            }).catch((res) => {
                message.error(res.msg.msg);
                setBtnLoading(false);
            })
        })
    }

    //角色信息保存
    const onFinish = (values: any) => {
        // console.log(roleEdit)
        values.playId = roleEdit?.playId;
        if (mode == "edit") {
            values.id = roleEdit?.id;
        }

        //处理短剧多语言名称
        values.nameInfo = dealLangToService(values.name);
        // console.log(values);

        //处理多语言结构
        form.validateFields().then(() => {
            setBtnLoading(true);
            if (mode == "edit") {
                roleService.update(values)
                    .then(() => {
                        onSuccess && onSuccess();
                        setBtnLoading(false);
                    })
                    .catch((res) => {
                        message.error(res.msg.msg);
                        setBtnLoading(false);
                    })
            } else {
                roleService.add(values)
                    .then(() => {
                        onSuccess && onSuccess();
                        setBtnLoading(false);
                    })
                    .catch((res) => {
                        message.error(res.msg.msg);
                        setBtnLoading(false);
                    })
            }
        })
    }

    const fillForm = (roleEdit: any) => {
        // console.log(roleEdit)
        //判断名称多语言
        roleEdit.nameInfo && roleEdit.nameInfo.forEach((el: any) => {
            contentLang.current.push(el.language)
        })
        if (roleEdit["nameInfo"]) {
            roleEdit["name"] = dealLangToPage(roleEdit["nameInfo"]);
        }
        if (!roleEdit["hideStatus"]) {
            roleEdit["hideStatus"] = 0;
        }
        if (!roleEdit["chatStatus"]) {
            roleEdit["chatStatus"] = 0
        }
        form.setFieldsValue(roleEdit);
        setFormShow({ 1: 1 })
    }

    /**
     * 表单重置
     */
    const onReset = () => {
        form.resetFields();
    }

    return <div>
        <Tabs
            type="card"
            onChange={(e) => {
                setActiveKey(e);
                if (e == "2") {
                    findRecalls();
                } else if (e == "3") {
                    findPhotos();
                }
            }}
            activeKey={activeKey}
            // onEdit={null}
            items={initialItems}
        />

    </div>
}