import { Button } from "antd";
import { OrderedListOutlined, DeleteOutlined, ReloadOutlined, LeftOutlined, MessageOutlined } from '@ant-design/icons';
import * as d3 from 'd3';
import { useNavigate } from "react-router";

const TopOperation = (props: { 
    onClick_Chapter: () => void, selectedNode: any, 
    onClick_del: () => void, 
    onClick_refresh: () => void, 
    onClick_chatMap: () => void, 
    transform: any, 
    resetScale: any }) => {
    const { onClick_chatMap, onClick_Chapter, onClick_del, onClick_refresh, selectedNode, transform, resetScale } = props
    const navigate = useNavigate();

    return <div style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        padding: 10,
    }}>
        {/* 返回 */}
        <Button
            onClick={() => {
                window.history.back();
            }}
            style={{ marginRight: 10 }}
            type="primary"
            icon={<LeftOutlined />} />

        {/* 章节 */}
        <Button
            onClick={onClick_Chapter}
            type="primary"
            icon={<OrderedListOutlined />}>
            章节选择
        </Button>

        {/* 删除 */}
        <Button
            danger
            onClick={onClick_del}
            disabled={!selectedNode}
            style={{ float: 'right' }}
            type="primary"
            icon={<DeleteOutlined />} />

        {/* 聊天 */}
        <Button
            // @ts-expect-error
            style={selectedNode ? buttonStyles : {
                float: 'right',
                marginRight: 10,
            }}
            onClick={onClick_chatMap}
            disabled={!selectedNode}
            type="primary"
            icon={<MessageOutlined />} />

        {/* 刷新 */}
        <Button
            onClick={onClick_refresh}
            style={{ float: 'right', marginRight: 10, fontWeight: 'bold', backgroundColor: 'white', color: '#3f5464' }}
            type="primary"
            icon={<ReloadOutlined />} />

        {/* 缩放百分比 */}
        <Button
            onClick={resetScale}
            style={{ float: 'right', marginRight: 10, fontWeight: 'bold', width: 70, backgroundColor: 'white', color: '#3f5464' }}
            type="primary">
            {Math.round(transform.k * 100)}%
        </Button>


    </div>
}

const buttonStyles = {
    float: 'right',
    marginRight: 10,
    background: 'linear-gradient(to right, #296dd3, #04b2fe)',
    border: 'none',
    color: 'white',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.10)',
}

// 样式

export default TopOperation;