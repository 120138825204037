import React, {createContext, useContext, useReducer, Dispatch, Reducer} from 'react';

interface IUserInfo {
    username?: string;
    userId?: string;
    nickname?: string;
    avatar?: string;
}

export interface IMenu {
    name: string;
    url: string;
    children?: {
        name: string;
        url: string;
    }[]
}

export interface IState {
    isLogin?: boolean;
    userInfo?: IUserInfo;
    menus?: IMenu[];
}

export interface IAction<P = {}> {
    type: string;
    payload?: P
}

interface ICreateContext {
    state: IState;
    dispatch: Dispatch<IAction<Partial<IState>>>
}

const initialState: IState = {
    isLogin: false,
    userInfo: {},
    menus: []
};

export enum ActionType {
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    SET_MENUS = "SET_MENUS",
}

const AppContext = createContext<ICreateContext>({
    state: initialState, dispatch: () => {
    }
});
const appReducer: Reducer<IState, IAction> = (state, action) => {
    switch (action.type) {
        case ActionType.LOGIN:
            return {...state, isLogin: true, ...action.payload};
        case ActionType.LOGOUT:
            return {...state, isLogin: false, userInfo: {}, menus: []};
        case ActionType.SET_MENUS:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

interface IAppProvider {
    children?: React.ReactNode
}

export const AppStore = ({children}: IAppProvider) => {
    const [state, dispatch] = useReducer(appReducer, initialState);
    // console.log("AppStore", state);
    return (
        <AppContext.Provider value={{state, dispatch}}>
            {children}
        </AppContext.Provider>
    );
};
export const useAppContext = () => useContext(AppContext);

class StoreMgr {
    private mgr: any = {}
    private storeEmitterKey = "UPDATESTORE"

    dispatch(action: IAction) {
        this.mgr[this.storeEmitterKey]?.(action)
    }

    on(fn: (action: IAction) => any) {
        this.mgr[this.storeEmitterKey] = fn
    }

    off() {
        delete this.mgr[this.storeEmitterKey]
    }
}

export const storeMgr = new StoreMgr()
