import { Alert, Button, Flex, Layout, message, Popover } from 'antd';
import React, { useImperativeHandle, forwardRef, useState, useMemo, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { getOneLangDto } from '../../../../utils/lang';
import ChatMap_Sider from './comp/ChatMap_Sider';
import { UseDrawChatMap } from './hook/UseDrawChatMap';
import UseChatMapData from './hook/UseChatMapData';
import ChatMap_Header from './comp/ChatMap_Header';
import ChatMap_AddMenu, { AddMenuRef } from './comp/ChatMap_AddMenu';
import ChatMap_RigForm from './comp/ChatMap_RigForm';
const { Content } = Layout;

interface IChatMap {
    playId: string;
    storyNode: any;
    onClosed: () => void;
    updateNode: (params: { value: any, nodeId: string, type: any }) => void;
    storyMap: any;
}

export interface ChatMapRef {
    show: () => void;
}

const ChatMap = forwardRef<ChatMapRef, IChatMap>((props, ref) => {
    const { playId, storyNode, onClosed, updateNode: updateStoryNode, storyMap } = props;
    const [isVisible, setIsVisible] = useState(!!storyNode); // 是否显示
    const addMenuRef = useRef<AddMenuRef>(null);
    const [curRole, setCurRole] = useState<any>() // 当前选中的角色
    const [selectedNode, setSelectedNode] = useState<any>(null);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const addTarget = useRef<any>(null);
    const svgRef = React.useRef<SVGSVGElement>(null);
    useImperativeHandle(ref, () => ({
        show() { setIsVisible(true) }
    }));
    // 数据 hook
    const [
        roles, // 角色列表数据
        chatMapData, // 当前角色对话数据
        ChatMap, // 节点数据源
        createNode, // 创建节点
        updateNode, // 更新节点
        deleteNode, // 删除节点
    ] = UseChatMapData({ playId, storyId: storyNode?.id, curRole, isVisible })
    // 绘制 hook
    const [startNode, setStartNode] = UseDrawChatMap({
        updateNode,
        selectedNode,
        ChatMap,
        svgRef,
        data: chatMapData,
        onClickNode: (e: any, d: any) => {
            addMenuRef.current?.hideMenu();
            if (d.data.id !== 'root') return setSelectedNode({ ...ChatMap.current[d.data?.id], type: d.data.type });
            // 获取data根节点第一个成员的tpye
            const type = chatMapData.children && chatMapData.children[0]?.type;
            if (type == 1) return
            setMenuPosition({ x: e.clientX, y: e.clientY });
            addTarget.current = d;
            if (!type) return addMenuRef.current?.showMenu(4)
            addMenuRef.current?.showMenu(type)
        },
        onClickAddNode: (e, d) => {
            const isType_2 = d.children && d.children.some((child: any) => child.data.type === 2);
            const isType_3 = d.children && d.children.some((child: any) => child.data.type === 3);
            if (isType_2) {
                addMenuRef.current?.showMenu(2)
            } else if (isType_3) {
                addMenuRef.current?.showMenu(3)
            } else {
                addMenuRef.current?.showMenu(5)
            }
            addTarget.current = d;
            setMenuPosition({ x: e.clientX, y: e.clientY });
        },
    });

    // 增加节点
    const handleAddNode = (type: 1 | 2 | 3 | 4) => {
        addMenuRef.current?.hideMenu();
        const _addTarget = addTarget.current;
        if (type == 4) {
            // 进入新增跳跃连线状态
            setStartNode(_addTarget);
            return
        }
        const isStart = _addTarget.data.id == 'root' ? 1 : 0;
        // 新增节点
        createNode(_addTarget.data, type, isStart);
    };

    const close = () => {
        setIsVisible(false);
        setCurRole(null);
        onClosed()
    }

    if (!isVisible) return null;
    return <div
        style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 100,
            backgroundColor: 'white',
        }}
    >
        <Layout style={layoutStyle}>
            {/* 左侧 */}
            <ChatMap_Sider roles={roles} curRole={curRole} selectedNode={storyNode} onClickItem={(el: any) => setCurRole(el)} />

            {/* 右侧 */}
            <div style={{ flex: 1, position: 'relative' }}>
                {/* 顶部操作栏 */}
                <ChatMap_Header
                    storyMap={storyMap}
                    onFormChange={(val) => {
                        updateStoryNode({ value: val, nodeId: storyNode.id, type: storyNode.type })
                    }}
                    storyNode={storyNode}
                    close={close}
                    onClick_del={() => deleteNode(selectedNode.id, selectedNode.type)}
                    selectedNode={selectedNode}
                    curRole={curRole}
                />
                <svg
                    style={{ width: '100%' }}
                    onClick={() => {
                        addMenuRef.current?.hideMenu();
                    }} ref={svgRef} />
            </div>

            {/* 表单 */}
            <ChatMap_RigForm
                info={selectedNode}
                close={() => { setSelectedNode(null) }}
                onSubmit={(val: any, type: any) => {
                    updateNode({ value: val, nodeId: selectedNode.id, type })
                }} />

            {/* 提示内容 */}
            {startNode && <div style={{
                position: 'absolute',
                top: 50,
                zIndex: 1,
                width: 250,
                left: '50%',
                marginLeft: -125,
            }}>
                <Alert message="请在图中选择要链接的节点" type="info" showIcon closable afterClose={() => {
                    setStartNode(null);
                }} />
            </div>}

            <ChatMap_AddMenu
                onAddNode={handleAddNode}
                ref={addMenuRef}
                position={menuPosition}
            />
        </Layout>
    </div>
})

const contentStyle: React.CSSProperties = {
    backgroundColor: '#f3f5f7',
};

const layoutStyle = {
    width: '100%',
};
export default ChatMap;