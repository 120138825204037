import React, { lazy, Suspense, useEffect, useState } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { routeCfg } from "../../../routeCfg";
import { ActionType, IMenu, IState, storeMgr, useAppContext } from "../../../appStore";
import { Layout } from "../../common/layout";
import { Spin } from "antd";
import "./index.less";
import { homePageUrl } from "../../../config/public";
import { authService } from "../../../service/auth/auth-service";
import { Login } from "../../../pages/login/login";

// 模拟菜单
const menus: IMenu[] = [
    { name: "首页", url: "/home", children: [] },
    {
        name: "短剧管理", url: "/play", children: [
            { name: "短剧列表", url: "/play/info/list" },
            { name: "剧集列表", url: "/play/info/episode-list" },
            { name: "互动剧列表", url: "/play/info/list_2" },
            { name: "推荐列表", url: "/play/info/recommend-list" },
            { name: "完播推荐配置", url: "/play/info/endPlayRecommend-list" },
        ]
    },
    {
        name: "充值管理", url: "/order", children: [
            { name: "订单记录", url: "/order/list" },
            { name: "充值项管理",url:"/recharge/rechargeList"},
            { name: "区服档位配置", url: "/recharge/list" }
        ]
    },
    {
        name: "用户管理", url: "/user", children: [
            { name: "用户列表", url: "/user/list" },
            { name: "反馈列表", url: "/feedback/list" }
        ]
    },
    {
        name: "消息管理", url: "/pushTask", children: [
            { name: "推送管理", url: "/pushTask/list" },
        ]
    },
    {
        name: "频道管理", url: "/channel", children: [
            { name: "首页模块配置", url: "/homeModule/list" },
            { name: "推荐视频配置", url: "/recommendVideoConfig/list" },
            { name: "广告位配置", url: "/banner/list" },
        ]
    },
    {
        name: "更新管理", url: "/update", children: [
            { name: "更新配置", url: "/version/list" }
        ]
    },
    {
        name: "活动管理", url: "/active", children: [
            { name: "tiktok任务管理", url: "/active/list" }
        ]
    }
]

/**
 * 全局首页
 * @constructor
 */
import { setPlayInfoData } from "../../../utils/playInfoData";
import InteractiveStory from "../../../pages/InteractiveStory";
export const AuthComp = () => {
    const { state, dispatch } = useAppContext()
    const [loading, setLoading] = useState<boolean>(true)
    const { isLogin } = state


    useEffect(() => {
        checkLoginProd();
        storeMgr.on((action) => {
            dispatch(action)
        })
        return () => storeMgr.off()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (isLogin) {
            console.log("触发登录");
            dispatch({
                type: ActionType.SET_MENUS,
                payload: { menus }
            })
        }
    }, [isLogin]);


    /**
     * 检查登录
     */
    const checkLoginProd = () => {
        console.log("检查登录")
        // 判断缓存有没有 token
        authService.checkToken()
            .then(res => {
                authService.afterLogin()
                    .then(res2 => {
                        console.log("afterlogin-->", res2);
                        const userInfo = res2.data.userInfo;
                        if (res2.status == 1) {
                            setPlayInfoData("playInfoMap", res2.data.playInfoMap)
                            setPlayInfoData("regionMap", res2.data.regionMap)
                            setPlayInfoData("levelMap", res2.data.levelMap)
                            setPlayInfoData("rechargeMap",res2.data.rechargeMap)
                            const payload: IState = {
                                isLogin: true,
                                userInfo: { username: userInfo.realName, userId: userInfo.phone },
                                menus
                            }
                            dispatch({
                                type: ActionType.LOGIN,
                                payload
                            })
                            setLoading(false)
                        }
                    })
                    .catch(err2 => {
                        console.error(err2);
                    })

            })
            .catch(err => {
                // TODO 需要判断是什么错，要不要登出
                dispatch({
                    type: ActionType.LOGOUT
                });
                setLoading(false)
            })

    }


    return loading ? <div className="auth-spin">
        <Spin size={"large"} />
    </div> : <HashRouter>
        <Layout>
            <Suspense>
                <Routes>
                    <Route path={"/"} element={<Navigate to={isLogin ? homePageUrl : "/login"} />} />
                    <Route path={"/login"} element={isLogin ? <Navigate to="/" /> : <Login />} />
                    <Route path={"/interactiveStory"} element={<InteractiveStory />} />
                    {
                        routeCfg.map((route) => {
                            const Comp = lazy(route.comp)
                            return <Route
                                key={route.path}
                                path={route.path}
                                element={isLogin ? <Comp /> : <Navigate to="/login" />}
                            />
                        })
                    }
                </Routes>
            </Suspense>
        </Layout>
    </HashRouter>
}
