import { Button, Card, Checkbox, Col, Form, Input, Radio, Row, Select, Space, DatePicker, Tabs, InputNumber } from "antd";
import { CloseOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useRef, useState } from "react";
import ChooseLang from "../../../../components/common/ChooseLang";
import { dealLangToPage } from "../../../../utils/lang";
import { getPlayInfoData } from "../../../../utils/playInfoData";
import { valueNumberService } from "../../../../service/play/value-number-service";

interface IStoryFrom {
    onFinish: (value: any) => void;
    info: any;
    playId: string
    isChatForm?: boolean;
}

const SelectForm = (props: IStoryFrom) => {
    const { onFinish, info, isChatForm = false, playId } = props;
    const [form] = Form.useForm();
    const introLang = useRef<any>([]) //映射简介语言数据
    const [useMoney, setUseMoney] = useState(true);
    const [useVipMoney, setUseVipMoney] = useState(true);
    const [useDiscount, setUseDiscount] = useState(true);
    const regionMap = getPlayInfoData("regionMap");//国家/地区map

    const [valueMap, setValueMap] = useState({});
    //初始化数据
    const initData = () => {
        valueNumberService.valueList({ playId }).then((res) => {
            let map: any = {}
            if (res.data) {
                res.data.map((item: any) => {
                    map[item["id"]] = item["name"];
                })
            }
            setValueMap(map);
        })
    }

    useEffect(() => {
        initData();
    }, []);

    useEffect(() => {
        if (!info) return
        form.setFieldsValue({
            nameInfo: info.nameInfo && dealLangToPage(info.nameInfo),
            history: info.history,
            addValue: info.addValue || [],
            conditionDetail: {
                conditionType: info.conditionDetail?.conditionType || 1,
                children: info.conditionDetail?.children || [
                    {
                        conditionType: 1,
                        children: [
                            { valueType: 1, opType: 1, optionId: 1 }
                        ]
                    }
                ]
            }
        });
    }, [info, form])

    const tabItem = useMemo(() => {
        const item = [
            {
                key: '1',
                label: '选择条件',
                children: <>
                    <Row gutter={16}>
                        <Col span={11}>
                            <Form.Item label="" name={["conditionDetail", "conditionType"]}>
                                <Select>
                                    <Select.Option value={1}>且（满足全部条件）</Select.Option>
                                    <Select.Option value={0}>或（满足其中之一）</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2} />
                        <Col span={11}>
                            <Form.Item
                                label="不满足条件是否显示">
                                <Radio.Group>
                                    <Radio value={1}> 是 </Radio>
                                    <Radio value={0}> 否 </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Form.List name={["conditionDetail", "children"]}>
                            {(fields, { add, remove }) => (
                                <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                    {fields.map((field) => (
                                        <Card
                                            size="small"
                                            title={`条件 ${field.name + 1}`}
                                            key={field.key}
                                            extra={
                                                <CloseOutlined
                                                    onClick={() => remove(field.name)}
                                                />
                                            }
                                        >
                                            <Form.Item label="" name={[field.name, 'conditionType']}>
                                                <Select>
                                                    <Select.Option value={1}>且（满足全部条件）</Select.Option>
                                                    <Select.Option value={0}>或（满足其中之一）</Select.Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.List name={[field.name, 'children']}>
                                                {(subFields, subOpt) => (
                                                    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                                        {subFields.map((subField) => (
                                                            <Space key={subField.key} align="baseline">
                                                                <Form.Item noStyle name={[subField.name, 'valueType']}>
                                                                    <Select style={{ width: 105 }}>
                                                                        <Select.Option value={1}>具体数值</Select.Option>
                                                                        <Select.Option value={2}>数值属性</Select.Option>
                                                                    </Select>
                                                                </Form.Item>

                                                                <Form.Item
                                                                    noStyle
                                                                    shouldUpdate={(prevValues, currentValues) =>
                                                                        prevValues.children?.[field.name]?.children?.[subField.name]?.valueType !==
                                                                        currentValues.children?.[field.name]?.children?.[subField.name]?.valueType
                                                                    }
                                                                >
                                                                    {() => {
                                                                        const valueType = form.getFieldValue(['children', field.name, 'children', subField.name, 'valueType']);
                                                                        return valueType === 1 ? (
                                                                            <Form.Item noStyle name={[subField.name, 'optionId']}>
                                                                                <Select style={{ width: 150 }}>
                                                                                    <Select.Option value={1}>角色1好感度</Select.Option>
                                                                                    <Select.Option value={2}>角色2好感度</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        ) : (
                                                                            <Form.Item noStyle name={[subField.name, 'valueAttr']}>
                                                                                <Select style={{ width: 100 }}>
                                                                                    <Select.Option value={1}>好感度</Select.Option>
                                                                                    <Select.Option value={2}>xxx</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        );
                                                                    }}
                                                                </Form.Item>

                                                                <Form.Item
                                                                    noStyle
                                                                    shouldUpdate={(prevValues, currentValues) =>
                                                                        prevValues.children?.[field.name]?.children?.[subField.name]?.valueType !==
                                                                        currentValues.children?.[field.name]?.children?.[subField.name]?.valueType
                                                                    }
                                                                >
                                                                    {() => {
                                                                        const valueType = form.getFieldValue(['children', field.name, 'children', subField.name, 'valueType']);
                                                                        return <Form.Item
                                                                            noStyle name={[subField.name, 'opType']}>
                                                                            <Select style={{ width: 75 }}>
                                                                                <Select.Option value={1}>{'>'}</Select.Option>
                                                                                <Select.Option value={2}>{'>='}</Select.Option>
                                                                                <Select.Option value={3}>{'='}</Select.Option>
                                                                                <Select.Option value={4}>{'<'}</Select.Option>
                                                                                <Select.Option value={5}>{'<='}</Select.Option>
                                                                                <Select.Option value={6}>{'!='}</Select.Option>
                                                                                {valueType == 1 ? null : <><Select.Option value={7}>{'第几名'}</Select.Option>
                                                                                    <Select.Option value={8}>{'前几名'}</Select.Option></>}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    }}
                                                                </Form.Item>

                                                                <Form.Item noStyle name={[subField.name, 'value']}>
                                                                    <Input placeholder="具体数值" />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    noStyle
                                                                    shouldUpdate={(prevValues, currentValues) =>
                                                                        prevValues.children?.[field.name]?.children?.[subField.name]?.valueType !==
                                                                        currentValues.children?.[field.name]?.children?.[subField.name]?.valueType
                                                                    }
                                                                >
                                                                    {() => {
                                                                        const valueType = form.getFieldValue(['children', field.name, 'children', subField.name, 'valueType']);
                                                                        return valueType === 2 && (
                                                                            <Form.Item noStyle name={[subField.name, 'optionId']}>
                                                                                <Select style={{ width: 150 }}>
                                                                                    <Select.Option value={1}>角色1好感度</Select.Option>
                                                                                    <Select.Option value={2}>角色2好感度</Select.Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                        )
                                                                    }}
                                                                </Form.Item>
                                                                <CloseOutlined
                                                                    onClick={() => subOpt.remove(subField.name)}
                                                                />
                                                            </Space>
                                                        ))}
                                                        <Button
                                                            icon={<PlusOutlined />}
                                                            type="dashed"
                                                            style={{ fontSize: 13 }}
                                                            onClick={() => subOpt.add({ valueType: 1, opType: 1, optionId: 1 })}
                                                            block
                                                        >
                                                            添加子条件
                                                        </Button>
                                                    </div>
                                                )}
                                            </Form.List>
                                        </Card>
                                    ))}

                                    <Button
                                        icon={<PlusOutlined />}
                                        type="dashed"
                                        onClick={() => add({
                                            conditionType: 1,
                                            children: [
                                                { valueType: 1, opType: 1, optionId: 1 }
                                            ]
                                        })}
                                        block
                                    >
                                        添加条件
                                    </Button>
                                </div>
                            )}
                        </Form.List>
                    </Row>

                </>,
            },
            {
                key: '2',
                label: '选项付费',
                children: <>
                    <Row gutter={16}>
                        <Col span={11}>
                            <Form.Item
                                name="money"
                                label={
                                    <Checkbox
                                        onChange={(e) => setUseMoney(e.target.checked)}
                                    >付费金额</Checkbox>
                                }
                            >
                                <Input
                                    disabled={!useMoney}
                                    placeholder='请输入付费金额' />
                            </Form.Item>
                        </Col>
                        <Col span={2} />
                        <Col span={11}>
                            <Form.Item
                                name="vipMoney"
                                label={
                                    <Checkbox
                                        onChange={(e) => setUseVipMoney(e.target.checked)}
                                    >会员优惠价</Checkbox>
                                }
                            >
                                <Input
                                    disabled={!useVipMoney}
                                    placeholder='请输入会员优惠价' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10}>
                            <Form.Item
                                name="discountStyle"
                                label={
                                    <Checkbox
                                        onChange={(e) => setUseDiscount(e.target.checked)}
                                    >选项折扣样式</Checkbox>
                                }
                            >
                                <Input
                                    disabled={!useDiscount}
                                    placeholder='输入原价（划线价）' />
                            </Form.Item>
                        </Col>
                        <Col span={1} />
                        <Col span={13}>
                            <Form.Item
                                name={'payType'}
                                label="付费模式">
                                <Radio.Group>
                                    <Radio value={1}> 仅Points </Radio>
                                    <Radio value={2}> Points+Bonus </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.List name="registerMoney">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            label="注册时间"
                                            name={[name, 'startTime']}
                                        >
                                            <DatePicker placeholder="开始时间" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'endTime']}
                                        >
                                            <DatePicker placeholder="结束时间" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'registerPrice']}
                                        >
                                            <Input style={{ width: 90 }} placeholder="付费金额" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'vipRegisterPrice']}
                                        >
                                            <Input style={{ width: 90 }} placeholder="优惠价" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        添加注册时间
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </>,
            },
            {
                key: '3',
                label: '特殊定价',
                children: <Form.List name="regionMoney">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Card
                                    style={{ marginBottom: 16 }}
                                    size="small"
                                    title={`条件 ${name + 1}`}
                                    key={key}
                                    extra={
                                        <CloseOutlined
                                            onClick={() => remove(name)}
                                        />
                                    }
                                >
                                    <Form.Item name={["regionId"]} label="国家/地区" rules={[{ required: true }]}>
                                        <Select allowClear showSearch
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {Object.keys(regionMap).map((key) => {
                                                return <Select.Option key={key} value={key}>{regionMap[key]}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Row>
                                        <Col>
                                            <Form.Item
                                                name="money"
                                                label='付费金额'
                                            >
                                                <Input
                                                    placeholder='请输入定价' />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}></Col>
                                        <Col>
                                            <Form.Item
                                                {...restField}
                                                label='优惠价'
                                                name={[name, 'vipMoney']}
                                            >
                                                <Input placeholder="优惠价" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.List name={[name, 'children']}>
                                        {(subFields, subOpt) => (
                                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                                                {subFields.map((subField) => (
                                                    <Space key={subField.key} align="baseline">
                                                        <Form.Item
                                                            {...restField}
                                                            label="注册时间"
                                                            name={[name, 'startTime']}
                                                        >
                                                            <DatePicker placeholder="开始时间" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'endTime']}
                                                        >
                                                            <DatePicker placeholder="结束时间" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[subField.name, 'money']}
                                                        >
                                                            <Input placeholder="请输入付费金额" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[subField.name, 'vipMoney']}
                                                        >
                                                            <Input placeholder="请输入优惠价" />
                                                        </Form.Item>
                                                        <CloseOutlined
                                                            onClick={() => subOpt.remove(subField.name)}
                                                        />
                                                    </Space>
                                                ))}
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    type="dashed"
                                                    style={{ fontSize: 13 }}
                                                    onClick={() => subOpt.add({ valueType: 1, opType: 1, optionId: 1 })}
                                                    block
                                                >
                                                    添加注册时间
                                                </Button>
                                            </div>
                                        )}
                                    </Form.List>
                                </Card>

                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    添加地区定价
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>,
            },
            {
                key: '4',
                label: '数值加成',
                children: <Form.List name="addValue">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item noStyle name={[name, 'numberId']}>
                                        <Select style={{ width: 150 }} showSearch placeholder={"点击下拉关联数值"}
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {Object.keys(valueMap).map((key: any) => {
                                                return <Select.Option value={key} key={key}>{valueMap[key]}</Select.Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'opType']}
                                    >
                                        <Select style={{ width: 60 }}>
                                            <Select.Option value={1}>{'+'}</Select.Option>
                                            <Select.Option value={2}>{'-'}</Select.Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                    >
                                        <InputNumber placeholder="请输入付费金额" />
                                    </Form.Item>
                                    <CloseOutlined
                                        onClick={() => remove(name)}
                                    />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    添加加成
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>,
            },
            {
                key: '5',
                label: '付费关联',
                children: <Tabs
                    tabPosition={'left'}
                    items={new Array(3).fill(null).map((_, i) => {
                        const id = String(i + 1);
                        return {
                            label: `章节 ${id}`,
                            key: id,
                            children: <Form.Item
                                name={['chapter', id]}
                            // rules={[{ required: true, message: '请选择至少一个选项' }]}
                            >
                                <Checkbox.Group style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: 5
                                }}>
                                    <Checkbox value="option1">选项</Checkbox>
                                    <Checkbox value="option2">选项</Checkbox>
                                    <Checkbox value="option3">选项</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>,
                        };
                    })}
                />,
            },
        ]
        if (isChatForm) {
            return item.filter(i => i.key !== '2' && i.key !== '3' && i.key !== '5')
        }
        return item;
    }, [form, valueMap])

    const handleFinish = (values: any) => {
        // 处理表单数据，构造最终需要的格式
        const formattedValues = {
            conditionDetail: {
                conditionType: values.conditionType,
                children: values.children || []
            }
        };
        // 调用传入的 onFinish 方法并传递格式化后的数据
        onFinish(values);
    };

    return info && (
        <Form
            form={form}
            style={{
                width: 600,
                padding: 12,
                marginTop: 50,
                overflow: 'auto',
                height: 'calc(100% - 100px)',
            }}
            name="form_item_path"
            onFinish={handleFinish}
        >
            <Form.Item name="conditionDetail">
                <ChooseLang
                    language={introLang.current}
                    renderTab={(index, language) => {
                        return <>
                            <Form.Item name={["nameInfo", index, language.key]} label="内容" rules={[{ required: true }]}>
                                <Input placeholder='请输入' />
                            </Form.Item>
                        </>
                    }} />
                {isChatForm && <Form.Item
                    name="history"
                    valuePropName="checked"
                    getValueProps={(value) => ({ checked: value === 1 })}
                    getValueFromEvent={(e) => (e.target.checked ? 1 : 0)}
                >
                    <Checkbox>设为历史记录</Checkbox>
                </Form.Item>}
                <Tabs defaultActiveKey="1" items={tabItem} onChange={() => { }} />
            </Form.Item>

            <div style={{ height: 50 }}>
                <Button type="primary" htmlType="submit">
                    保存
                </Button>
            </div>
        </Form>
    )
}

export default SelectForm;