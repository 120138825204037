import React, {useEffect, useState} from "react";
import {ActionType, IState, useAppContext} from "../../appStore";
import {useNavigate} from "react-router";
import {Button, message, Spin} from "antd";
import {authService} from "../../service/auth/auth-service";

export const Login = () => {
    const [msgApi, contextHolder] = message.useMessage();
    const {dispatch} = useAppContext()
    const navigate = useNavigate()
    // const [params, set] = useSearchParams()
    const [allowMock, setAllowMock] = useState("dev" == process.env.REACT_APP_ENV && "true" == process.env.REACT_APP_MOCK)
    // 获取url参数
    const searchParams = new URLSearchParams(window.location.search);
    const params: any = {};
    for (let param of searchParams.entries()) {
        params[param[0]] = param[1];
    }
    // console.log("location", params);
    // const {setLocalStorage} = useLocalStorage()

    /**
     * 模拟登录
     */
    const mockLogin = () => {
        const adminUserId = "6643316ccf85694553e31c39";
        authService.mockLogin(adminUserId)
            .then(res => {
                const token = res.data.token;
                console.log("mockLogin", res.data.token);
                localStorage.setItem("token", token);
                afterLogin();
            })
            .catch(err => {
                console.error(err);
            })
    }

    const afterLogin = ()=>{
        location.href = "/"
    }


    useEffect(() => {
        if (!allowMock) {
            console.log("走平台登录");
            platformLogin();
        }
    }, [])


    const platformLogin = () => {
        const code = params.code;
        // const code = null;
        if (!code) {
            msgApi.error("登录失效！请通过开放平台进入！").then();
            return;
        }
        authService.platformLogin(code)
            .then(res => {
                console.log("platformLogin", res);
                if(res.status ==1){
                    const token = res.data.token;
                    console.log("platformLogin", res.data.token);
                    localStorage.setItem("token", token);
                    afterLogin();
                }
            })
            .catch(err => {
                msgApi.error("鉴权错误").then();
                console.error(err);
            })
    }

    return <div style={{height: "100vh", display: "flex", justifyContent: 'center', alignItems: 'center'}}>
        {contextHolder}
        {allowMock ? <Button onClick={mockLogin}>
                模拟登录
            </Button> :
            <Spin>

            </Spin>

        }

    </div>
}
