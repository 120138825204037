import {http} from "../request";

class AuthService {

    /**
     * 任意请求接口
     */
    checkToken() {
        return http.post("/auth/checkToken", {});
    }

    /**
     * 模拟登陆
     * @param adminUserID
     */
    mockLogin(adminUserID: string) {
        return http.post("/admin/user/mockLogin?adminUserID=" + adminUserID, {});
    }

    /**
     * 登录后的请求
     */
    afterLogin(){
        return http.post("/admin/user/afterLogin",{});
    }

    /**
     * 平台code登录
     * @param code
     */
    platformLogin(code:string){
        return http.get("/admin/user/validationCode?code=" + code);
    }

}

export const authService = new AuthService();
