import { http } from "../request";

class PlayInfoService {

    // cdn 域名
    private cdnUrl = process.env.REACT_APP_CND_URL;

    // 短剧封面前缀
    private playInfoImagePrefix = "/playlet/";


    /**
     * 查询短剧列表
     * @param param
     */
    list(param: {
        pageNum: number;
        pageSize: number;
        name?: string;
        playName?: string;
        language?: string;
        updateStatus?: number;
        type?: number;
        status?: number;
        playSubscriptType?: number;
    }) {
        return http.post("/playInfo/list", param)
    }

    /**
     * 增加短剧，先用 any
     * @param playInfo
     */
    add(playInfo: any) {
        return http.post("/playInfo/add", playInfo)
    }

    /**
     * 更新短剧信息
     * @param playInfo
     */
    edit(playInfo: any) {
        return http.post("/playInfo/update", playInfo)
    }

    /**
     * 传入相对路径获取完整的路径
     * @param path
     * @param language
     */
    getFullPlayInfoImage(path: string, language?: string) {
        const _lang = language ? language : "en";
        if (path.startsWith("/")) {
            return this.cdnUrl + this.playInfoImagePrefix + _lang + path;
        } else {
            return this.cdnUrl + this.playInfoImagePrefix + _lang + "/" + path;
        }
    }

    //根据id获取短剧信息
    getById(id: string) {
        return http.post("/playInfo/getById", id);
    }

    //同步短剧封面信息
    syncCoverUrl(id: string) {
        return http.post("/episodeInfo/getEpisodeCover", id);
    }

    //同步短剧的字幕
    syncSubtitle(id:string){
        return http.post("/playInfo/syncSubtitle", id);
    }

    //获取章节剧情信息
    playChapterStory(param:any){
        return http.post("/playChapter/listChapterAndStory", param);
    }
}

export const playInfoService = new PlayInfoService();
