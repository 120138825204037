import { useEffect, useRef, useState } from "react";
import { IChapter_req_update, StoryService } from "../../../service/interactiveStory/inedx";
import { NodeData, TYPE_MAP } from "..";
import { message, Modal } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';

// 初始化剧情数据
const UseStoryData = ({ playId }: { playId: string }) => {
    const [data, setData] = useState<NodeData | any>(); // 当前剧情数据
    const [list, setList] = useState<any[]>([]); // 章节列表
    const [curChapterId, setCurChapterId] = useState<string>(''); //当前选中章节id
    const storyMap = useRef<any>({})

    // 新增节点
    const addNode = (parentNode: NodeData, type: any) => {
        TYPE_MAP[type - 1].api_create({
            chapterId: curChapterId,
            playId,
            parentId: parentNode.id,
            parentType: parentNode.type,
        }).then(res => {
            const findParent = (node: NodeData) => {
                if (node.id === parentNode.id) {
                    node.children = node.children || [];
                    node.children.push({
                        type,
                        id: res.data
                    })
                    return;
                }
                if (node.children) node.children.forEach(findParent)
            }
            findParent(data);
            setData({ ...data });
            storyMap.current[res.data] = {
                type,
                id: res.data
            }
        })
    }

    // 更新节点
    const updateNode = (params: { value: any, nodeId: string, type: any }, cb: (err?: any) => void) => {
        const { value, nodeId, type } = params;
        TYPE_MAP[type - 1].api_update({ ...value, id: nodeId }).then(res => {
            storyMap.current[nodeId] = { ...storyMap.current[nodeId], ...value }
            if (value.nextStoryId) {
                // 给data里id为nodeId的节点添加子集，增加字段nextId 和 isPlaceholder 
                const findNode = (node: NodeData) => {
                    if (node.id === nodeId) {
                        // @ts-expect-error
                        node.nextStoryId = node.nextId = value.nextStoryId
                        return;
                    }
                    if (node.children) node.children.forEach(findNode)
                }
                findNode(data);
            } else if (value.nextStoryId === '') {
                // 删除data里id为nodeId的节点的子集
                const findNode = (node: NodeData) => {
                    if (node.id === nodeId) {
                        node.children = [];
                        return;
                    }
                    if (node.children) node.children.forEach(findNode)
                }
                findNode(data);
            }
            setData({ ...data });
            cb && cb()
        }).catch(err => {
            cb && cb(err)
            message.open({
                type: 'error',
                content: '更新节点失败',
            });
        })
    }

    // 刷新节点
    const changeNode = (chapterId: string) => {
        const _chapterId = chapterId || curChapterId;
        StoryService.getChapter({ chapterId: _chapterId }).then(res => {
            setCurChapterId(_chapterId)
            if (res.data?.router) {
                // 有数据
                storyMap.current = {
                    ...res.data.storyMap,
                    ...res.data.selectMap,
                    ...res.data.conditionMap
                }
                // TODO 减少遍历
                const data = markDuplicateNodes(res.data.router);
                const findParent = (node: NodeData) => {
                    if (node.children) {
                        node.children.forEach((child: any) => {
                            if (child.isPlaceholder) {
                                node.nextId = child.id;
                            }
                        });
                        node.children = node.children.filter((child: any) => !child.isPlaceholder);
                        node.children.forEach(findParent)
                    }
                }
                findParent(data);
                setData(data);
            } else {
                // 没有数据, 自动生成第一个节点
                StoryService.createChapterStory({
                    chapterId,
                    playId,
                    isStart: 1,
                }).then(res => {
                    setData({
                        type: 1,
                        id: res.data
                    })
                    storyMap.current[res.data] = {
                        type: 1,
                        id: res.data
                    }
                })
            }
        }).catch(err => {
            message.open({
                type: 'error',
                content: '获取章节节点失败',
            });
        });
    }

    // 删除节点
    const deleteNode = (nodeId: string, type: any) => {
        Modal.confirm({
            title: '提示',
            icon: <ExclamationCircleFilled />,
            content: '确定删除该节点？',
            okText: '删除',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                TYPE_MAP[type - 1].api_delete({ ids: [nodeId] }).then(res => {
                    delete storyMap.current[nodeId];
                    // 删除data里id为nodeId的节点及其子集
                    const findParent = (node: NodeData) => {
                        if (node.children) {
                            node.children = node.children.filter((child: any) => child.id !== nodeId);
                            node.children.forEach(findParent)
                        }
                    }
                    findParent(data);
                    setData({ ...data });
                    message.open({
                        type: 'success',
                        content: '已删除',
                    });
                }).catch(err => {
                    message.open({
                        type: 'error',
                        content: '删除失败',
                    });
                })
            },
            onCancel() {
            },
        });
    }

    // 新增章节
    const addChapter = () => {
        StoryService.addPlayChapterList({
            playId,
        }).then(res => {
            setList([...list, {
                id: res.data,
                nameInfo: [{ name: '章节名称', lang: 'en' }]
            }])
        }).catch(err => {
            message.open({
                type: 'error',
                content: '新增章节失败',
            });
        })
    }

    // 修改章节
    const updateChapter = (values: any) => {
        StoryService.updatePlayChapterList({
            playId,
            ...values,
        }).then(res => {
            message.open({
                type: 'success',
                content: '修改成功',
            });
        }).catch(err => {
            message.open({
                type: 'error',
                content: '修改失败',
            });
        })
    }

    useEffect(() => {
        // 获取章节列表
        StoryService.getPlayChapterList({ playId }).then(res => {
            if (res.data?.length > 0) {
                setList(res.data)
                changeNode(res.data[0].id)
            }
        })
    }, [])

    return [data, addNode, updateNode, deleteNode, changeNode, storyMap, curChapterId, addChapter, updateChapter, list];
}

// 标记重复节点
export const markDuplicateNodes = (node: any, nodeMap = new Map(), depth = 0) => {
        // 比对重复节点
    if (nodeMap.has(node.id)) {
        const existingNode = nodeMap.get(node.id);
        if (existingNode.depth < depth) {
            // 之前的节点深度更浅
            existingNode.nodes.forEach(n => {
                n.isPlaceholder = true;
            });
            node.isPlaceholder = false;
            nodeMap.set(node.id, { depth, nodes: [node] });
        } else if (existingNode.depth > depth) {
            // 之前的节点深度更深
            node.isPlaceholder = true;
        } else {
            // 深度相同的情况， 取中间
            const currentNodes = nodeMap.get(node.id).nodes;
            currentNodes.push(node);
            const midIndex = Math.floor(currentNodes.length / 2);

            currentNodes.forEach((n, index) => {
                if (index !== midIndex) {
                    n.isPlaceholder = true;
                } else {
                    n.isPlaceholder = false;
                }
            });

            nodeMap.set(node.id, { depth, nodes: currentNodes });
        }
    } else {
        nodeMap.set(node.id, { depth, nodes: [node] });
    }

    if (node.children) {
        node.children.forEach((child: any) => markDuplicateNodes(child, nodeMap, depth + 1));
    }

    return node;
}

export default UseStoryData;