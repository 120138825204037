import { Button, Card, Form, Input, Select, Space } from "antd";
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useEffect } from "react";
import { dealLangToPage } from "../../../../utils/lang";

interface IStoryFrom {
    onFinish: (value: any) => void;
    info: any;
}

const ConditionForm = (props: IStoryFrom) => {
    const { onFinish, info } = props;
    const [form] = Form.useForm();

    useEffect(() => {
        if (!info) return;
        form.setFieldsValue({
            conditionDetail: {
                conditionType: info.conditionDetail?.conditionType || 1,
                children: info.conditionDetail?.children || [
                    {
                        conditionType: 1,
                        children: [
                            { valueType: 1, opType: 1, optionId: 1 }
                        ]
                    }
                ]
            }
        });
    }, [info, form]);

    const handleFinish = (values: any) => {
        // 调用传入的 onFinish 方法并传递格式化后的数据
        onFinish(values);
    };

    return info && (
        <Form
            form={form}
            style={{
                width: 600,
                padding: 12,
                marginTop: 50,
                overflow: 'auto',
                height: 'calc(100% - 100px)',
            }}
            name="form_item_path"
            onFinish={handleFinish}
        >
            <Form.Item name="conditionDetail">
                <Form.Item label="" name={["conditionDetail", "conditionType"]}>
                    <Select>
                        <Select.Option value={1}>且（满足全部条件）</Select.Option>
                        <Select.Option value={0}>或（满足其中之一）</Select.Option>
                    </Select>
                </Form.Item>

                <Form.List name={["conditionDetail", "children"]}>
                    {(fields, { add, remove }) => (
                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                            {fields.map((field) => (
                                <Card
                                    size="small"
                                    title={`条件 ${field.name + 1}`}
                                    key={field.key}
                                    extra={
                                        <CloseOutlined
                                            onClick={() => remove(field.name)}
                                        />
                                    }
                                >
                                    <Form.Item label="" name={[field.name, 'conditionType']}>
                                        <Select>
                                            <Select.Option value={1}>且（满足全部条件）</Select.Option>
                                            <Select.Option value={0}>或（满足其中之一）</Select.Option>
                                        </Select>
                                    </Form.Item>

                                    <Form.List name={[field.name, 'children']}>
                                        {(subFields, subOpt) => (
                                            <div style={{ display: 'flex', flexDirection: 'column', rowGap: 10 }}>
                                                {subFields.map((subField) => (
                                                    <Space key={subField.key} align="baseline">
                                                        <Form.Item noStyle name={[subField.name, 'valueType']}>
                                                            <Select style={{ width: 105 }}>
                                                                <Select.Option value={1}>具体数值</Select.Option>
                                                                <Select.Option value={2}>数值属性</Select.Option>
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            noStyle
                                                            shouldUpdate={(prevValues, currentValues) =>
                                                                prevValues.children?.[field.name]?.children?.[subField.name]?.valueType !==
                                                                currentValues.children?.[field.name]?.children?.[subField.name]?.valueType
                                                            }
                                                        >
                                                            {() => {
                                                                const valueType = form.getFieldValue(['children', field.name, 'children', subField.name, 'valueType']);
                                                                return valueType === 1 ? (
                                                                    <Form.Item noStyle name={[subField.name, 'optionId']}>
                                                                        <Select style={{ width: 150 }}>
                                                                            <Select.Option value={1}>角色1好感度</Select.Option>
                                                                            <Select.Option value={2}>角色2好感度</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                ) : (
                                                                    <Form.Item noStyle name={[subField.name, 'valueAttr']}>
                                                                        <Select style={{ width: 100 }}>
                                                                            <Select.Option value={1}>好感度</Select.Option>
                                                                            <Select.Option value={2}>xxx</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                );
                                                            }}
                                                        </Form.Item>

                                                        <Form.Item
                                                            noStyle
                                                            shouldUpdate={(prevValues, currentValues) =>
                                                                prevValues.children?.[field.name]?.children?.[subField.name]?.valueType !==
                                                                currentValues.children?.[field.name]?.children?.[subField.name]?.valueType
                                                            }
                                                        >
                                                            {() => {
                                                                const valueType = form.getFieldValue(['children', field.name, 'children', subField.name, 'valueType']);
                                                                return <Form.Item
                                                                    noStyle name={[subField.name, 'opType']}>
                                                                    <Select style={{ width: 75 }}>
                                                                        <Select.Option value={1}>{'>'}</Select.Option>
                                                                        <Select.Option value={2}>{'>='}</Select.Option>
                                                                        <Select.Option value={3}>{'='}</Select.Option>
                                                                        <Select.Option value={4}>{'<'}</Select.Option>
                                                                        <Select.Option value={5}>{'<='}</Select.Option>
                                                                        <Select.Option value={6}>{'!='}</Select.Option>
                                                                        {valueType == 1 ? null : <><Select.Option value={7}>{'第几名'}</Select.Option>
                                                                            <Select.Option value={8}>{'前几名'}</Select.Option></>}
                                                                    </Select>
                                                                </Form.Item>
                                                            }}
                                                        </Form.Item>

                                                        <Form.Item noStyle name={[subField.name, 'value']}>
                                                            <Input placeholder="具体数值" />
                                                        </Form.Item>

                                                        <Form.Item
                                                            noStyle
                                                            shouldUpdate={(prevValues, currentValues) =>
                                                                prevValues.children?.[field.name]?.children?.[subField.name]?.valueType !==
                                                                currentValues.children?.[field.name]?.children?.[subField.name]?.valueType
                                                            }
                                                        >
                                                            {() => {
                                                                const valueType = form.getFieldValue(['children', field.name, 'children', subField.name, 'valueType']);
                                                                return valueType === 2 && (
                                                                    <Form.Item noStyle name={[subField.name, 'optionId']}>
                                                                        <Select style={{ width: 150 }}>
                                                                            <Select.Option value={1}>角色1好感度</Select.Option>
                                                                            <Select.Option value={2}>角色2好感度</Select.Option>
                                                                        </Select>
                                                                    </Form.Item>
                                                                )
                                                            }}
                                                        </Form.Item>
                                                        <CloseOutlined
                                                            onClick={() => subOpt.remove(subField.name)}
                                                        />
                                                    </Space>
                                                ))}
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    type="dashed"
                                                    style={{ fontSize: 13 }}
                                                    onClick={() => subOpt.add({ valueType: 1, opType: 1, optionId: 1 })}
                                                    block
                                                >
                                                    添加子条件
                                                </Button>
                                            </div>
                                        )}
                                    </Form.List>
                                </Card>
                            ))}

                            <Button
                                icon={<PlusOutlined />}
                                type="dashed"
                                onClick={() => add({
                                    conditionType: 1,
                                    children: [
                                        { valueType: 1, opType: 1, optionId: 1 }
                                    ]
                                })}
                                block
                            >
                                添加条件
                            </Button>
                        </div>
                    )}
                </Form.List>
            </Form.Item>

            <div style={{ height: 50 }}>
                <Button type="primary" htmlType="submit">
                    保存
                </Button>
            </div>
        </Form>
    );
};

export default ConditionForm;
